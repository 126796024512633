import m, { createContext as f, useContext as d, useState as h, useMemo as u } from "react";
import { useEvent as b } from "./designsystem-ui-react92.js";
import { useId as C } from "./designsystem-ui-react94.js";
import { useIsoMorphicEffect as v } from "./designsystem-ui-react123.js";
import { useSyncRefs as g } from "./designsystem-ui-react100.js";
import { forwardRefWithAs as k, render as E } from "./designsystem-ui-react106.js";
let p = f(null);
function c() {
  let o = d(p);
  if (o === null) {
    let r = new Error("You used a <Label /> component, but it is not inside a relevant parent.");
    throw Error.captureStackTrace && Error.captureStackTrace(r, c), r;
  }
  return o;
}
function y() {
  let [o, r] = h([]);
  return [o.length > 0 ? o.join(" ") : void 0, u(() => function(e) {
    let s = b((n) => (r((t) => [...t, n]), () => r((t) => {
      let a = t.slice(), l = a.indexOf(n);
      return l !== -1 && a.splice(l, 1), a;
    }))), i = u(() => ({ register: s, slot: e.slot, name: e.name, props: e.props }), [s, e.slot, e.name, e.props]);
    return m.createElement(p.Provider, { value: i }, e.children);
  }, [r])];
}
let x = "label";
function L(o, r) {
  let e = C(), { id: s = `headlessui-label-${e}`, passive: i = !1, ...n } = o, t = c(), a = g(r);
  v(() => t.register(s), [s, t.register]);
  let l = { ref: a, ...t.props, id: s };
  return i && ("onClick" in l && (delete l.htmlFor, delete l.onClick), "onClick" in n && delete n.onClick), E({ ourProps: l, theirProps: n, slot: t.slot || {}, defaultTag: x, name: t.name || "Label" });
}
let S = k(L), A = Object.assign(S, {});
export {
  A as Label,
  y as useLabels
};
