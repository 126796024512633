import { jsxs as t, jsx as l } from "react/jsx-runtime";
const i = (a) => t("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "1.5em", height: "1.5em", style: {
  fillRule: "evenodd",
  clipRule: "evenodd"
}, focusable: "false", "aria-hidden": "true", fill: "currentColor", viewBox: "0 0 24 24" }, a, { children: [l("path", { fill: "#017832", d: "M96.5 19.5a946 946 0 0 1 28 5q1.936-.356 3 1 2.212 2.865 6 3.5a193 193 0 0 1 8.5 8.5 4.94 4.94 0 0 1 2.5-1q2.891 2.69 7 3 5.5 4.5 10 10-.393 4.128 0 9 2.466-.32 4.5 1 3.295 7.717 8.5 14a37 37 0 0 1 3 13v26q-1.756 15.277-10 28-2.554-1.74-2 2h-3q.717 4.167-3 6-1.393 1.783-1 4-5.667 7.676-14 12a12.9 12.9 0 0 0-5 .5 27.8 27.8 0 0 1-8 6 85.4 85.4 0 0 0-15 5 256 256 0 0 1-32 0 25309 25309 0 0 1-22-6.5q-28.484-14.457-38-45-6.61-26.217 1.5-52 1.064-2.21 3.5-2 1.57-10.983 9.5-19a8.43 8.43 0 0 0 .5-4q22.647-25.572 57-28", style: {
  opacity: 1
} }), l("path", { fill: "#1d6e40", d: "M96.5 19.5q14.624.112 28 5a946 946 0 0 0-28-5", style: {
  opacity: 1
} }), l("path", { fill: "#107037", d: "M127.5 25.5q13.914 3.955 24 14-4.109-.31-7-3a4.94 4.94 0 0 0-2.5 1 193 193 0 0 0-8.5-8.5q-3.788-.635-6-3.5", style: {
  opacity: 1
} }), l("path", { fill: "#fbfefc", d: "M122.5 63.5q9.252 22.239-9 37.5a318 318 0 0 0 15 25.5 166.5 166.5 0 0 1 10 20h-78a1566 1566 0 0 1 25-45.5 54.8 54.8 0 0 1-10-11.5 19.2 19.2 0 0 1-1-6q-.977-10.026 3.5-19 2.028-2.898 5.5-4a6.85 6.85 0 0 0 1-4q9.206-7.066 21-4.5 10.38 3.157 17 11.5", style: {
  opacity: 1
} }), l("path", { fill: "#146e3b", d: "M39.5 47.5a8.43 8.43 0 0 1-.5 4q-7.93 8.017-9.5 19-2.436-.21-3.5 2-8.11 25.783-1.5 52-13.492-42.542 15-77", style: {
  opacity: 1
} }), l("path", { fill: "#def8e9", d: "M84.5 56.5a6.85 6.85 0 0 1-1 4q-3.472 1.102-5.5 4-4.477 8.974-3.5 19-2.195-8.504 1-17 2.436-6.884 9-10", style: {
  opacity: 1
} }), l("path", { fill: "#11703a", d: "M161.5 49.5q8.828 10.65 13 24-5.205-6.283-8.5-14-2.034-1.32-4.5-1-.393-4.872 0-9", style: {
  opacity: 1
} }), l("path", { fill: "#1f7644", d: "M75.5 66.5q-3.195 8.496-1 17a19.2 19.2 0 0 0 1 6q-1.437-.68-2.5-2a90.5 90.5 0 0 1 0-19q.625-1.884 2.5-2", style: {
  opacity: 1
} }), l("path", { fill: "#196c3d", d: "M177.5 86.5q4 13 0 26z", style: {
  opacity: 1
} }), l("path", { fill: "#19753f", d: "M122.5 63.5q2.864 2.212 3.5 6 3.931 18.685-9.5 31.5a867 867 0 0 1 12 25.5 318 318 0 0 1-15-25.5q18.252-15.261 9-37.5", style: {
  opacity: 1
} }), l("path", { fill: "#126a39", d: "M167.5 140.5q-3.393 6.885-9 12-.393-2.217 1-4 3.717-1.833 3-6h3q-.554-3.74 2-2", style: {
  opacity: 1
} }), l("path", { fill: "#156e3a", d: "M144.5 164.5q-30.631 21.271-67 11.5-7.985-2.5-15-6.5a25309 25309 0 0 0 22 6.5q16 1 32 0a85.4 85.4 0 0 1 15-5 27.8 27.8 0 0 0 8-6 12.9 12.9 0 0 1 5-.5", style: {
  opacity: 1
} })] }));
export {
  i as default
};
