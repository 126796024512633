import { jsx as b } from "react/jsx-runtime";
import { cn as a } from "./designsystem-ui-react46.js";
function d({
  children: r,
  className: e,
  ref: t,
  ...o
}) {
  return /* @__PURE__ */ b(
    "tr",
    {
      className: a(
        // Base styles
        "tw-text-sm",
        // Border styles
        "tw-border-b",
        "tw-border-b-ink-border-subtle",
        "last:tw-border-b-0",
        // Mobile-specific borders
        "even:max-lg:tw-border-t",
        "even:max-lg:tw-border-t-ink-border-subtle",
        // Hover state
        "hover:tw-bg-surface-default-hover",
        // Mobile first row special case
        "max-lg:first-of-type:[&_td]:tw-bg-surface-default-hover",
        "max-lg:first-of-type:[&_td]:tw-border-[1.5px]",
        e
      ),
      ref: t,
      ...o,
      children: r
    }
  );
}
d.displayName = "TableRow";
export {
  d as TableRow
};
