import { jsx as s } from "react/jsx-runtime";
import { cn as n } from "./designsystem-ui-react46.js";
function b({
  children: t,
  className: l,
  colSpan: r,
  headers: e,
  ref: o,
  ...a
}) {
  const m = e.length > 0;
  return /* @__PURE__ */ s(
    "td",
    {
      className: n(["tw-p-4", "tw-align-top", "tw-break-words"], ["max-lg:tw-grid", "max-lg:tw-font-medium"], [
        "max-lg:tw-border",
        "max-lg:tw-border-transparent",
        "max-lg:last:tw-border-b-0",
        "max-lg:tw-border-b-ink-border-subtle"
      ], m ? [
        "max-lg:tw-grid-cols-2",
        "max-lg:before:tw-content-[attr(headers)]",
        "max-lg:before:tw-text-ink-brand-default",
        "max-lg:before:tw-font-normal",
        "max-lg:before:tw-hyphens-auto",
        "max-lg:before:tw-mr-1"
      ] : ["max-lg:tw-grid-cols-1", "max-lg:tw-justify-items-end", "max-lg:before:tw-content-none"], l),
      colSpan: r,
      headers: e,
      ref: o,
      ...a,
      children: t
    }
  );
}
b.displayName = "TableCell";
export {
  b as TableCell
};
