import { jsxs as A, jsx as w } from "react/jsx-runtime";
import { useState as y, useEffect as j, useCallback as C } from "react";
import { cn as f } from "./designsystem-ui-react46.js";
import { Button as _ } from "./designsystem-ui-react6.js";
import { Input as F } from "./designsystem-ui-react19.js";
import B from "./designsystem-ui-react63.js";
import z from "./designsystem-ui-react64.js";
const i = (r, n, a = !1) => typeof r > "u" ? "" : typeof r == "string" && a ? r : typeof r == "object" ? r[n] ?? "" : "";
function E({
  value: r = 1,
  className: n,
  onChange: a,
  min: o,
  max: u,
  size: d = "regular",
  onPlusClick: b,
  onMinusClick: s,
  ...S
}) {
  const [l, g] = y(r), [x, p] = y(r.toString());
  j(() => {
    g(r), p(r.toString());
  }, [r]);
  const c = C(
    (t) => {
      let e = t(l);
      if (isNaN(e) && (e = 1), o !== void 0 && e < o && (e = o), u !== void 0 && e > u && (e = u), e === l) {
        p(e.toString());
        return;
      }
      if (typeof a == "function") {
        const v = a(e);
        typeof v == "number" && (e = v);
      }
      g(e), p(e.toString());
    },
    [l, a, o, u]
  ), h = () => {
    typeof b == "function" && b() === !1 || c((t) => t + 1);
  }, V = () => {
    typeof s == "function" && s() === !1 || c((t) => t - 1);
  }, k = {
    compact: "tw-w-32 tw-max-w-36",
    regular: "tw-w-44 tw-max-w-40",
    large: "tw-w-48 tw-max-w-48"
  }[d], I = {
    compact: "[&_input]:tw-h-8 [&_input]:tw-text-sm",
    regular: "tw-h-[42px]",
    large: "tw-h-[51px] [&_input]:tw-text-xl [&_input]:tw-font-bold"
  }[d], m = {
    compact: "tw-h-8 tw-w-10",
    regular: "tw-w-12",
    large: "tw-w-14"
  }[d];
  return /* @__PURE__ */ A(
    "div",
    {
      className: f(
        // Layout
        "tw-grid tw-gap-0 tw-grid-cols-[max-content_auto_max-content] ",
        // Border and shape
        "tw-border tw-border-solid tw-border-ink-brand-default tw-rounded-button",
        k,
        i(n, "container", !0)
      ),
      ...S,
      children: [
        /* @__PURE__ */ w(
          _,
          {
            "aria-label": "Minske antall",
            className: f(
              // Shape
              "tw-border-0 hover:tw-border-0 tw-rounded-r-none",
              // Disabled override color
              "[&:disabled]:tw-bg-surface-default",
              m,
              i(n, "button"),
              i(n, "decrease")
            ),
            disabled: o !== void 0 && l <= o,
            onClick: V,
            size: d === "regular" ? "regular" : "compact",
            variant: "tertiary",
            children: /* @__PURE__ */ w(B, {})
          }
        ),
        /* @__PURE__ */ w(
          F,
          {
            "aria-label": "Antall",
            className: f(
              // Size
              "tw-w-full [&>div]:tw-h-full [&_input]:tw-h-full",
              I,
              // Border
              "tw-border-x tw-border-y-0 tw-border-ink-brand-default tw-border-solid",
              // Descendant input element styling
              "[&_input]:tw-text-center [&_input]:tw-rounded-none [&_input]:tw-border-0 [&_input]:tw-px-0 [&_input]:active:tw-border-0 [&_input]:focus:tw-border-0",
              i(n, "input")
            ),
            inputMode: "numeric",
            label: "",
            onBlur: (t) => {
              c(() => parseInt(t.target.value, 10));
            },
            onChange: (t) => {
              p(t.target.value);
            },
            onFocus: (t) => {
              t.target.select();
            },
            type: "number",
            value: x
          }
        ),
        /* @__PURE__ */ w(
          _,
          {
            "aria-label": "Øke antall",
            className: f(
              // Shape
              "tw-border-0 hover:tw-border-0 tw-rounded-l-none",
              // Disabled override color
              "[&:disabled]:tw-bg-surface-default",
              m,
              i(n, "button"),
              i(n, "increase")
            ),
            disabled: u !== void 0 && l >= u,
            onClick: h,
            size: d === "regular" ? "regular" : "compact",
            variant: "tertiary",
            children: /* @__PURE__ */ w(z, {})
          }
        )
      ]
    }
  );
}
E.displayName = "InputStepper";
export {
  E as InputStepper
};
