import { jsx as a } from "react/jsx-runtime";
function d({
  children: o,
  className: e,
  ref: r,
  ...t
}) {
  return /* @__PURE__ */ a("tbody", { className: e, ref: r, ...t, children: o });
}
d.displayName = "TableBody";
export {
  d as TableBody
};
