import { store } from "@risingstack/react-easy-state";
import has from "lodash-es/has";
import intersection from "lodash-es/intersection";
import take from "lodash-es/take";

import { apiClient } from "../common/apiClient";
import {
   AsyncData,
   initializeWithDefaultData,
   isAbortError,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../common/utils/asyncDataUtils";

import theme from "../themes/theme";
import authStore from "./auth/authStore";
import productStore from "./product/productStore";

type RecommendationBase = {
   reason: string;
   producedBy: string;
   createdAt: string;
};

type Recommendation = RecommendationBase & {
   skus: Suggestion[];
};

type RecommendationBySource = {
   [source: string]: Recommendation;
};

type Suggestion = {
   sku: string;
   confidence: number;
};

type ML001Recommendation = RecommendationBase & {
   suggestions: SuggestionsBySource;
};

type SuggestionsBySource = {
   [source: string]: ML001Suggestion[];
};

type ML001Suggestion = {
   sku: string;
   confidence: number;
   type: string;
};

export type ContractualRecommendationResponse = {
   foundContractualData: boolean;
   customerNumber: string;
   relevantChainIds: string[];
   contractualObligatedProducts: ContractualObligatedProduct[];
   voluntaryProducts: string[];
   shouldBuy: string[];
};

export type ContractualObligatedProduct = {
   sku: string;
   lastPurchase: Date | null;
};

const getSkusFromRecommendation = (recommendation: ML001Recommendation, type: string): string[] => {
   if (!has(recommendation.suggestions, type)) {
      return [];
   }

   return recommendation.suggestions[type].map((suggestion) => suggestion.sku);
};

const adjustForAvailableProducts = (recommendation: Recommendation | ML001Recommendation | undefined): Recommendation => {
   if (!recommendation) {
      return {
         reason: "Ingen tilgjengelig anbefalinger",
         skus: [],
         producedBy: "",
         createdAt: ""
      };
   }

   let skus = [];
   if ("suggestions" in recommendation) {
      // Handle old backend with split product suggestions
      const newProducts = intersection(productStore.assortment, getSkusFromRecommendation(recommendation, "Nyhet"));
      const focusProducts = intersection(productStore.assortment, getSkusFromRecommendation(recommendation, "Fokus"));
      const normalProducts = intersection(productStore.assortment, getSkusFromRecommendation(recommendation, "Fordelingsnøkkel"));

      const newProductsCount = Math.min(1, newProducts.length);
      const focusProductsCount = Math.min(2, focusProducts.length);
      const normalProductsCount = Math.min(6 - newProductsCount - focusProductsCount);

      console.log(
         `RECOMMENDATIONS: Using ${newProductsCount} new products, ${focusProductsCount} focus products and ${normalProductsCount} other products`
      );

      skus = [
         ...take(normalProducts, normalProductsCount),
         ...take(focusProducts, focusProductsCount),
         ...take(newProducts, newProductsCount)
      ].map((sku) => ({ sku, confidence: 1 }));
   } else {
      // Handle new backend, with combined product suggestions and intergrated confidence
      skus = recommendation.skus;
   }

   return {
      reason: recommendation.reason,
      skus: skus,
      producedBy: recommendation.producedBy,
      createdAt: recommendation.createdAt
   };
};

type RecommendationStore = {
   recommendations: AsyncData<RecommendationBySource | null>;
   contractualRecommendations: AsyncData<ContractualRecommendationResponse | null>;

   clearRecommendations(): void;
   getRecommendations(): void;
   sendRecommendationFeedback(serviceId: string, sku: string, action: string): void;

   getContractualRecommendations(): void;
};

const recommendationStore: RecommendationStore = store({
   recommendations: initializeWithDefaultData(null),
   contractualRecommendations: initializeWithDefaultData(null),

   clearRecommendations: () => {
      recommendationStore.recommendations = initializeWithDefaultData(null);
   },

   getRecommendations: () => {
      if (!authStore.isLoggedIn()) {
         console.log("RECOMMENDATIONS: User is not logged in, clearing recommendations...");
         recommendationStore.clearRecommendations();
         return;
      }

      const abortController = setAsWaitingForData(recommendationStore.recommendations);
      const customerNumber = authStore.currentCompany;

      void apiClient(`${process.env.API_HOST}/api/recommend/ml001/${customerNumber}`, authStore.getSessionToken())
         .signal(abortController)
         .get()
         .json(async (res: { [source: string]: ML001Recommendation }) => {
            setAsDataAvailable(recommendationStore.recommendations, {
               AI1: adjustForAvailableProducts(res["AI1"]),
               AI2: adjustForAvailableProducts(res["AI2"]),
               AI3: adjustForAvailableProducts(res["AI3"])
            });
            console.log("RECOMMENDATIONS: Custom Recommendations loaded", recommendationStore.recommendations.data);
         })
         .catch((err) => {
            if (!isAbortError(err)) {
               console.warn("RECOMMENDATIONS: Unable to load recommendations: ", err.message);
               setAsErrorOccured(recommendationStore.recommendations, "" + err);
            }
         });
   },

   sendRecommendationFeedback: (serviceId, sku, action) => {
      const customerNumber = authStore.currentCompany;
      console.log(
         "RECOMMENDATIONS: Sending feedback for recommendation service " + serviceId + " " + action + " with SKU " + sku
      );

      void apiClient(`${process.env.API_HOST}/api/recommend/${customerNumber}`, authStore.getSessionToken())
         .query({
            serviceId,
            sku,
            action
         })
         .post()
         .json();
   },

   getContractualRecommendations: () => {
      if (!authStore.isLoggedIn()) {
         recommendationStore.clearRecommendations();
         return;
      }

      const abortController = setAsWaitingForData(recommendationStore.contractualRecommendations);
      const m3CompanyNumber = theme.m3CompanyNumber;
      const customerNumber = authStore.currentCompany;

      void apiClient(
         `${process.env.API_HOST}/api/recommend/contractual/${m3CompanyNumber}/${customerNumber}`,
         authStore.getSessionToken()
      )
         .signal(abortController)
         .get()
         .json(async (res: ContractualRecommendationResponse) => {
            setAsDataAvailable(recommendationStore.contractualRecommendations, res);
            console.log(
               "RECOMMENDATIONS: Contractual Recommendations loaded",
               recommendationStore.contractualRecommendations.data
            );
         })
         .catch((err) => {
            if (!isAbortError(err)) {
               console.warn("RECOMMENDATIONS: Unable to load contractual recommendations: ", err.message);
               setAsErrorOccured(recommendationStore.contractualRecommendations, "" + err);
            }
         });
   }
} satisfies RecommendationStore);

export default recommendationStore;
