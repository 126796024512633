import { useRef as s, useEffect as p } from "react";
import { isFocusableElement as v, FocusableMode as E } from "./designsystem-ui-react119.js";
import { isMobile as w } from "./designsystem-ui-react146.js";
import { useDocumentEvent as c } from "./designsystem-ui-react147.js";
import { useWindowEvent as b } from "./designsystem-ui-react148.js";
function L(m, d, a = !0) {
  let l = s(!1);
  p(() => {
    requestAnimationFrame(() => {
      l.current = a;
    });
  }, [a]);
  function i(t, r) {
    if (!l.current || t.defaultPrevented)
      return;
    let e = r(t);
    if (e === null || !e.getRootNode().contains(e) || !e.isConnected)
      return;
    let f = function o(n) {
      return typeof n == "function" ? o(n()) : Array.isArray(n) || n instanceof Set ? n : [n];
    }(m);
    for (let o of f) {
      if (o === null)
        continue;
      let n = o instanceof HTMLElement ? o : o.current;
      if (n != null && n.contains(e) || t.composed && t.composedPath().includes(n))
        return;
    }
    return !v(e, E.Loose) && e.tabIndex !== -1 && t.preventDefault(), d(t, e);
  }
  let u = s(null);
  c("pointerdown", (t) => {
    var r, e;
    l.current && (u.current = ((e = (r = t.composedPath) == null ? void 0 : r.call(t)) == null ? void 0 : e[0]) || t.target);
  }, !0), c("mousedown", (t) => {
    var r, e;
    l.current && (u.current = ((e = (r = t.composedPath) == null ? void 0 : r.call(t)) == null ? void 0 : e[0]) || t.target);
  }, !0), c("click", (t) => {
    w() || u.current && (i(t, () => u.current), u.current = null);
  }, !0), c("touchend", (t) => i(t, () => t.target instanceof HTMLElement ? t.target : null), !0), b("blur", (t) => i(t, () => window.document.activeElement instanceof HTMLIFrameElement ? window.document.activeElement : null), !0);
}
export {
  L as useOutsideClick
};
