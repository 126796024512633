import { jsxs as u, jsx as t } from "react/jsx-runtime";
import { cn as w } from "./designsystem-ui-react46.js";
import { forwardRef as j, useState as F, useId as z } from "react";
import { FormError as A } from "./designsystem-ui-react15.js";
import { FormDetails as B } from "./designsystem-ui-react14.js";
import D from "./designsystem-ui-react61.js";
import H from "./designsystem-ui-react65.js";
import L from "./designsystem-ui-react66.js";
const R = j(
  ({
    description: b = "",
    disabled: p = !1,
    errorMessage: c = "",
    hideLabel: m = !1,
    label: a = "",
    placeholder: g = "",
    showErrorMessage: r = !1,
    type: d = "text",
    icon: e,
    iconPosition: i = "left",
    tooltipPosition: h = "right",
    tooltipText: v,
    className: x,
    inputSize: s = "regular",
    ...k
  }, y) => {
    const [o, N] = F(!1), I = z(), n = typeof a == "string" ? a : I, S = () => {
      N(!o);
      const f = document.getElementById(n);
      f && f.setAttribute("type", o ? "password" : "text");
    }, C = {
      compact: "tw-text-xs tw-leading-none",
      regular: "tw-text-base"
    }, l = s === "compact" ? 16 : 20;
    return /* @__PURE__ */ u("div", { className: w("tw-flex tw-flex-col tw-gap-y-2 tw-w-full", x), children: [
      a ? /* @__PURE__ */ t(
        B,
        {
          description: b,
          id: n,
          isLabelHidden: m,
          label: a,
          tooltipPosition: h,
          tooltipText: v
        }
      ) : null,
      /* @__PURE__ */ u("div", { className: "tw-relative tw-flex tw-items-center tw-fill-ink-brand-default", children: [
        /* @__PURE__ */ t(
          "input",
          {
            id: n,
            placeholder: g,
            ref: y,
            type: d,
            ...k,
            className: w(
              // basic layout
              "tw-block tw-w-full tw-relative tw-transition-all tw-p-2 tw-text-ink-brand-default tw-fill-ink-brand-default tw-rounded placeholder:tw-text-ink-disabled",
              // border
              "tw-border-2 tw-border-ink-disabled hover:tw-border-ink-brand-default focus-visible:tw-ring-offset-1 focus-visible:tw-ring-offset-ink-brand-default",
              // focus
              "focus-visible:tw-border-ink-brand-default focus-visible:tw-ring-2 focus-visible:tw-ring-information-600 focus-visible:tw-outline-none",
              // Invalid state
              "data-[invalid]:tw-border-error-600 data-[invalid]:data-[hover]:tw-border-error-600",
              r && "!tw-border-error-600",
              d === "file" && "tw-p-0 tw-border-0 file:tw-rounded-button file:tw-mr-5 file:tw-border-button-primary-bg file:tw-px-button-regular-padding-x file:tw-py-button-regular-padding-y file:tw-border-solid file:tw-text-button-primary-text file:tw-bg-button-primary-bg hover:file:tw-cursor-pointer",
              p && "tw-bg-base-100 tw-cursor-not-allowed tw-pointer-events-none",
              // Icon position
              d === "password" && "tw-pr-16",
              e && i === "left" && "tw-pl-10",
              e && i === "right" && "tw-pr-8",
              e && i === "right" && r && "tw-pr-16",
              // Size classes
              C[s]
            )
          }
        ),
        e && i === "left" ? /* @__PURE__ */ t("div", { className: "tw-absolute tw-left-2", children: e }) : null,
        r ? /* @__PURE__ */ t("div", { className: "tw-absolute tw-right-2", children: /* @__PURE__ */ t(D, { className: "tw-fill-error-600", height: l, width: l }) }) : null,
        e && i === "right" ? /* @__PURE__ */ t("div", { className: w("tw-absolute", r ? "tw-right-8" : "tw-right-2"), children: e }) : null,
        d === "password" ? /* @__PURE__ */ t(
          "button",
          {
            "aria-checked": o,
            className: w(
              "tw-absolute tw-bg-transparent tw-border-none tw-cursor-pointer tw-fill-ink-brand-default",
              r ? "tw-right-8" : "tw-right-2"
            ),
            onClick: S,
            role: "switch",
            type: "button",
            children: o ? /* @__PURE__ */ t(H, { height: l, width: l }) : /* @__PURE__ */ t(L, { height: l, width: l })
          }
        ) : null
      ] }),
      /* @__PURE__ */ t(A, { errorMessage: c, showErrorMessage: r })
    ] });
  }
);
R.displayName = "Input";
export {
  R as Input
};
