import { forwardRefWithAs as o, render as t } from "./designsystem-ui-react106.js";
let s = "div";
var l = ((e) => (e[e.None = 1] = "None", e[e.Focusable = 2] = "Focusable", e[e.Hidden = 4] = "Hidden", e))(l || {});
function p(e, a) {
  var i;
  let { features: d = 1, ...r } = e, n = { ref: a, "aria-hidden": (d & 2) === 2 ? !0 : (i = r["aria-hidden"]) != null ? i : void 0, hidden: (d & 4) === 4 ? !0 : void 0, style: { position: "fixed", top: 1, left: 1, width: 1, height: 0, padding: 0, margin: -1, overflow: "hidden", clip: "rect(0, 0, 0, 0)", whiteSpace: "nowrap", borderWidth: "0", ...(d & 4) === 4 && (d & 2) !== 2 && { display: "none" } } };
  return t({ ourProps: n, theirProps: r, slot: {}, defaultTag: s, name: "Hidden" });
}
let f = o(p);
export {
  l as Features,
  f as Hidden
};
