import { ContentfulArticle, ContentfulExternalLink, ContentfulMediaLink } from "../common/types/cmsTypes";

import contentStore from "../stores/cms/contentStore";

import { DownloadLink, ExternalLink, InternalLink } from "../components/Links";

export type FooterLink = ContentfulArticle | ContentfulMediaLink | ContentfulExternalLink;

type ContentfulFooterLinkProps = {
   content: FooterLink;
};

const ContentfulFooterLink = ({ content }: ContentfulFooterLinkProps) => {
   if (content?.sys?.contentType?.sys?.id === "v2_article") {
      const article = content as ContentfulArticle;
      const id = article.sys.id;
      const linkText = article.fields.title;
      const fullUrl = `/${contentStore.urlData.data.find((data) => data.id === id)?.fullUrl}`;

      return (
         <InternalLink
            href={fullUrl}
            className="tw-text-headerFooterText tw-no-underline hover:tw-underline"
            trackingLocationName="Footer"
         >
            {linkText}
         </InternalLink>
      );
   }

   if (content?.sys?.contentType?.sys?.id === "externalLink") {
      const externalLink = content as ContentfulExternalLink;
      const url = externalLink.fields.url;
      const linkText = externalLink.fields.linkText;

      const ComponentToUse = url.startsWith("http") ? ExternalLink : InternalLink;

      return (
         <ComponentToUse
            trackingLocationName="Footer"
            href={url}
            className="tw-text-headerFooterText tw-no-underline hover:tw-underline"
         >
            {linkText}
         </ComponentToUse>
      );
   }

   if (content?.sys?.contentType?.sys?.id === "linkToMedia") {
      const externalLink = content as ContentfulMediaLink;
      const url = externalLink.fields.media?.fields.file?.url;
      const linkText = externalLink.fields.linkText;
      return (
         <DownloadLink href={url} className="tw-text-headerFooterText tw-no-underline hover:tw-underline">
            {linkText}
         </DownloadLink>
      );
   }
};

export default ContentfulFooterLink;
