import * as configcat from "configcat-js";
import { PollingMode, User } from "configcat-js";

import { ConfigCatUserData } from "./types/featureTypes";

// All the keys we use in ConfigCat
export const enum ConfigCatKeys {
   BARCODESCANNER_AVAILABLE = "barcodeScannerAvailable",
   CLAIMS_AVAILABLE = "claimsAvailable",
   PICKUP_ORDER_AVAILABLE = "pickupOrderAvailable",
   NOTIFICATIONS_AVAILABLE = "notificationsAvailable",
   RECOMMENDATION_TYPE = "recommendationType",
   KEYFIGURES_AVAILABLE = "keyFiguresAvailable",
   CONTRACTUAL_RECOMMENDATIONS = "contractualRecommendations",
   PROMOTIONS_AVAILABLE = "promotionsAvailable",
   USE_DYNAMICS_FOR_NOTIFICATION_PROFILES = "useDynamicsForNotificationProfiles",
   PRIORITIZE_AGREEMENT_ASSORTMENT = "prioritizeAgreementAssortment"
}

// Define a mapping type that associates keys with return types to allow TypeScript to
// infer the correct return type based on the key.
type ConfigCatKeyReturnType = {
   [ConfigCatKeys.BARCODESCANNER_AVAILABLE]: boolean;
   [ConfigCatKeys.CLAIMS_AVAILABLE]: boolean;
   [ConfigCatKeys.PICKUP_ORDER_AVAILABLE]: boolean;
   [ConfigCatKeys.NOTIFICATIONS_AVAILABLE]: boolean;
   [ConfigCatKeys.KEYFIGURES_AVAILABLE]: boolean;
   [ConfigCatKeys.RECOMMENDATION_TYPE]: "AI1" | "AI2";
   [ConfigCatKeys.CONTRACTUAL_RECOMMENDATIONS]: boolean;
   [ConfigCatKeys.PROMOTIONS_AVAILABLE]: boolean;
   [ConfigCatKeys.USE_DYNAMICS_FOR_NOTIFICATION_PROFILES]: boolean;
   [ConfigCatKeys.PRIORITIZE_AGREEMENT_ASSORTMENT]: boolean;
};

// Define a mapping object with default values for each key now that we can no longer default to false
const defaultValueReturnValues = {
   [ConfigCatKeys.BARCODESCANNER_AVAILABLE]: false,
   [ConfigCatKeys.CLAIMS_AVAILABLE]: false,
   [ConfigCatKeys.PICKUP_ORDER_AVAILABLE]: false,
   [ConfigCatKeys.NOTIFICATIONS_AVAILABLE]: false,
   [ConfigCatKeys.KEYFIGURES_AVAILABLE]: false,
   [ConfigCatKeys.RECOMMENDATION_TYPE]: "AI2",
   [ConfigCatKeys.CONTRACTUAL_RECOMMENDATIONS]: false,
   [ConfigCatKeys.PROMOTIONS_AVAILABLE]: false,
   [ConfigCatKeys.USE_DYNAMICS_FOR_NOTIFICATION_PROFILES]: false,
   [ConfigCatKeys.PRIORITIZE_AGREEMENT_ASSORTMENT]: false
};

/**
 * Set up ConfigCat client so we can do true feature toggles
 */
// @ts-expect-error
const configCatClient = configcat.getClient(process.env.CONFIG_CAT_KEY, PollingMode.AutoPoll, {
   pollIntervalSeconds: 60 * 20,
   logger: configcat.createConsoleLogger(3)
});

export function getConfigCatValue<K extends ConfigCatKeys>(key: K, userData?: User): Promise<ConfigCatKeyReturnType[K]>;

/** Fetches a value from ConfigCat, with a fallback value to false if it's not available. */
export async function getConfigCatValue<K extends ConfigCatKeys>(key: K, userData?: User): Promise<ConfigCatKeyReturnType[K]> {
   const value = await configCatClient.getValueAsync(key, defaultValueReturnValues[key], userData).catch((e) => {});
   if (key == ConfigCatKeys.RECOMMENDATION_TYPE) {
      return value as any;
   }
   return Boolean(value) as any;
}
