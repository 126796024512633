import { jsxs as n, jsx as t } from "react/jsx-runtime";
import { cn as d } from "./designsystem-ui-react46.js";
import i from "./designsystem-ui-react78.js";
import a from "./designsystem-ui-react79.js";
import w from "./designsystem-ui-react80.js";
function b(e) {
  return e >= 5 ? i : e >= 1.5 ? w : a;
}
function S({
  rating: e,
  isCompact: f = !1,
  maxRating: m = 5,
  amountOfRatings: s = 0,
  className: u = "",
  inverted: c
}) {
  if (f) {
    const r = b(e);
    return /* @__PURE__ */ n(
      "div",
      {
        className: d(
          "tw-flex tw-items-center",
          c ? "tw-text-ink-brand-inverted" : "tw-text-ink-brand-default",
          u
        ),
        children: [
          /* @__PURE__ */ t(r, {}),
          /* @__PURE__ */ n("div", { className: "tw-ordinal tw-flex tw-items-center tw-gap-x-1", children: [
            e ? /* @__PURE__ */ t(
              "span",
              {
                className: d("tw-inline-block", c ? "tw-text-ink-brand-inverted" : "tw-text-ink-brand-default"),
                children: e.toFixed(1)
              }
            ) : null,
            s ? /* @__PURE__ */ n(
              "span",
              {
                className: d(
                  "tw-inline-block tw-text-xs tw-text-ink-brand-default",
                  c ? "tw-text-ink-brand-inverted" : "tw-text-ink-brand-default"
                ),
                children: [
                  "(",
                  s,
                  ")"
                ]
              }
            ) : null
          ] })
        ]
      }
    );
  }
  const x = (r) => {
    if (e === 5)
      return r < 5 ? /* @__PURE__ */ t(i, {}) : /* @__PURE__ */ t(a, {});
    const l = Math.floor(e), o = e - l;
    return o >= 0.1 && o <= 0.5 ? r < l ? /* @__PURE__ */ t(i, {}) : r === l ? /* @__PURE__ */ t(w, {}) : /* @__PURE__ */ t(a, {}) : o > 0.5 ? r < l + 1 ? /* @__PURE__ */ t(i, {}) : /* @__PURE__ */ t(a, {}) : r < l ? /* @__PURE__ */ t(i, {}) : /* @__PURE__ */ t(a, {});
  };
  return /* @__PURE__ */ n("div", { className: "tw-flex tw-items-center tw-gap-1", children: [
    /* @__PURE__ */ t("div", { className: "tw-flex", children: Array.from({ length: m }, (r, l) => /* @__PURE__ */ t("span", { className: "text-yellow-500", children: x(l) }, l)) }),
    /* @__PURE__ */ n("div", { className: "tw-ordinal tw-flex tw-items-center", children: [
      e ? /* @__PURE__ */ t(
        "span",
        {
          className: d("tw-inline-block", c ? "tw-text-ink-brand-inverted" : "tw-text-ink-brand-default"),
          children: e.toFixed(1)
        }
      ) : null,
      s ? /* @__PURE__ */ n("span", { className: "tw-inline-block tw-text-base-400", children: [
        "(",
        s,
        ")"
      ] }) : null
    ] })
  ] });
}
export {
  S as Rating
};
