import { jsx as o } from "react/jsx-runtime";
import { cn as l } from "./designsystem-ui-react46.js";
function b({
  children: t,
  className: e,
  ref: r,
  ...a
}) {
  return /* @__PURE__ */ o(
    "thead",
    {
      className: l(
        "tw-font-semibold tw-text-ink-brand-default tw-bg-surface-default-hover max-lg:tw-text-clip max-lg:tw-h-[1px] max-lg:tw-w-[1px] max-lg:tw-absolute max-lg:tw-overflow-hidden max-lg:tw-m-[-1px] tw-border-base-transparent tw-border-x-0 tw-border-t-0 tw-border-b-[1.5px] tw-border-b-ink-border-subtle",
        e
      ),
      ref: r,
      ...a,
      children: t
    }
  );
}
b.displayName = "TableHeader";
export {
  b as TableHeader
};
