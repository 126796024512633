import c, { createContext as i, useContext as m } from "react";
import { useEvent as u } from "./designsystem-ui-react92.js";
import { useIsoMorphicEffect as f } from "./designsystem-ui-react123.js";
let l = i(() => {
});
l.displayName = "StackContext";
var p = ((e) => (e[e.Add = 0] = "Add", e[e.Remove = 1] = "Remove", e))(p || {});
function v() {
  return m(l);
}
function S({ children: e, onUpdate: s, type: r, element: a, enabled: n }) {
  let d = v(), o = u((...t) => {
    s == null || s(...t), d(...t);
  });
  return f(() => {
    let t = n === void 0 || n === !0;
    return t && o(0, r, a), () => {
      t && o(1, r, a);
    };
  }, [o, r, a, n]), c.createElement(l.Provider, { value: o }, e);
}
export {
  p as StackMessage,
  S as StackProvider,
  v as useStackContext
};
