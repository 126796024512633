import { useStore as c } from "./designsystem-ui-react144.js";
import { useIsoMorphicEffect as p } from "./designsystem-ui-react123.js";
import { overflows as t } from "./designsystem-ui-react145.js";
function n(o, r, f) {
  let e = c(t), s = o ? e.get(o) : void 0, i = s ? s.count > 0 : !1;
  return p(() => {
    if (!(!o || !r))
      return t.dispatch("PUSH", o, f), () => t.dispatch("POP", o, f);
  }, [r, o]), i;
}
export {
  n as useDocumentOverflowLockedEffect
};
