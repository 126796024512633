import { view } from "@risingstack/react-easy-state";

import loginState from "../../stores/auth/loginState";
import uiStore from "../../stores/uiStore";

import Button from "../Button";
import Modal from "../Modal";

const hideModal = () => {
   loginState.transitionToIfPossible("NOT_LOGGED_IN");
};

const toLogin = () => {
   uiStore.showLoginModal();
};

const ForcedLogoutModal = () => (
   <Modal
      name="Automatisk utlogget"
      isOpen={loginState.is("FORCED_LOGOUT")}
      onClose={hideModal}
      title="Du har blitt logget ut..."
   >
      <div>
         <p>Fordi du har vært borte en stund, så har vi for sikkerhets skyld logget deg ut av løsningen.</p>
         <p> Du kan så klart logge inn igjen og fortsette handleturen din om du trenger det.</p>
      </div>
      <div className="tw-flex tw-justify-end tw-gap-2">
         <Button onClick={toLogin}>Til innlogging</Button>
         <Button variant="secondary" onClick={hideModal}>
            Lukk
         </Button>
      </div>
   </Modal>
);

export default view(ForcedLogoutModal);
