import { snakeCase } from "lodash-es";

import theme from "../themes/theme";

type ImageTemplate = {
   size: number;
   id: string;
};

const fallbackSize: ImageTemplate = { size: 750, id: "fullscreen" };

// Array of available Papirfly APS sizes, should be ordered by size in ascending order.
const availableImageSize: ImageTemplate[] = [
   { size: 48, id: "thumb_small" },
   { size: 76, id: "thumb_large" },
   { size: 160, id: "small" },
   { size: 240, id: "medium" },
   { size: 380, id: "large" },
   fallbackSize
];

const siteNamePrefix = snakeCase(theme.siteName);

export const productImageUrl = (sku: string, size: number = 380): string => {
   const sizeToUse = availableImageSize.find((imgSize) => imgSize.size >= size) ?? fallbackSize;
   return `https://aps.brandmaster.com/v03/product/${theme.papirflyApsCustomerId}/${theme.papirflyApsImagePrefix}${sku}/${sizeToUse.id}/hovedbilde/${siteNamePrefix}_artnr_${sku}__${sizeToUse.id}.png`;
};
