import { jsxs as i, jsx as t } from "react/jsx-runtime";
import { cn as a } from "./designsystem-ui-react46.js";
import l from "./designsystem-ui-react61.js";
function o({ errorMessage: r, showErrorMessage: e = !1 }) {
  return typeof r != "string" || !r.trim() ? null : /* @__PURE__ */ i(
    "div",
    {
      "aria-live": "polite",
      "aria-relevant": "additions removals",
      className: a(
        e ? "tw-flex" : "tw-hidden",
        "tw-items-start tw-gap-1 tw-p-1 tw-text-xs tw-border-0 tw-border-l-2 tw-bg-error-100 tw-border-l-error-600"
      ),
      children: [
        /* @__PURE__ */ t(l, { className: "tw-w-4 tw-h-4 tw-fill-error-600" }),
        /* @__PURE__ */ t("p", { className: "tw-mb-0 tw-text-ink-brand-default", children: r })
      ]
    }
  );
}
o.displayName = "FormError";
export {
  o as FormError
};
