import { disposables as f } from "./designsystem-ui-react128.js";
import { isIOS as m } from "./designsystem-ui-react146.js";
function p() {
  return m() ? { before({ doc: o, d: n, meta: c }) {
    function r(s) {
      return c.containers.flatMap((a) => a()).some((a) => a.contains(s));
    }
    n.microTask(() => {
      var s;
      if (window.getComputedStyle(o.documentElement).scrollBehavior !== "auto") {
        let t = f();
        t.style(o.documentElement, "scrollBehavior", "auto"), n.add(() => n.microTask(() => t.dispose()));
      }
      let a = (s = window.scrollY) != null ? s : window.pageYOffset, l = null;
      n.addEventListener(o, "click", (t) => {
        if (t.target instanceof HTMLElement)
          try {
            let e = t.target.closest("a");
            if (!e)
              return;
            let { hash: d } = new URL(e.href), i = o.querySelector(d);
            i && !r(i) && (l = i);
          } catch {
          }
      }, !0), n.addEventListener(o, "touchstart", (t) => {
        if (t.target instanceof HTMLElement)
          if (r(t.target)) {
            let e = t.target;
            for (; e.parentElement && r(e.parentElement); )
              e = e.parentElement;
            n.style(e, "overscrollBehavior", "contain");
          } else
            n.style(t.target, "touchAction", "none");
      }), n.addEventListener(o, "touchmove", (t) => {
        if (t.target instanceof HTMLElement)
          if (r(t.target)) {
            let e = t.target;
            for (; e.parentElement && e.dataset.headlessuiPortal !== "" && !(e.scrollHeight > e.clientHeight || e.scrollWidth > e.clientWidth); )
              e = e.parentElement;
            e.dataset.headlessuiPortal === "" && t.preventDefault();
          } else
            t.preventDefault();
      }, { passive: !1 }), n.add(() => {
        var t;
        let e = (t = window.scrollY) != null ? t : window.pageYOffset;
        a !== e && window.scrollTo(0, a), l && l.isConnected && (l.scrollIntoView({ block: "nearest" }), l = null);
      });
    });
  } } : {};
}
export {
  p as handleIOSLocking
};
