import { jsx as t, jsxs as o, Fragment as x } from "react/jsx-runtime";
import { useState as g } from "react";
import { cn as y } from "./designsystem-ui-react46.js";
import { RadioGroup as a } from "./designsystem-ui-react74.js";
function N({
  className: u,
  label: l,
  onChange: d = () => null,
  options: r,
  value: n,
  initialValue: m,
  horizontal: w = !1,
  name: s
}) {
  const [p, f] = g(m ?? r[0].value), c = n !== void 0, h = c ? n : p, v = (e) => {
    c || f(e), d({
      target: {
        value: e,
        name: s,
        type: "radio",
        checked: !0
      }
    });
  };
  return /* @__PURE__ */ t("div", { className: u, children: /* @__PURE__ */ o(a, { value: h, children: [
    l ? /* @__PURE__ */ t(a.Label, { className: "tw-font-bold", children: l }) : null,
    /* @__PURE__ */ t(
      "div",
      {
        className: y("tw-mt-4 tw-flex tw-flex-col tw-gap-y-3", w && "tw-flex-row tw-gap-x-4 tw-flex-wrap"),
        children: r.map((e) => /* @__PURE__ */ t(a.Option, { as: "div", className: "tw-flex tw-items-center", value: e.value, children: ({ checked: i }) => /* @__PURE__ */ o(x, { children: [
          /* @__PURE__ */ t(
            "input",
            {
              checked: i,
              className: "tw-mr-2 tw-m-0 tw-h-6 tw-w-6 tw-cursor-pointer tw-accent-surface-action",
              name: s,
              onChange: () => v(e.value),
              type: "radio"
            }
          ),
          /* @__PURE__ */ t(a.Label, { as: "label", className: "tw-text-sm tw-cursor-pointer", children: e.label })
        ] }) }, e.value))
      }
    )
  ] }) });
}
N.displayName = "RadioGroup";
export {
  N as RadioGroup
};
