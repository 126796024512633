import m, { useRef as p, useMemo as T } from "react";
import { Hidden as h, Features as v } from "./designsystem-ui-react117.js";
import { useEvent as d } from "./designsystem-ui-react92.js";
import { useOwnerDocument as M } from "./designsystem-ui-react97.js";
function C({ defaultContainers: o = [], portals: s, mainTreeNodeRef: r } = {}) {
  var a;
  let n = p((a = r == null ? void 0 : r.current) != null ? a : null), i = M(n), f = d(() => {
    var u, l, c;
    let t = [];
    for (let e of o)
      e !== null && (e instanceof HTMLElement ? t.push(e) : "current" in e && e.current instanceof HTMLElement && t.push(e.current));
    if (s != null && s.current)
      for (let e of s.current)
        t.push(e);
    for (let e of (u = i == null ? void 0 : i.querySelectorAll("html > *, body > *")) != null ? u : [])
      e !== document.body && e !== document.head && e instanceof HTMLElement && e.id !== "headlessui-portal-root" && (e.contains(n.current) || e.contains((c = (l = n.current) == null ? void 0 : l.getRootNode()) == null ? void 0 : c.host) || t.some((N) => e.contains(N)) || t.push(e));
    return t;
  });
  return { resolveContainers: f, contains: d((u) => f().some((l) => l.contains(u))), mainTreeNodeRef: n, MainTreeNode: T(() => function() {
    return r != null ? null : m.createElement(h, { features: v.Hidden, ref: n });
  }, [n, r]) };
}
function L() {
  let o = p(null);
  return { mainTreeNodeRef: o, MainTreeNode: T(() => function() {
    return m.createElement(h, { features: v.Hidden, ref: o });
  }, [o]) };
}
export {
  L as useMainTreeNode,
  C as useRootContainers
};
