import { view } from "@risingstack/react-easy-state";
import { IconSpinner } from "@tine/designsystem-icons/sharp";
import { cn } from "@tine/designsystem-utils";
import { useEffect, useState } from "react";

import Modal from "./Modal";

interface LoadingProps {
   simple?: boolean;
   modal?: boolean;
   label?: string | null;
   className?: string;
   delay?: boolean;
}

const Loading = ({ simple = false, modal = false, label = null, className = "", delay = true }: LoadingProps) => {
   const [isVisible, setVisible] = useState(!delay);

   useEffect(() => {
      if (!delay) {
         return;
      }

      const timerId = setTimeout(() => {
         setVisible(true);
      }, 150);

      return () => {
         clearTimeout(timerId);
      };
   }, [delay]);

   if (!isVisible) {
      return null;
   }

   if (simple) {
      return (
         <div className={className}>
            <IconSpinner className={cn("tw-animate-spin")} />
            {label && <span>{label}</span>}
         </div>
      );
   }

   if (modal) {
      return (
         <Modal
            TitleIcon={() => <IconSpinner className="tw-h-5 tw-w-5 tw-animate-spin" />}
            name="Vennligst vent"
            isOpen={true}
            title="Vennligst vent"
            showCloseButton={false}
         >
            <div className="tw-flex tw-items-center tw-gap-2">{label && <span>{label}</span>}</div>
         </Modal>
      );
   }

   return (
      <div className={`tw-flex tw-justify-center ${className}`}>
         <div className={cn("tw-flex tw-gap-2 tw-text-center")}>
            <IconSpinner className={cn("tw-animate-spin", { "tw-w-1/12": label })} />
            {label && <span>{label}</span>}
         </div>
      </div>
   );
};

export default view(Loading);
