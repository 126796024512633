import s, { createContext as F, useState as G, useMemo as D, useContext as _, useRef as B, useEffect as K, Fragment as A } from "react";
import { useControllable as H } from "./designsystem-ui-react122.js";
import { useDisposables as N } from "./designsystem-ui-react112.js";
import { useEvent as b } from "./designsystem-ui-react92.js";
import { useId as O } from "./designsystem-ui-react94.js";
import { useResolveButtonType as U } from "./designsystem-ui-react109.js";
import { useSyncRefs as j } from "./designsystem-ui-react100.js";
import { Hidden as M, Features as W } from "./designsystem-ui-react117.js";
import { isDisabledReactIssue7711 as q } from "./designsystem-ui-react104.js";
import { attemptSubmit as z } from "./designsystem-ui-react129.js";
import { forwardRefWithAs as J, render as E, compact as Q } from "./designsystem-ui-react106.js";
import { Description as V, useDescriptions as X } from "./designsystem-ui-react107.js";
import { Keys as C } from "./designsystem-ui-react108.js";
import { Label as Y, useLabels as Z } from "./designsystem-ui-react131.js";
let w = F(null);
w.displayName = "GroupContext";
let ee = A;
function te(r) {
  var i;
  let [t, u] = G(null), [c, h] = Z(), [a, v] = X(), n = D(() => ({ switch: t, setSwitch: u, labelledby: c, describedby: a }), [t, u, c, a]), d = {}, y = r;
  return s.createElement(v, { name: "Switch.Description" }, s.createElement(h, { name: "Switch.Label", props: { htmlFor: (i = n.switch) == null ? void 0 : i.id, onClick(m) {
    t && (m.currentTarget.tagName === "LABEL" && m.preventDefault(), t.click(), t.focus({ preventScroll: !0 }));
  } } }, s.createElement(w.Provider, { value: n }, E({ ourProps: d, theirProps: y, defaultTag: ee, name: "Switch.Group" }))));
}
let re = "button";
function ae(r, i) {
  var t;
  let u = O(), { id: c = `headlessui-switch-${u}`, checked: h, defaultChecked: a = !1, onChange: v, disabled: n = !1, name: d, value: y, form: m, ...g } = r, l = _(w), p = B(null), x = j(p, i, l === null ? null : l.setSwitch), [o, f] = H(h, v, a), S = b(() => f == null ? void 0 : f(!o)), T = b((e) => {
    if (q(e.currentTarget))
      return e.preventDefault();
    e.preventDefault(), S();
  }), $ = b((e) => {
    e.key === C.Space ? (e.preventDefault(), S()) : e.key === C.Enter && z(e.currentTarget);
  }), L = b((e) => e.preventDefault()), I = D(() => ({ checked: o }), [o]), P = { id: c, ref: x, role: "switch", type: U(r, p), tabIndex: r.tabIndex === -1 ? 0 : (t = r.tabIndex) != null ? t : 0, "aria-checked": o, "aria-labelledby": l == null ? void 0 : l.labelledby, "aria-describedby": l == null ? void 0 : l.describedby, disabled: n, onClick: T, onKeyUp: $, onKeyPress: L }, R = N();
  return K(() => {
    var e;
    let k = (e = p.current) == null ? void 0 : e.closest("form");
    k && a !== void 0 && R.addEventListener(k, "reset", () => {
      f(a);
    });
  }, [p, f]), s.createElement(s.Fragment, null, d != null && o && s.createElement(M, { features: W.Hidden, ...Q({ as: "input", type: "checkbox", hidden: !0, readOnly: !0, disabled: n, form: m, checked: o, name: d, value: y }) }), E({ ourProps: P, theirProps: g, slot: I, defaultTag: re, name: "Switch" }));
}
let le = J(ae), oe = te, Se = Object.assign(le, { Group: oe, Label: Y, Description: V });
export {
  Se as Switch
};
