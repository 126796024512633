import { useRef as i, useEffect as m } from "react";
import { getOwnerDocument as d } from "./designsystem-ui-react110.js";
import { useIsoMorphicEffect as E } from "./designsystem-ui-react123.js";
function T({ container: e, accept: r, walk: t, enabled: c = !0 }) {
  let o = i(r), n = i(t);
  m(() => {
    o.current = r, n.current = t;
  }, [r, t]), E(() => {
    if (!e || !c)
      return;
    let u = d(e);
    if (!u)
      return;
    let f = o.current, l = n.current, s = Object.assign((p) => f(p), { acceptNode: f }), a = u.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, s, !1);
    for (; a.nextNode(); )
      l(a.currentNode);
  }, [e, c, o, n]);
}
export {
  T as useTreeWalker
};
