function f(n = {}, t = null, e = []) {
  for (let [o, r] of Object.entries(n))
    l(e, u(t, o), r);
  return e;
}
function u(n, t) {
  return n ? n + "[" + t + "]" : t;
}
function l(n, t, e) {
  if (Array.isArray(e))
    for (let [o, r] of e.entries())
      l(n, u(t, o.toString()), r);
  else
    e instanceof Date ? n.push([t, e.toISOString()]) : typeof e == "boolean" ? n.push([t, e ? "1" : "0"]) : typeof e == "string" ? n.push([t, e]) : typeof e == "number" ? n.push([t, `${e}`]) : e == null ? n.push([t, ""]) : f(e, t, n);
}
function a(n) {
  var t, e;
  let o = (t = n == null ? void 0 : n.form) != null ? t : n.closest("form");
  if (o) {
    for (let r of o.elements)
      if (r !== n && (r.tagName === "INPUT" && r.type === "submit" || r.tagName === "BUTTON" && r.type === "submit" || r.nodeName === "INPUT" && r.type === "image")) {
        r.click();
        return;
      }
    (e = o.requestSubmit) == null || e.call(o);
  }
}
export {
  a as attemptSubmit,
  f as objectToFormEntries
};
