import { jsxs as i, jsx as t } from "react/jsx-runtime";
import { isEmptyArray as D, cn as a } from "./designsystem-ui-react46.js";
import { FormDetails as S } from "./designsystem-ui-react14.js";
import { FormError as j } from "./designsystem-ui-react15.js";
import { Listbox as s } from "./designsystem-ui-react81.js";
import E from "./designsystem-ui-react49.js";
function I({
  label: m,
  isLabelHidden: o = !1,
  className: v,
  value: h,
  options: w,
  description: x,
  onChange: p,
  isDisabled: d,
  placeholder: g = "Select",
  tooltipPosition: k = "right",
  tooltipText: N = "",
  errorMessage: y = "",
  showErrorMessage: u = !1,
  required: z,
  optGroup: f = !1,
  isNative: F = !1,
  ...$
}) {
  var A;
  const c = Array.isArray(w[0]) || (A = w[0]) != null && A.options ? w.flatMap((e) => e.options) : w;
  if (D(c))
    return /* @__PURE__ */ i("div", { children: [
      /* @__PURE__ */ t("h2", { children: "Error:" }),
      /* @__PURE__ */ t("p", { children: "No options, or invalid options provided." })
    ] });
  const { label: O = "" } = h;
  return F ? /* @__PURE__ */ i("div", { className: a("tw-flex tw-flex-col tw-gap-2 tw-full tw-relative", o && "tw-gap-0", v), children: [
    /* @__PURE__ */ t(
      S,
      {
        description: x,
        isDisabled: d,
        isLabelHidden: o,
        label: m,
        tooltipPosition: k,
        tooltipText: N
      }
    ),
    /* @__PURE__ */ i(
      "select",
      {
        className: a(
          "tw-w-full tw-p-2 tw-rounded tw-border tw-border-ink-brand-default tw-text-base tw-transition-all tw-ease-in-out tw-duration-200 tw-cursor-pointer",
          "hover:tw-border-ink-brand-default-TODO:fix-hover focus:tw-border-ink-brand-default-TODO:fix-focus focus:tw-ring-2 focus:tw-ring-information-600 focus:tw-outline-none",
          d && "!tw-bg-base-50 !tw-text-ink-disabled !tw-cursor-not-allowed",
          u && "!tw-border-error-600"
        ),
        disabled: d,
        onChange: (e) => {
          const l = c.find((r) => r.value === e.target.value);
          l && p(l);
        },
        value: O,
        ...$,
        children: [
          /* @__PURE__ */ t("option", { disabled: !0, value: "", children: g }),
          f ? Array.isArray(w) && w.map((e, l) => /* @__PURE__ */ t("optgroup", { label: e.optGroupLabel || e.label, children: e.options.map((r) => /* @__PURE__ */ t("option", { disabled: r.isDisabled, value: r.value, children: r.label }, r.id)) }, `group-${l}`)) : null,
          !f && c.map((e) => /* @__PURE__ */ t("option", { disabled: e.isDisabled, value: e.value, children: e.label }, e.id))
        ]
      }
    ),
    /* @__PURE__ */ t(j, { errorMessage: y, showErrorMessage: u })
  ] }) : /* @__PURE__ */ i("div", { className: a("tw-flex tw-flex-col tw-gap-2 tw-full tw-relative", v), children: [
    /* @__PURE__ */ t(s, { disabled: d, onChange: p, value: h, children: ({ open: e }) => /* @__PURE__ */ i(
      "div",
      {
        className: a(
          "tw-flex tw-relative tw-flex-col tw-w-full tw-gap-2",
          o && "tw-gap-0",
          e && "tw-z-10"
        ),
        children: [
          /* @__PURE__ */ t(
            S,
            {
              description: x,
              isDisabled: d,
              isLabelHidden: o,
              label: m,
              tooltipPosition: k,
              tooltipText: N
            }
          ),
          /* @__PURE__ */ i("div", { className: a("tw-relative tw-w-full tw-bg-surface-default", e && "tw-drop-shadow-lg"), children: [
            /* @__PURE__ */ i(
              s.Button,
              {
                "aria-required": z,
                className: a(
                  "tw-text-left tw-w-full tw-cursor-pointer tw-text-base tw-p-2 tw-transition-all tw-ease-in-out tw-rounded tw-duration-200 tw-flex tw-items-center tw-justify-between",
                  "tw-border-ink-disabled tw-border-2 hover:tw-border-ink-brand-default",
                  // focus
                  "focus-visible:tw-ring-2 focus-visible:tw-ring-information-600 focus-visible:tw-outline-none",
                  e ? "tw-bg-surface-action tw-text-ink-brand-inverted tw-border-surface-action tw-rounded-b-none" : "tw-bg-surface-default tw-border-surface-ink-brand-default tw-rounded tw-text-ink-brand-default",
                  d && "!tw-bg-base-50 !tw-text-ink-disabled !tw-cursor-not-allowed ",
                  u && "!tw-border-error-600"
                ),
                children: [
                  O || g,
                  /* @__PURE__ */ t(
                    E,
                    {
                      "aria-hidden": "true",
                      className: a(
                        "tw-transition-all tw-ease-in-out tw-duration-300 tw-text-base tw-w-5 tw-h-5",
                        e ? "tw-rotate-180 tw-fill-ink-brand-inverted" : "tw-text-base-900 tw-rotate-0 tw-fill-ink-brand-default",
                        d && "tw-fill-ink-disabled"
                      )
                    }
                  )
                ]
              }
            ),
            /* @__PURE__ */ t(
              s.Options,
              {
                className: a(
                  // basic layout
                  "tw-absolute tw-z-10 tw-block tw-left-0 tw-overflow-clip tw-right-0 tw-w-full tw-rounded-b tw-bg-surface-default tw-m-0 tw-p-0 focus:tw-outline-none",
                  // reset
                  "tw-list-none"
                ),
                children: f && Array.isArray(w) ? w.map((l, r) => /* @__PURE__ */ i("div", { children: [
                  /* @__PURE__ */ t("div", { className: "tw-px-2 tw-py-1 tw-text-sm tw-font-semibold tw-text-ink-light", children: l.optGroupLabel || l.label }),
                  l.options.map((n) => /* @__PURE__ */ t(s.Option, { disabled: n.isDisabled, value: n, children: ({ selected: b, active: q, disabled: B }) => /* @__PURE__ */ t(
                    "div",
                    {
                      className: a(
                        "tw-relative tw-flex tw-cursor-default tw-select-none tw-p-2 tw-text-base tw-pl-4",
                        (b || q) && "tw-bg-surface-default-hover tw-cursor-pointer",
                        B && "tw-text-ink-disabled tw-cursor-not-allowed tw-bg-base-50"
                      ),
                      children: /* @__PURE__ */ t("span", { children: n.label })
                    }
                  ) }, n.id))
                ] }, `group-${r}`)) : c.map((l) => /* @__PURE__ */ t(s.Option, { disabled: l.isDisabled, value: l, children: ({ selected: r, active: n, disabled: b }) => /* @__PURE__ */ t(
                  "div",
                  {
                    className: a(
                      "tw-relative tw-flex tw-cursor-default tw-select-none tw-p-2 tw-text-base",
                      (r || n) && "tw-bg-surface-default-hover tw-cursor-pointer",
                      b && "tw-text-ink-disabled tw-cursor-not-allowed tw-bg-base-50"
                    ),
                    children: /* @__PURE__ */ t("span", { children: l.label })
                  }
                ) }, l.id))
              }
            )
          ] })
        ]
      }
    ) }),
    /* @__PURE__ */ t(j, { errorMessage: y, showErrorMessage: u })
  ] });
}
I.displayName = "Select";
export {
  I as Select
};
