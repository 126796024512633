import { jsxs as f, jsx as e } from "react/jsx-runtime";
import { cn as o } from "./designsystem-ui-react46.js";
import { forwardRef as b } from "react";
import { Tag as v } from "./designsystem-ui-react39.js";
import k from "./designsystem-ui-react68.js";
import x from "./designsystem-ui-react69.js";
import y from "./designsystem-ui-react70.js";
import S from "./designsystem-ui-react71.js";
import j from "./designsystem-ui-react72.js";
import K from "./designsystem-ui-react73.js";
const n = (t, l, i = !1) => typeof t > "u" ? "" : typeof t == "string" && i ? t : typeof t == "object" ? t[l] ?? "" : "", N = b(
  ({ image: t, imageAlt: l, title: i, badges: s = [], href: d, as: m = "a", label: w, backgroundColor: p = "white", className: r }, u) => {
    const c = {
      white: "tw-bg-surface-default",
      transparent: "tw-bg-base-transparent"
    }[p], g = {
      debio: k,
      nokkelhull: x,
      nytNorge: y,
      okologisk: S,
      pant2Kr: j,
      pant3Kr: K
    };
    return /* @__PURE__ */ f(
      "div",
      {
        className: o(
          "tw-inline-flex tw-relative tw-p-3 tw-flex-col tw-gap-3 tw-justify-center tw-items-center tw-rounded-lg tw-overflow-clip tw-text-link-default hover:-tw-translate-y-1 tw-duration-300 tw-transition-all",
          c,
          n(r, "container", !0)
        ),
        ref: u,
        children: [
          /* @__PURE__ */ f("div", { className: "tw-relative", children: [
            /* @__PURE__ */ e(
              "img",
              {
                alt: l,
                className: o("tw-mix-blend-darken", n(r, "image")),
                height: 120,
                src: t,
                width: 120
              }
            ),
            /* @__PURE__ */ e("div", { className: o("tw-absolute tw-top-2 tw-right-0 tw-flex tw-flex-col", n(r, "badges")), children: s.map((a) => {
              const h = g[a];
              return /* @__PURE__ */ e(h, {}, a);
            }) })
          ] }),
          w ? /* @__PURE__ */ e(
            v,
            {
              className: o("tw-absolute tw-inline-block tw-left-3 tw-top-3", n(r, "label")),
              size: "small",
              children: w
            },
            w
          ) : null,
          i ? /* @__PURE__ */ e(
            m,
            {
              className: "tw-no-underline tw-text-ink-brand-default tw-outline-none focus-visible:tw-ring tw-rounded tw-max-w-36 tw-text-center",
              href: d || null,
              children: /* @__PURE__ */ e(
                "p",
                {
                  className: o(
                    "hover:tw-underline before:tw-absolute before:tw-inset-0 before:tw-z-0",
                    n(r, "title")
                  ),
                  children: i
                }
              )
            }
          ) : null
        ]
      }
    );
  }
);
N.displayName = "ProductCardSimple";
export {
  N as ProductCardSimple
};
