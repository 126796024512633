import h, { createContext as g, useContext as m, useRef as P, useMemo as p, useState as C, useEffect as d, Fragment as E } from "react";
import { createPortal as O } from "react-dom";
import { useEvent as c } from "./designsystem-ui-react92.js";
import { useIsoMorphicEffect as v } from "./designsystem-ui-react123.js";
import { useOnUnmount as A } from "./designsystem-ui-react114.js";
import { useOwnerDocument as b } from "./designsystem-ui-react97.js";
import { useServerHandoffComplete as I } from "./designsystem-ui-react99.js";
import { useSyncRefs as y, optionalRef as T } from "./designsystem-ui-react100.js";
import { usePortalRoot as U } from "./designsystem-ui-react102.js";
import { env as S } from "./designsystem-ui-react130.js";
import { forwardRefWithAs as R, render as $ } from "./designsystem-ui-react106.js";
function _(a) {
  let l = U(), u = m(x), e = b(a), [o, n] = C(() => {
    if (!l && u !== null || S.isServer)
      return null;
    let r = e == null ? void 0 : e.getElementById("headlessui-portal-root");
    if (r)
      return r;
    if (e === null)
      return null;
    let t = e.createElement("div");
    return t.setAttribute("id", "headlessui-portal-root"), e.body.appendChild(t);
  });
  return d(() => {
    o !== null && (e != null && e.body.contains(o) || e == null || e.body.appendChild(o));
  }, [o, e]), d(() => {
    l || u !== null && n(u.current);
  }, [u, n, l]), o;
}
let j = E;
function w(a, l) {
  let u = a, e = P(null), o = y(T((s) => {
    e.current = s;
  }), l), n = b(e), r = _(e), [t] = C(() => {
    var s;
    return S.isServer ? null : (s = n == null ? void 0 : n.createElement("div")) != null ? s : null;
  }), i = m(f), N = I();
  return v(() => {
    !r || !t || r.contains(t) || (t.setAttribute("data-headlessui-portal", ""), r.appendChild(t));
  }, [r, t]), v(() => {
    if (t && i)
      return i.register(t);
  }, [i, t]), A(() => {
    var s;
    !r || !t || (t instanceof Node && r.contains(t) && r.removeChild(t), r.childNodes.length <= 0 && ((s = r.parentElement) == null || s.removeChild(r)));
  }), N ? !r || !t ? null : O($({ ourProps: { ref: o }, theirProps: u, defaultTag: j, name: "Portal" }), t) : null;
}
let D = E, x = g(null);
function F(a, l) {
  let { target: u, ...e } = a, o = { ref: y(l) };
  return h.createElement(x.Provider, { value: u }, $({ ourProps: o, theirProps: e, defaultTag: D, name: "Popover.Group" }));
}
let f = g(null);
function X() {
  let a = m(f), l = P([]), u = c((n) => (l.current.push(n), a && a.register(n), () => e(n))), e = c((n) => {
    let r = l.current.indexOf(n);
    r !== -1 && l.current.splice(r, 1), a && a.unregister(n);
  }), o = p(() => ({ register: u, unregister: e, portals: l }), [u, e, l]);
  return [l, p(() => function({ children: n }) {
    return h.createElement(f.Provider, { value: o }, n);
  }, [o])];
}
let G = R(w), M = R(F), Y = Object.assign(G, { Group: M });
export {
  Y as Portal,
  X as useNestedPortals
};
