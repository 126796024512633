import { view } from "@risingstack/react-easy-state";
import { Alert, AlertProps } from "@tine/designsystem-ui-react";
import { PropsWithChildren, useEffect } from "react";

import { sendErrorMessage } from "../common/tracking";
import { GAError } from "../common/types/trackingTypes";

import Button from "./Button";

interface ErrorAlertWithOptionalTrackingProps extends Omit<AlertProps, "children"> {
   onRetry?: () => void;
   gaErrorTracking?: GAError;
}

const ErrorAlertWithOptionalTracking = ({
   title,
   show = true,
   fullWidth,
   maxWidth,
   showCloseButton,
   cta,
   ctaText,
   ariaLive,
   onClose,
   onRetry,
   gaErrorTracking,
   className,
   children
}: PropsWithChildren<ErrorAlertWithOptionalTrackingProps>) => {
   useEffect(() => {
      show && gaErrorTracking && sendErrorMessage(gaErrorTracking);
   }, [show]);

   return (
      <Alert
         variant="error"
         title={title}
         show={show}
         fullWidth={fullWidth}
         maxWidth={maxWidth}
         showCloseButton={showCloseButton}
         cta={cta}
         ctaText={ctaText}
         ariaLive={ariaLive}
         onClose={onClose}
         className={className}
      >
         {children}
         {onRetry && (
            <Button variant="secondary" size="compact" className="tw-mt-2 tw-block" onClick={onRetry}>
               Prøv på nytt?
            </Button>
         )}
      </Alert>
   );
};

export default view(ErrorAlertWithOptionalTracking);
