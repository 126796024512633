import { __module as c } from "./designsystem-ui-react191.js";
import { m as E } from "./designsystem-ui-react192.js";
(function(b, n) {
  n = b.exports = g.debug = g.default = g, n.coerce = k, n.disable = h, n.enable = v, n.enabled = m, n.humanize = E, n.names = [], n.skips = [], n.formatters = {};
  var o;
  function d(r) {
    var e = 0, f;
    for (f in r)
      e = (e << 5) - e + r.charCodeAt(f), e |= 0;
    return n.colors[Math.abs(e) % n.colors.length];
  }
  function g(r) {
    function e() {
      if (e.enabled) {
        var f = e, l = +/* @__PURE__ */ new Date(), y = l - (o || l);
        f.diff = y, f.prev = o, f.curr = l, o = l;
        for (var i = new Array(arguments.length), u = 0; u < i.length; u++)
          i[u] = arguments[u];
        i[0] = n.coerce(i[0]), typeof i[0] != "string" && i.unshift("%O");
        var a = 0;
        i[0] = i[0].replace(/%([a-zA-Z%])/g, function(t, A) {
          if (t === "%%")
            return t;
          a++;
          var s = n.formatters[A];
          if (typeof s == "function") {
            var C = i[a];
            t = s.call(f, C), i.splice(a, 1), a--;
          }
          return t;
        }), n.formatArgs.call(f, i);
        var w = e.log || n.log || console.log.bind(console);
        w.apply(f, i);
      }
    }
    return e.namespace = r, e.enabled = n.enabled(r), e.useColors = n.useColors(), e.color = d(r), typeof n.init == "function" && n.init(e), e;
  }
  function v(r) {
    n.save(r), n.names = [], n.skips = [];
    for (var e = (typeof r == "string" ? r : "").split(/[\s,]+/), f = e.length, l = 0; l < f; l++)
      e[l] && (r = e[l].replace(/\*/g, ".*?"), r[0] === "-" ? n.skips.push(new RegExp("^" + r.substr(1) + "$")) : n.names.push(new RegExp("^" + r + "$")));
  }
  function h() {
    n.enable("");
  }
  function m(r) {
    var e, f;
    for (e = 0, f = n.skips.length; e < f; e++)
      if (n.skips[e].test(r))
        return !1;
    for (e = 0, f = n.names.length; e < f; e++)
      if (n.names[e].test(r))
        return !0;
    return !1;
  }
  function k(r) {
    return r instanceof Error ? r.stack || r.message : r;
  }
})(c, c.exports);
var R = c.exports;
export {
  R as d
};
