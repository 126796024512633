import * as a from "react";
function r(t, n) {
  return t === n && (t !== 0 || 1 / t === 1 / n) || t !== t && n !== n;
}
const i = typeof Object.is == "function" ? Object.is : r, { useState: f, useEffect: p, useLayoutEffect: S, useDebugValue: h } = a;
function l(t, n, c) {
  const u = n(), [{ inst: s }, e] = f({ inst: { value: u, getSnapshot: n } });
  return S(() => {
    s.value = u, s.getSnapshot = n, o(s) && e({ inst: s });
  }, [t, u, n]), p(() => (o(s) && e({ inst: s }), t(() => {
    o(s) && e({ inst: s });
  })), [t]), h(u), u;
}
function o(t) {
  const n = t.getSnapshot, c = t.value;
  try {
    const u = n();
    return !i(c, u);
  } catch {
    return !0;
  }
}
export {
  l as useSyncExternalStore
};
