import { jsx as v, jsxs as h } from "react/jsx-runtime";
import { cn as l } from "./designsystem-ui-react46.js";
function c({
  href: r = "",
  variant: s = "default",
  children: a,
  icon: e = null,
  iconPosition: n = "right",
  noUnderline: o = !1,
  className: w,
  as: t = "a",
  ...d
}) {
  const u = {
    default: "tw-text-link-default *:tw-fill-text-ink-brand-default",
    inverted: "hover:tw-text-link-default-inverted-hover *:tw-fill-link-inverted tw-text-link-inverted *:hover:tw-fill-link-inverted-hover"
  }[s], f = t === "a", i = !!e;
  return /* @__PURE__ */ v(
    t,
    {
      href: r,
      ...d,
      className: l(
        // common
        "tw-rounded tw-underline-offset-2 tw-underline hover:tw-no-underline",
        // focus
        "focus-visible:tw-ring-2  focus-visible:tw-ring-information-600 focus-visible:tw-outline-none",
        u,
        f && "tw-inline-flex tw-items-center tw-flex-wrap",
        // for hasIcon
        i && "tw-gap-x-1 tw-no-underline hover:!tw-underline",
        // for noUnderline
        o && "tw-no-underline hover:!tw-no-underline",
        // for override
        w
      ),
      children: /* @__PURE__ */ h("span", { className: l(i && "[&>svg]:tw-size-5 [&>svg]:tw-inline"), children: [
        n === "left" ? e : null,
        a,
        n === "right" ? e : null
      ] })
    }
  );
}
c.displayName = "Link";
export {
  c as Link
};
