var e, r;
function _() {
  if (r)
    return e;
  r = 1;
  var t = "SECRET_DO_NOT_PASS_THIS_OR_YOU_WILL_BE_FIRED";
  return e = t, e;
}
export {
  _ as __require
};
