import o, { createContext as l, useContext as r } from "react";
let n = l(null);
n.displayName = "OpenClosedContext";
var s = ((e) => (e[e.Open = 1] = "Open", e[e.Closed = 2] = "Closed", e[e.Closing = 4] = "Closing", e[e.Opening = 8] = "Opening", e))(s || {});
function d() {
  return r(n);
}
function i({ value: e, children: t }) {
  return o.createElement(n.Provider, { value: e }, t);
}
export {
  i as OpenClosedProvider,
  s as State,
  d as useOpenClosed
};
