import { useEffect as u } from "react";
import { useLatestValue as f } from "./designsystem-ui-react124.js";
function E(e, t, i, r) {
  let n = f(i);
  u(() => {
    e = e ?? window;
    function s(o) {
      n.current(o);
    }
    return e.addEventListener(t, s, r), () => e.removeEventListener(t, s, r);
  }, [e, t, r]);
}
export {
  E as useEventListener
};
