import { view } from "@risingstack/react-easy-state";
import { IconBarcodeScanner } from "@tine/designsystem-icons/sharp";

import useMediaQuery from "../../common/useMediaQuery";
import { mediaQueries } from "../../common/utils";

import barcodeStore from "../../stores/barcodeStore";
import featuresStore from "../../stores/features/featuresStore";

import Button from "../Button";

type BarcodeScannerButtonProps = {
   [key: string]: any;
};

const BarcodeScannerButton = (props: BarcodeScannerButtonProps) => {
   if (!featuresStore.barcodeScannerEnabled) {
      return null;
   }

   return (
      <div {...props}>
         <Button
            icon={<IconBarcodeScanner />}
            iconPosition="left"
            variant="tertiary"
            size="regular"
            onClick={barcodeStore.showModal}
            trackingName="Skanner"
         >
            {useMediaQuery(mediaQueries.xl) ? "Skanner" : ""}
         </Button>
      </div>
   );
};

export default view(BarcodeScannerButton);
