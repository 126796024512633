import { jsx as u, jsxs as g } from "react/jsx-runtime";
import { cn as a } from "./designsystem-ui-react46.js";
function p({
  children: l,
  icon: t = null,
  iconPosition: e = "right",
  className: s,
  as: b = "button",
  checked: r = !1,
  onClick: o,
  disabled: i,
  ...w
}) {
  const n = !!t;
  return /* @__PURE__ */ u(
    b,
    {
      ...w,
      "aria-pressed": r,
      className: a(
        // common
        "tw-bg-chip-default tw-rounded-full tw-p-2 tw-cursor-pointer tw-flex tw-items-center",
        // Border
        "tw-border-button-primary-bg",
        // Hover
        "hover:tw-bg-chip-default-hover",
        // for hasIcon
        n && "tw-no-underline [&>svg]:tw-ring",
        // for checked
        r && "tw-bg-button-primary-bg tw-text-base-0 hover:tw-bg-button-primary-bg-hover",
        // for disabled
        i && "tw-bg-button-disabled-bg tw-text-ink-disabled hover:tw-bg-button-disabled-bg",
        // for override
        s
      ),
      onClick: o,
      children: /* @__PURE__ */ g("span", { className: a(n && "tw-flex tw-items-center tw-gap-x-1"), children: [
        e === "left" ? t : null,
        l,
        e === "right" ? t : null
      ] })
    }
  );
}
p.displayName = "Chip";
export {
  p as Chip
};
