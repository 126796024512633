import { jsxs as w, jsx as r } from "react/jsx-runtime";
import { useRef as m, useEffect as p } from "react";
import l from "./designsystem-ui-react84.js";
import h from "./designsystem-ui-react51.js";
function d({ iconVariant: s }) {
  const t = "tw-cursor-pointer tw-fill-surface-action";
  switch (s) {
    case "help":
      return /* @__PURE__ */ r(l, { className: t, height: 20, width: 20 });
    case "information":
      return /* @__PURE__ */ r(h, { className: t, height: 20, width: 20 });
    default:
      return /* @__PURE__ */ r(l, { className: t, height: 20, width: 20 });
  }
}
function b({ tooltipText: s, tooltipPosition: t = "bottom", iconVariant: i = "help" }) {
  const o = m(null), a = () => {
    o.current && (o.current.style.visibility = "visible");
  }, n = () => {
    o.current && (o.current.style.visibility = "hidden");
  };
  p(() => {
    const e = (f) => {
      f.key === "Escape" && n();
    };
    return document.addEventListener("keydown", e), () => {
      document.removeEventListener("keydown", e);
    };
  }, []);
  const c = (e) => {
    switch (e) {
      case "top":
        return "tw-bottom-6 tw-left-1/2 tw-transform -tw-translate-x-1/2";
      case "bottom":
        return "tw-top-7 tw-left-1/2 tw-transform -tw-translate-x-1/2";
      case "left":
        return "tw-right-7 tw-top-1/2 tw-transform -tw-translate-y-1/2";
      case "right":
        return "tw-left-7 tw-top-1/2 tw-transform -tw-translate-y-1/2";
      default:
        return "tw-bottom-full tw-left-1/2 tw-transform -tw-translate-x-1/2";
    }
  }, u = (e) => {
    switch (e) {
      case "top":
        return "tw-bottom-[-6px] tw-left-1/2 tw-transform -tw-translate-x-1/2";
      case "right":
        return "tw-left-[-12px] tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-rotate-90";
      case "bottom":
        return "tw-top-[-6px] tw-left-1/2 tw-transform -tw-translate-x-1/2 tw-rotate-180";
      case "left":
        return "tw-right-[-12px] tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-rotate-[270deg]";
      default:
        return "tw-bottom-full tw-left-1/2 tw-transform tw-translate-x-1/2";
    }
  };
  return /* @__PURE__ */ w(
    "button",
    {
      "aria-describedby": "tooltip",
      "aria-label": "Tooltip",
      className: "tw-relative tw-flex tw-items-center tw-p-0 tw-bg-transparent tw-border-none tw-max-w-max tw-group",
      onBlur: n,
      onFocus: a,
      onMouseEnter: a,
      onMouseLeave: n,
      type: "button",
      children: [
        /* @__PURE__ */ r(d, { iconVariant: i }),
        /* @__PURE__ */ w(
          "div",
          {
            className: `tw-absolute tw-w-max tw-max-w-44 tw-text-ink-brand-inverted tw-shadow-lg tw-invisible group-focus:tw-visible group-hover:tw-visible tw-bg-surface-action tw-base-0 tw-p-2 tw-z-10 tw-text-sm ${c(t)} tw-rounded tw-whitespace-normal`,
            onBlur: n,
            ref: o,
            role: "tooltip",
            children: [
              s || null,
              /* @__PURE__ */ r(
                "svg",
                {
                  className: `tw-absolute tw-w-6 tw-h-3 ${u(t)}`,
                  fill: "none",
                  viewBox: "0 0 24 12",
                  xmlns: "http://www.w3.org/2000/svg",
                  children: /* @__PURE__ */ r("path", { className: "tw-fill-surface-action", d: "M12 12L0 0h24L12 12z" })
                }
              )
            ]
          }
        )
      ]
    }
  );
}
b.displayName = "Tooltip";
export {
  b as Tooltip
};
