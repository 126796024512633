import { __module as a } from "./designsystem-ui-react188.js";
(function(t, e) {
  Object.defineProperty(e, "__esModule", {
    value: !0
  }), e.default = ["ready", "stateChange", "playbackQualityChange", "playbackRateChange", "error", "apiChange", "volumeChange"], t.exports = e.default;
})(a, a.exports);
var o = a.exports;
export {
  o as e
};
