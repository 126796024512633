import { getDefaultExportFromCjs as e } from "./designsystem-ui-react152.js";
import { __module as r } from "./designsystem-ui-react153.js";
import { __require as o } from "./designsystem-ui-react154.js";
import { __require as t } from "./designsystem-ui-react155.js";
import { __require as p } from "./designsystem-ui-react156.js";
if (process.env.NODE_ENV !== "production") {
  var s = o(), i = !0;
  r.exports = t()(s.isElement, i);
} else
  r.exports = p()();
var m = r.exports;
const q = /* @__PURE__ */ e(m);
export {
  q as default
};
