import N, { createContext as Z, useReducer as xe, createRef as me, useRef as C, useCallback as ge, useMemo as P, useEffect as ee, useContext as te, Fragment as Oe } from "react";
import { useComputed as oe } from "./designsystem-ui-react121.js";
import { useControllable as Re } from "./designsystem-ui-react122.js";
import { useDisposables as V } from "./designsystem-ui-react112.js";
import { useEvent as p } from "./designsystem-ui-react92.js";
import { useId as K } from "./designsystem-ui-react94.js";
import { useIsoMorphicEffect as z } from "./designsystem-ui-react123.js";
import { useLatestValue as Se } from "./designsystem-ui-react124.js";
import { useOutsideClick as he } from "./designsystem-ui-react96.js";
import { useResolveButtonType as Le } from "./designsystem-ui-react109.js";
import { useSyncRefs as k } from "./designsystem-ui-react100.js";
import { useTextValue as ye } from "./designsystem-ui-react125.js";
import { useTrackedPointer as Ie } from "./designsystem-ui-react126.js";
import { Hidden as Te, Features as $e } from "./designsystem-ui-react117.js";
import { OpenClosedProvider as Pe, State as H, useOpenClosed as Ce } from "./designsystem-ui-react101.js";
import { isDisabledReactIssue7711 as De } from "./designsystem-ui-react104.js";
import { Focus as L, calculateActiveIndex as ke } from "./designsystem-ui-react127.js";
import { disposables as q } from "./designsystem-ui-react128.js";
import { isFocusableElement as we, FocusableMode as Ee, sortByDomNode as Fe } from "./designsystem-ui-react119.js";
import { objectToFormEntries as Ae } from "./designsystem-ui-react129.js";
import { match as D } from "./designsystem-ui-react105.js";
import { getOwnerDocument as Me } from "./designsystem-ui-react110.js";
import { Features as _, forwardRefWithAs as w, compact as Qe, render as E } from "./designsystem-ui-react106.js";
import { Keys as x } from "./designsystem-ui-react108.js";
var Be = ((e) => (e[e.Open = 0] = "Open", e[e.Closed = 1] = "Closed", e))(Be || {}), Ue = ((e) => (e[e.Single = 0] = "Single", e[e.Multi = 1] = "Multi", e))(Ue || {}), Ne = ((e) => (e[e.Pointer = 0] = "Pointer", e[e.Other = 1] = "Other", e))(Ne || {}), Ve = ((e) => (e[e.OpenListbox = 0] = "OpenListbox", e[e.CloseListbox = 1] = "CloseListbox", e[e.GoToOption = 2] = "GoToOption", e[e.Search = 3] = "Search", e[e.ClearSearch = 4] = "ClearSearch", e[e.RegisterOption = 5] = "RegisterOption", e[e.UnregisterOption = 6] = "UnregisterOption", e[e.RegisterLabel = 7] = "RegisterLabel", e))(Ve || {});
function G(e, i = (a) => a) {
  let a = e.activeOptionIndex !== null ? e.options[e.activeOptionIndex] : null, r = Fe(i(e.options.slice()), (u) => u.dataRef.current.domRef.current), l = a ? r.indexOf(a) : null;
  return l === -1 && (l = null), { options: r, activeOptionIndex: l };
}
let ze = { 1(e) {
  return e.dataRef.current.disabled || e.listboxState === 1 ? e : { ...e, activeOptionIndex: null, listboxState: 1 };
}, 0(e) {
  if (e.dataRef.current.disabled || e.listboxState === 0)
    return e;
  let i = e.activeOptionIndex, { isSelected: a } = e.dataRef.current, r = e.options.findIndex((l) => a(l.dataRef.current.value));
  return r !== -1 && (i = r), { ...e, listboxState: 0, activeOptionIndex: i };
}, 2(e, i) {
  var a;
  if (e.dataRef.current.disabled || e.listboxState === 1)
    return e;
  let r = G(e), l = ke(i, { resolveItems: () => r.options, resolveActiveIndex: () => r.activeOptionIndex, resolveId: (u) => u.id, resolveDisabled: (u) => u.dataRef.current.disabled });
  return { ...e, ...r, searchQuery: "", activeOptionIndex: l, activationTrigger: (a = i.trigger) != null ? a : 1 };
}, 3: (e, i) => {
  if (e.dataRef.current.disabled || e.listboxState === 1)
    return e;
  let a = e.searchQuery !== "" ? 0 : 1, r = e.searchQuery + i.value.toLowerCase(), l = (e.activeOptionIndex !== null ? e.options.slice(e.activeOptionIndex + a).concat(e.options.slice(0, e.activeOptionIndex + a)) : e.options).find((t) => {
    var n;
    return !t.dataRef.current.disabled && ((n = t.dataRef.current.textValue) == null ? void 0 : n.startsWith(r));
  }), u = l ? e.options.indexOf(l) : -1;
  return u === -1 || u === e.activeOptionIndex ? { ...e, searchQuery: r } : { ...e, searchQuery: r, activeOptionIndex: u, activationTrigger: 1 };
}, 4(e) {
  return e.dataRef.current.disabled || e.listboxState === 1 || e.searchQuery === "" ? e : { ...e, searchQuery: "" };
}, 5: (e, i) => {
  let a = { id: i.id, dataRef: i.dataRef }, r = G(e, (l) => [...l, a]);
  return e.activeOptionIndex === null && e.dataRef.current.isSelected(i.dataRef.current.value) && (r.activeOptionIndex = r.options.indexOf(a)), { ...e, ...r };
}, 6: (e, i) => {
  let a = G(e, (r) => {
    let l = r.findIndex((u) => u.id === i.id);
    return l !== -1 && r.splice(l, 1), r;
  });
  return { ...e, ...a, activationTrigger: 1 };
}, 7: (e, i) => ({ ...e, labelId: i.id }) }, J = Z(null);
J.displayName = "ListboxActionsContext";
function F(e) {
  let i = te(J);
  if (i === null) {
    let a = new Error(`<${e} /> is missing a parent <Listbox /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(a, F), a;
  }
  return i;
}
let X = Z(null);
X.displayName = "ListboxDataContext";
function A(e) {
  let i = te(X);
  if (i === null) {
    let a = new Error(`<${e} /> is missing a parent <Listbox /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(a, A), a;
  }
  return i;
}
function He(e, i) {
  return D(i.type, ze, e, i);
}
let Ke = Oe;
function We(e, i) {
  let { value: a, defaultValue: r, form: l, name: u, onChange: t, by: n = (s, d) => s === d, disabled: f = !1, horizontal: m = !1, multiple: g = !1, ...y } = e;
  const $ = m ? "horizontal" : "vertical";
  let I = k(i), [O = g ? [] : void 0, h] = Re(a, t, r), [b, o] = xe(He, { dataRef: me(), listboxState: 1, options: [], searchQuery: "", labelId: null, activeOptionIndex: null, activationTrigger: 1 }), v = C({ static: !1, hold: !1 }), M = C(null), Q = C(null), W = C(null), R = p(typeof n == "string" ? (s, d) => {
    let S = n;
    return (s == null ? void 0 : s[S]) === (d == null ? void 0 : d[S]);
  } : n), T = ge((s) => D(c.mode, { 1: () => O.some((d) => R(d, s)), 0: () => R(O, s) }), [O]), c = P(() => ({ ...b, value: O, disabled: f, mode: g ? 1 : 0, orientation: $, compare: R, isSelected: T, optionsPropsRef: v, labelRef: M, buttonRef: Q, optionsRef: W }), [O, f, g, b]);
  z(() => {
    b.dataRef.current = c;
  }, [c]), he([c.buttonRef, c.optionsRef], (s, d) => {
    var S;
    o({ type: 1 }), we(d, Ee.Loose) || (s.preventDefault(), (S = c.buttonRef.current) == null || S.focus());
  }, c.listboxState === 0);
  let re = P(() => ({ open: c.listboxState === 0, disabled: f, value: O }), [c, f, O]), ne = p((s) => {
    let d = c.options.find((S) => S.id === s);
    d && j(d.dataRef.current.value);
  }), ie = p(() => {
    if (c.activeOptionIndex !== null) {
      let { dataRef: s, id: d } = c.options[c.activeOptionIndex];
      j(s.current.value), o({ type: 2, focus: L.Specific, id: d });
    }
  }), ae = p(() => o({ type: 0 })), le = p(() => o({ type: 1 })), se = p((s, d, S) => s === L.Specific ? o({ type: 2, focus: L.Specific, id: d, trigger: S }) : o({ type: 2, focus: s, trigger: S })), ue = p((s, d) => (o({ type: 5, id: s, dataRef: d }), () => o({ type: 6, id: s }))), de = p((s) => (o({ type: 7, id: s }), () => o({ type: 7, id: null }))), j = p((s) => D(c.mode, { 0() {
    return h == null ? void 0 : h(s);
  }, 1() {
    let d = c.value.slice(), S = d.findIndex((U) => R(U, s));
    return S === -1 ? d.push(s) : d.splice(S, 1), h == null ? void 0 : h(d);
  } })), pe = p((s) => o({ type: 3, value: s })), ce = p(() => o({ type: 4 })), fe = P(() => ({ onChange: j, registerOption: ue, registerLabel: de, goToOption: se, closeListbox: le, openListbox: ae, selectActiveOption: ie, selectOption: ne, search: pe, clearSearch: ce }), []), be = { ref: I }, B = C(null), ve = V();
  return ee(() => {
    B.current && r !== void 0 && ve.addEventListener(B.current, "reset", () => {
      h == null || h(r);
    });
  }, [B, h]), N.createElement(J.Provider, { value: fe }, N.createElement(X.Provider, { value: c }, N.createElement(Pe, { value: D(c.listboxState, { 0: H.Open, 1: H.Closed }) }, u != null && O != null && Ae({ [u]: O }).map(([s, d], S) => N.createElement(Te, { features: $e.Hidden, ref: S === 0 ? (U) => {
    var Y;
    B.current = (Y = U == null ? void 0 : U.closest("form")) != null ? Y : null;
  } : void 0, ...Qe({ key: s, as: "input", type: "hidden", hidden: !0, readOnly: !0, form: l, disabled: f, name: s, value: d }) })), E({ ourProps: be, theirProps: y, slot: re, defaultTag: Ke, name: "Listbox" }))));
}
let je = "button";
function Ge(e, i) {
  var a;
  let r = K(), { id: l = `headlessui-listbox-button-${r}`, ...u } = e, t = A("Listbox.Button"), n = F("Listbox.Button"), f = k(t.buttonRef, i), m = V(), g = p((b) => {
    switch (b.key) {
      case x.Space:
      case x.Enter:
      case x.ArrowDown:
        b.preventDefault(), n.openListbox(), m.nextFrame(() => {
          t.value || n.goToOption(L.First);
        });
        break;
      case x.ArrowUp:
        b.preventDefault(), n.openListbox(), m.nextFrame(() => {
          t.value || n.goToOption(L.Last);
        });
        break;
    }
  }), y = p((b) => {
    switch (b.key) {
      case x.Space:
        b.preventDefault();
        break;
    }
  }), $ = p((b) => {
    if (De(b.currentTarget))
      return b.preventDefault();
    t.listboxState === 0 ? (n.closeListbox(), m.nextFrame(() => {
      var o;
      return (o = t.buttonRef.current) == null ? void 0 : o.focus({ preventScroll: !0 });
    })) : (b.preventDefault(), n.openListbox());
  }), I = oe(() => {
    if (t.labelId)
      return [t.labelId, l].join(" ");
  }, [t.labelId, l]), O = P(() => ({ open: t.listboxState === 0, disabled: t.disabled, value: t.value }), [t]), h = { ref: f, id: l, type: Le(e, t.buttonRef), "aria-haspopup": "listbox", "aria-controls": (a = t.optionsRef.current) == null ? void 0 : a.id, "aria-expanded": t.listboxState === 0, "aria-labelledby": I, disabled: t.disabled, onKeyDown: g, onKeyUp: y, onClick: $ };
  return E({ ourProps: h, theirProps: u, slot: O, defaultTag: je, name: "Listbox.Button" });
}
let qe = "label";
function Je(e, i) {
  let a = K(), { id: r = `headlessui-listbox-label-${a}`, ...l } = e, u = A("Listbox.Label"), t = F("Listbox.Label"), n = k(u.labelRef, i);
  z(() => t.registerLabel(r), [r]);
  let f = p(() => {
    var g;
    return (g = u.buttonRef.current) == null ? void 0 : g.focus({ preventScroll: !0 });
  }), m = P(() => ({ open: u.listboxState === 0, disabled: u.disabled }), [u]);
  return E({ ourProps: { ref: n, id: r, onClick: f }, theirProps: l, slot: m, defaultTag: qe, name: "Listbox.Label" });
}
let Xe = "ul", Ye = _.RenderStrategy | _.Static;
function _e(e, i) {
  var a;
  let r = K(), { id: l = `headlessui-listbox-options-${r}`, ...u } = e, t = A("Listbox.Options"), n = F("Listbox.Options"), f = k(t.optionsRef, i), m = V(), g = V(), y = Ce(), $ = y !== null ? (y & H.Open) === H.Open : t.listboxState === 0;
  ee(() => {
    var o;
    let v = t.optionsRef.current;
    v && t.listboxState === 0 && v !== ((o = Me(v)) == null ? void 0 : o.activeElement) && v.focus({ preventScroll: !0 });
  }, [t.listboxState, t.optionsRef]);
  let I = p((o) => {
    switch (g.dispose(), o.key) {
      case x.Space:
        if (t.searchQuery !== "")
          return o.preventDefault(), o.stopPropagation(), n.search(o.key);
      case x.Enter:
        if (o.preventDefault(), o.stopPropagation(), t.activeOptionIndex !== null) {
          let { dataRef: v } = t.options[t.activeOptionIndex];
          n.onChange(v.current.value);
        }
        t.mode === 0 && (n.closeListbox(), q().nextFrame(() => {
          var v;
          return (v = t.buttonRef.current) == null ? void 0 : v.focus({ preventScroll: !0 });
        }));
        break;
      case D(t.orientation, { vertical: x.ArrowDown, horizontal: x.ArrowRight }):
        return o.preventDefault(), o.stopPropagation(), n.goToOption(L.Next);
      case D(t.orientation, { vertical: x.ArrowUp, horizontal: x.ArrowLeft }):
        return o.preventDefault(), o.stopPropagation(), n.goToOption(L.Previous);
      case x.Home:
      case x.PageUp:
        return o.preventDefault(), o.stopPropagation(), n.goToOption(L.First);
      case x.End:
      case x.PageDown:
        return o.preventDefault(), o.stopPropagation(), n.goToOption(L.Last);
      case x.Escape:
        return o.preventDefault(), o.stopPropagation(), n.closeListbox(), m.nextFrame(() => {
          var v;
          return (v = t.buttonRef.current) == null ? void 0 : v.focus({ preventScroll: !0 });
        });
      case x.Tab:
        o.preventDefault(), o.stopPropagation();
        break;
      default:
        o.key.length === 1 && (n.search(o.key), g.setTimeout(() => n.clearSearch(), 350));
        break;
    }
  }), O = oe(() => {
    var o;
    return (o = t.buttonRef.current) == null ? void 0 : o.id;
  }, [t.buttonRef.current]), h = P(() => ({ open: t.listboxState === 0 }), [t]), b = { "aria-activedescendant": t.activeOptionIndex === null || (a = t.options[t.activeOptionIndex]) == null ? void 0 : a.id, "aria-multiselectable": t.mode === 1 ? !0 : void 0, "aria-labelledby": O, "aria-orientation": t.orientation, id: l, onKeyDown: I, role: "listbox", tabIndex: 0, ref: f };
  return E({ ourProps: b, theirProps: u, slot: h, defaultTag: Xe, features: Ye, visible: $, name: "Listbox.Options" });
}
let Ze = "li";
function et(e, i) {
  let a = K(), { id: r = `headlessui-listbox-option-${a}`, disabled: l = !1, value: u, ...t } = e, n = A("Listbox.Option"), f = F("Listbox.Option"), m = n.activeOptionIndex !== null ? n.options[n.activeOptionIndex].id === r : !1, g = n.isSelected(u), y = C(null), $ = ye(y), I = Se({ disabled: l, value: u, domRef: y, get textValue() {
    return $();
  } }), O = k(i, y);
  z(() => {
    if (n.listboxState !== 0 || !m || n.activationTrigger === 0)
      return;
    let R = q();
    return R.requestAnimationFrame(() => {
      var T, c;
      (c = (T = y.current) == null ? void 0 : T.scrollIntoView) == null || c.call(T, { block: "nearest" });
    }), R.dispose;
  }, [y, m, n.listboxState, n.activationTrigger, n.activeOptionIndex]), z(() => f.registerOption(r, I), [I, r]);
  let h = p((R) => {
    if (l)
      return R.preventDefault();
    f.onChange(u), n.mode === 0 && (f.closeListbox(), q().nextFrame(() => {
      var T;
      return (T = n.buttonRef.current) == null ? void 0 : T.focus({ preventScroll: !0 });
    }));
  }), b = p(() => {
    if (l)
      return f.goToOption(L.Nothing);
    f.goToOption(L.Specific, r);
  }), o = Ie(), v = p((R) => o.update(R)), M = p((R) => {
    o.wasMoved(R) && (l || m || f.goToOption(L.Specific, r, 0));
  }), Q = p((R) => {
    o.wasMoved(R) && (l || m && f.goToOption(L.Nothing));
  }), W = P(() => ({ active: m, selected: g, disabled: l }), [m, g, l]);
  return E({ ourProps: { id: r, ref: O, role: "option", tabIndex: l === !0 ? void 0 : -1, "aria-disabled": l === !0 ? !0 : void 0, "aria-selected": g, disabled: void 0, onClick: h, onFocus: b, onPointerEnter: v, onMouseEnter: v, onPointerMove: M, onMouseMove: M, onPointerLeave: Q, onMouseLeave: Q }, theirProps: t, slot: W, defaultTag: Ze, name: "Listbox.Option" });
}
let tt = w(We), ot = w(Ge), rt = w(Je), nt = w(_e), it = w(et), Dt = Object.assign(tt, { Button: ot, Label: rt, Options: nt, Option: it });
export {
  Dt as Listbox
};
