function o(r) {
  throw new Error("Unexpected object: " + r);
}
var u = ((r) => (r[r.First = 0] = "First", r[r.Previous = 1] = "Previous", r[r.Next = 2] = "Next", r[r.Last = 3] = "Last", r[r.Specific = 4] = "Specific", r[r.Nothing = 5] = "Nothing", r))(u || {});
function c(r, l) {
  let t = l.resolveItems();
  if (t.length <= 0)
    return null;
  let s = l.resolveActiveIndex(), n = s ?? -1;
  switch (r.focus) {
    case 0: {
      for (let e = 0; e < t.length; ++e)
        if (!l.resolveDisabled(t[e], e, t))
          return e;
      return s;
    }
    case 1: {
      for (let e = n - 1; e >= 0; --e)
        if (!l.resolveDisabled(t[e], e, t))
          return e;
      return s;
    }
    case 2: {
      for (let e = n + 1; e < t.length; ++e)
        if (!l.resolveDisabled(t[e], e, t))
          return e;
      return s;
    }
    case 3: {
      for (let e = t.length - 1; e >= 0; --e)
        if (!l.resolveDisabled(t[e], e, t))
          return e;
      return s;
    }
    case 4: {
      for (let e = 0; e < t.length; ++e)
        if (l.resolveId(t[e], e, t) === r.id)
          return e;
      return s;
    }
    case 5:
      return null;
    default:
      o(r);
  }
}
export {
  u as Focus,
  c as calculateActiveIndex
};
