import r, { createContext as o, useContext as n } from "react";
let t = o(!1);
function l() {
  return n(t);
}
function u(e) {
  return r.createElement(t.Provider, { value: e.force }, e.children);
}
export {
  u as ForcePortalRoot,
  l as usePortalRoot
};
