import s from "./designsystem-ui-react136.js";
import u from "react";
import g from "./designsystem-ui-react137.js";
import b from "./designsystem-ui-react138.js";
var m = Object.defineProperty, C = Object.defineProperties, E = Object.getOwnPropertyDescriptors, f = Object.getOwnPropertySymbols, I = Object.prototype.hasOwnProperty, N = Object.prototype.propertyIsEnumerable, v = (r, e, t) => e in r ? m(r, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : r[e] = t, c = (r, e) => {
  for (var t in e || (e = {}))
    I.call(e, t) && v(r, t, e[t]);
  if (f)
    for (var t of f(e))
      N.call(e, t) && v(r, t, e[t]);
  return r;
}, P = (r, e) => C(r, E(e)), A = (r, e, t) => new Promise((a, i) => {
  var o = (l) => {
    try {
      p(t.next(l));
    } catch (h) {
      i(h);
    }
  }, n = (l) => {
    try {
      p(t.throw(l));
    } catch (h) {
      i(h);
    }
  }, p = (l) => l.done ? a(l.value) : Promise.resolve(l.value).then(o, n);
  p((t = t.apply(r, e)).next());
});
function S(r, e) {
  var t, a;
  if (r.videoId !== e.videoId)
    return !0;
  const i = ((t = r.opts) == null ? void 0 : t.playerVars) || {}, o = ((a = e.opts) == null ? void 0 : a.playerVars) || {};
  return i.start !== o.start || i.end !== o.end;
}
function _(r = {}) {
  return P(c({}, r), {
    height: 0,
    width: 0,
    playerVars: P(c({}, r.playerVars), {
      autoplay: 0,
      start: 0,
      end: 0
    })
  });
}
function V(r, e) {
  return r.videoId !== e.videoId || !g(_(r.opts), _(e.opts));
}
function w(r, e) {
  var t, a, i, o;
  return r.id !== e.id || r.className !== e.className || ((t = r.opts) == null ? void 0 : t.width) !== ((a = e.opts) == null ? void 0 : a.width) || ((i = r.opts) == null ? void 0 : i.height) !== ((o = e.opts) == null ? void 0 : o.height) || r.iframeClassName !== e.iframeClassName || r.title !== e.title;
}
var O = {
  videoId: "",
  id: "",
  className: "",
  iframeClassName: "",
  style: {},
  title: "",
  loading: void 0,
  opts: {},
  onReady: () => {
  },
  onError: () => {
  },
  onPlay: () => {
  },
  onPause: () => {
  },
  onEnd: () => {
  },
  onStateChange: () => {
  },
  onPlaybackRateChange: () => {
  },
  onPlaybackQualityChange: () => {
  }
}, R = {
  videoId: s.string,
  id: s.string,
  className: s.string,
  iframeClassName: s.string,
  style: s.object,
  title: s.string,
  loading: s.oneOf(["lazy", "eager"]),
  opts: s.objectOf(s.any),
  onReady: s.func,
  onError: s.func,
  onPlay: s.func,
  onPause: s.func,
  onEnd: s.func,
  onStateChange: s.func,
  onPlaybackRateChange: s.func,
  onPlaybackQualityChange: s.func
}, d = class extends u.Component {
  constructor(r) {
    super(r), this.destroyPlayerPromise = void 0, this.onPlayerReady = (e) => {
      var t, a;
      return (a = (t = this.props).onReady) == null ? void 0 : a.call(t, e);
    }, this.onPlayerError = (e) => {
      var t, a;
      return (a = (t = this.props).onError) == null ? void 0 : a.call(t, e);
    }, this.onPlayerStateChange = (e) => {
      var t, a, i, o, n, p, l, h;
      switch ((a = (t = this.props).onStateChange) == null || a.call(t, e), e.data) {
        case d.PlayerState.ENDED:
          (o = (i = this.props).onEnd) == null || o.call(i, e);
          break;
        case d.PlayerState.PLAYING:
          (p = (n = this.props).onPlay) == null || p.call(n, e);
          break;
        case d.PlayerState.PAUSED:
          (h = (l = this.props).onPause) == null || h.call(l, e);
          break;
      }
    }, this.onPlayerPlaybackRateChange = (e) => {
      var t, a;
      return (a = (t = this.props).onPlaybackRateChange) == null ? void 0 : a.call(t, e);
    }, this.onPlayerPlaybackQualityChange = (e) => {
      var t, a;
      return (a = (t = this.props).onPlaybackQualityChange) == null ? void 0 : a.call(t, e);
    }, this.destroyPlayer = () => this.internalPlayer ? (this.destroyPlayerPromise = this.internalPlayer.destroy().then(() => this.destroyPlayerPromise = void 0), this.destroyPlayerPromise) : Promise.resolve(), this.createPlayer = () => {
      if (typeof document > "u")
        return;
      if (this.destroyPlayerPromise) {
        this.destroyPlayerPromise.then(this.createPlayer);
        return;
      }
      const e = P(c({}, this.props.opts), {
        videoId: this.props.videoId
      });
      this.internalPlayer = b(this.container, e), this.internalPlayer.on("ready", this.onPlayerReady), this.internalPlayer.on("error", this.onPlayerError), this.internalPlayer.on("stateChange", this.onPlayerStateChange), this.internalPlayer.on("playbackRateChange", this.onPlayerPlaybackRateChange), this.internalPlayer.on("playbackQualityChange", this.onPlayerPlaybackQualityChange), (this.props.title || this.props.loading) && this.internalPlayer.getIframe().then((t) => {
        this.props.title && t.setAttribute("title", this.props.title), this.props.loading && t.setAttribute("loading", this.props.loading);
      });
    }, this.resetPlayer = () => this.destroyPlayer().then(this.createPlayer), this.updatePlayer = () => {
      var e;
      (e = this.internalPlayer) == null || e.getIframe().then((t) => {
        this.props.id ? t.setAttribute("id", this.props.id) : t.removeAttribute("id"), this.props.iframeClassName ? t.setAttribute("class", this.props.iframeClassName) : t.removeAttribute("class"), this.props.opts && this.props.opts.width ? t.setAttribute("width", this.props.opts.width.toString()) : t.removeAttribute("width"), this.props.opts && this.props.opts.height ? t.setAttribute("height", this.props.opts.height.toString()) : t.removeAttribute("height"), this.props.title ? t.setAttribute("title", this.props.title) : t.setAttribute("title", "YouTube video player"), this.props.loading ? t.setAttribute("loading", this.props.loading) : t.removeAttribute("loading");
      });
    }, this.getInternalPlayer = () => this.internalPlayer, this.updateVideo = () => {
      var e, t, a, i;
      if (typeof this.props.videoId > "u" || this.props.videoId === null) {
        (e = this.internalPlayer) == null || e.stopVideo();
        return;
      }
      let o = !1;
      const n = {
        videoId: this.props.videoId
      };
      if ((t = this.props.opts) != null && t.playerVars && (o = this.props.opts.playerVars.autoplay === 1, "start" in this.props.opts.playerVars && (n.startSeconds = this.props.opts.playerVars.start), "end" in this.props.opts.playerVars && (n.endSeconds = this.props.opts.playerVars.end)), o) {
        (a = this.internalPlayer) == null || a.loadVideoById(n);
        return;
      }
      (i = this.internalPlayer) == null || i.cueVideoById(n);
    }, this.refContainer = (e) => {
      this.container = e;
    }, this.container = null, this.internalPlayer = null;
  }
  componentDidMount() {
    this.createPlayer();
  }
  componentDidUpdate(r) {
    return A(this, null, function* () {
      w(r, this.props) && this.updatePlayer(), V(r, this.props) && (yield this.resetPlayer()), S(r, this.props) && this.updateVideo();
    });
  }
  componentWillUnmount() {
    this.destroyPlayer();
  }
  render() {
    return /* @__PURE__ */ u.createElement("div", {
      className: this.props.className,
      style: this.props.style
    }, /* @__PURE__ */ u.createElement("div", {
      id: this.props.id,
      className: this.props.iframeClassName,
      ref: this.refContainer
    }));
  }
}, y = d;
y.propTypes = R;
y.defaultProps = O;
y.PlayerState = {
  UNSTARTED: -1,
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
  BUFFERING: 3,
  CUED: 5
};
var j = y;
export {
  j as default
};
