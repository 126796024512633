import { __module as t } from "./designsystem-ui-react193.js";
(function(r, e) {
  Object.defineProperty(e, "__esModule", {
    value: !0
  }), e.default = {
    BUFFERING: 3,
    ENDED: 0,
    PAUSED: 2,
    PLAYING: 1,
    UNSTARTED: -1,
    VIDEO_CUED: 5
  }, r.exports = e.default;
})(t, t.exports);
var o = t.exports;
export {
  o as P
};
