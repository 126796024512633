import includes from "lodash-es/includes";
import isNil from "lodash-es/isNil";

import { ORDER_UNIT } from "./productOrderTypes";

export interface ProductBasics {
   sku: string;
   name: string;
}
export type CustomerType = "Isbar" | "Dagligvare";
export type AccessoryType = "Saus" | "Strøssel" | "Kjeks" | "Annet tilbehør" | "Softisdeler";
export type categoryReference = { externalReference: string; name: string };

export interface Product extends ProductBasics {
   weight: number;
   buyableInBaseUnit: boolean;
   inAgreementAssortment: boolean;
   baseUnit: ORDER_UNIT;
   buyableInSalesUnit: boolean;
   salesUnit: ORDER_UNIT;
   variableWeight: boolean;
   conversionFactor: number;
   comparativeUnit: string | null;
   epdNumber: string | null;
   name: string;
   description?: string | TrustedHTML;
   eans: Ean[];
   preservable: string | null;
   type: string;
   similarProducts: string[];
   newUntilDate: string | null;
   brand: string | null;
   certifications: string[] | null;
   countryOfOrigin: string | null;
   searchWord: string[];
   nutritions: NutritionLine[];
   allergens: string | null;
   ingredients: string | TrustedHTML | null;
   packaging: string | null;
   storage: string | null;
   recyclingChargeBaseUnit: number;
   objectID: string;
   leadTime: string | null;
   miniTank: boolean;
   url_key: string;
   size?: string;
   taste?: string;
   badges: Badge[];
   labels: Label[];
   price: number | null;
   unit: ORDER_UNIT;
   priceBeforeDiscount: number | null;
   comparisonPrice: number | null;
   comparisonUnit: string | null;
   vatRate: number | null;
   customerType?: CustomerType;
   parentCategory?: categoryReference;
   category?: categoryReference;
   accessoryType?: AccessoryType;
   newSku?: string;
}

export type SimplifiedProduct = Pick<Product, "sku" | "name" | "unit" | "price" | "conversionFactor">;

export type AssortmentEntry = {
   itemId: string;
   inAgreementAssortment: boolean;
   preferredProduct?: boolean;
   dpakPrice: number | null;
   dpakPriceBeforeDiscount: number | null;
   dpakPerUnitPrice?: number | null;
   dpakPerUnitPriceBeforeDiscount?: number | null;
   fpakPrice?: number | null;
   fpakPriceBeforeDiscount?: number | null;
   comparisonPriceUnit: string | null;
   comparisonPrice: number | null;
   sellByDate?: null;
   promotionalProduct?: boolean;
   canBeDeliveredAtDate?: null;
   canBuy?: boolean;
   cannotBuyCode?: null;
   VAT?: number;
   allowedOrderLineUnits?: string[];
};

export enum PageType {
   ProductListPage = "plp",
   ProductDetailsPage = "pdp"
}

export type Label = {
   text: string;
   color: "red" | "blue" | "green" | "gray" | "moss";
   position: "top-left" | "top-right" | "bottom-left" | "bottom-right";
   page: PageType[];
   icon?: React.ElementType;
};

export type BadgeKey = "nyt_norge" | "nokkelhullet" | "pant_2kr" | "pant_3kr";

export type Badge = {
   key: BadgeKey;
   name: string;
};

export type Ean = {
   sku: string;
   code: string;
   packaging: string;
};

export type NutritionLine = {
   name: string;
   value: string;
};

export type ProductTileCallbacks = {
   addToCart?: (product: Product, listname: string, position: number) => void;
   click?: (product: Product, listname: string, position: number) => void;
   impression?: (product: Product, listname: string, position: number) => void;
};

export type FlyingProductAnimationState = {
   animationId: string;
   x: number;
   y: number;
   height: number;
   sourceWidth: number;
   sku: string;
};

// These are the legal product attribute fields that you can build filters for, if you add
// filterAttributes for any of the sites you should also add them here.
export const legalFilterKeys = ["brand", "certifications", "size", "taste", "type", "accessoryType", "customerType"] as const;

export type LegalFilterKey = (typeof legalFilterKeys)[number];

export type FilterSettings = {
   field: LegalFilterKey;
   name: string;
   options: Record<string, string[]>;
   radioDefault?: string;
};

export type Filters = {
   [key in LegalFilterKey]?: string[];
};

export type ProductAvailability = {
   productNumber: string;
   plannedAvailableDate: string | null;
   delayedDeliveryDate: string | null;
   availableQuantity: number;
   customerWarehouse: string | null;
   warehouse: string | null;
   available: boolean;
   partialAvailable: boolean;
   supplyingWarehouse: string;
   atCustomersWarehouse: boolean;
   selectedQuantity: number;
   requestedQuantity: number;
};

export type ProductAndAvailability = {
   product: Product;
   availability: ProductAvailability;
};

export type CartItem = {
   sku: string;
   qty: number;
   unit: ORDER_UNIT;
   fromEditing: boolean;
};

export type ExpandedCartItem = CartItem & Pick<Product, "price" | "vatRate">;

export type CartItemDelta = CartItem & {
   qtyDelta: number;
};

export type LostSaleItem = {
   sku: string;
   quantity: number;
   orderLineUnit: ORDER_UNIT;
   isLostSales: true;
   lostSalesWareHouse: string;
};

export type CartTotals = {
   total: number;
   recyclingCharge: number;
};

export function hasKey<O extends object>(obj: O, key: string | number | symbol): key is keyof O {
   return key in obj;
}

export function isFilterAttribute(key: keyof Product): key is LegalFilterKey {
   return legalFilterKeys.includes(key as LegalFilterKey);
}

export function isTrustedHTML(src: string | TrustedHTML | undefined | null): src is TrustedHTML {
   if (isNil(src)) {
      return false;
   }
   return includes(src as string, "<");
}

export type ReplacementProduct = {
   oldSku: string;
   newSku: string;
};
