import { jsx as l } from "react/jsx-runtime";
import { cn as a } from "./designsystem-ui-react46.js";
import { useRef as n } from "react";
function s({
  className: w,
  children: o,
  fullWidth: t,
  transparent: d = !1,
  stickyHeader: e = !1,
  ref: r,
  ...b
}) {
  const f = n(null);
  return /* @__PURE__ */ l(
    "div",
    {
      className: a(
        t ? "tw-w-full" : "tw-w-fit",
        e ? "" : "tw-overflow-x-hidden",
        "tw-border tw-border-ink-border-default tw-rounded-md",
        w
      ),
      children: /* @__PURE__ */ l(
        "table",
        {
          cellSpacing: 0,
          className: a(
            "tw-text-left tw-table-fixed max-lg:tw-w-full tw-border-collapse tw-border-ink-border-subtle",
            t ? "tw-w-full" : "tw-w-auto",
            d ? "tw-bg-transparent" : "tw-bg-surface-default",
            {
              "tw-overflow-y-auto [&>thead]:tw-sticky [&>thead]:tw-top-0 [&>thead]:tw-z-10 [&>thead]:tw-border-b-ink-border-subtle": e
            }
          ),
          ref: r || f,
          ...b,
          children: o
        }
      )
    }
  );
}
s.displayName = "Table";
export {
  s as Table
};
