import { onDocumentReady as o } from "./designsystem-ui-react149.js";
let t = [];
o(() => {
  function e(n) {
    n.target instanceof HTMLElement && n.target !== document.body && t[0] !== n.target && (t.unshift(n.target), t = t.filter((d) => d != null && d.isConnected), t.splice(10));
  }
  window.addEventListener("click", e, { capture: !0 }), window.addEventListener("mousedown", e, { capture: !0 }), window.addEventListener("focus", e, { capture: !0 }), document.body.addEventListener("click", e, { capture: !0 }), document.body.addEventListener("mousedown", e, { capture: !0 }), document.body.addEventListener("focus", e, { capture: !0 });
});
export {
  t as history
};
