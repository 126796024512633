import { createClient, Entry } from "contentful";
import isUndefined from "lodash-es/isUndefined";

import {
   ContentfulArticle,
   ContentfulEntry,
   ContentfulProductCategory,
   ContentfulRecipe,
   ContentfulWarning,
   MenuItem,
   SectionRecipeOrArticleOrCalendarContent,
   UrlData,
   UrlDataWithoutFullUrl
} from "../../common/types/cmsTypes";
import {
   TypeAdventCalendarSlotSkeleton,
   TypeFooterSkeleton,
   TypeProductCategorySkeleton,
   TypeRecipeSkeleton,
   TypeV2_articleSkeleton,
   TypeV2_sectionSkeleton,
   TypeWarningSkeleton
} from "../../common/types/contentful";

export const PREVIEW_BASE_PATH = "_preview";

export const contentfulClient = (isPreviewMode: boolean) =>
   isPreviewMode
      ? createClient({
           host: "preview.contentful.com",
           space: process.env.CONTENTFUL_SPACE ?? "",
           accessToken: process.env.CONTENTFUL_PREVIEWTOKEN ?? "",
           environment: process.env.CONTENTFUL_ENV
        })
      : createClient({
           space: process.env.CONTENTFUL_SPACE ?? "",
           accessToken: process.env.CONTENTFUL_READTOKEN ?? "",
           environment: process.env.CONTENTFUL_ENV
        });

export const getArticleSlugsAndParents = (isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .withoutUnresolvableLinks.getEntries<TypeV2_articleSkeleton>({
         content_type: "v2_article",
         select: [
            "sys.contentType",
            "metadata.tags",
            "fields.title",
            "fields.slug",
            "fields.parentId",
            "fields.excludeSegments",
            "fields.includeSegments",
            "fields.visibleInSections"
         ],
         include: 1,
         limit: 1000
      })
      .then((response) => response.items);

export const getRecipeSlugsAndParents = (isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .withoutUnresolvableLinks.getEntries<TypeRecipeSkeleton>({
         content_type: "recipe",
         select: [
            "sys.contentType",
            "metadata.tags",
            "fields.title",
            "fields.slug",
            "fields.parentId",
            "fields.visibleInSections"
         ],
         include: 1,
         limit: 1000
      })
      .then((response) => response.items);

export const getAdventCalendarSlotSlugsAndParents = (isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .withoutUnresolvableLinks.getEntries<TypeAdventCalendarSlotSkeleton>({
         content_type: "adventCalendarSlot",
         select: ["sys.contentType", "metadata.tags", "fields.title", "fields.slug", "fields.parentId"],
         include: 1,
         limit: 1000
      })
      .then((response) => response.items);

export const getArticlesInSection = (sectionId: string, isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .getEntries<TypeV2_articleSkeleton>({
         content_type: "v2_article",
         "fields.visibleInSections": sectionId,
         select: ["fields.title", "fields.callToAction", "fields.headerImage", "fields.orderPriority", "sys.contentType"],
         include: 0
      })
      .then((response) => response.items);

export const getRecipesInSection = (sectionId: string, isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .getEntries<TypeRecipeSkeleton>({
         content_type: "recipe",
         "fields.visibleInSections": sectionId,
         select: ["fields.title", "fields.recipeData", "fields.orderPriority", "sys.contentType"],
         include: 0
      })
      .then((response) => response.items);

export const getFooterContent = (isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .withoutUnresolvableLinks.getEntries<TypeFooterSkeleton>({
         content_type: "footer",
         select: [
            "fields.contactNumber",
            "fields.columnCustomerServiceLabel",
            "fields.openingHours",
            "fields.linksRichText",
            "fields.column2label",
            "fields.column2links",
            "fields.column3label",
            "fields.column3links",
            "fields.column4label",
            "fields.column4links",
            "fields.termsAndConditions"
         ],
         include: 1,
         limit: 1
      })
      .then((response) => response.items);

export const getSectionsSlugsAndParents = (isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .withoutUnresolvableLinks.getEntries<TypeV2_sectionSkeleton>({
         content_type: "v2_section",
         select: [
            "sys.contentType",
            "metadata.tags",
            "fields.title",
            "fields.slug",
            "fields.parentId",
            "fields.description",
            "fields.icon"
         ],
         include: 0
      })
      .then((response) => response.items);

const buildFullUrlFromParent = (page: ContentfulEntry): string => {
   const parent = page.fields.parent as ContentfulEntry | null;
   const slug = page.fields.slug as string;
   return parent ? `${buildFullUrlFromParent(parent)}/${slug}` : slug;
};

export const getFullContent = async (id: string, isPreviewMode: boolean) => {
   return await contentfulClient(isPreviewMode).getEntry<TypeV2_articleSkeleton | TypeV2_sectionSkeleton | TypeRecipeSkeleton>(
      id,
      {
         include: 3
      }
   );
};

export const getFullArticleContentByIds = async (ids: string[], isPreviewMode: boolean): Promise<ContentfulArticle[]> => {
   const entries = await contentfulClient(isPreviewMode).withoutUnresolvableLinks.getEntries<TypeV2_articleSkeleton>({
      content_type: "v2_article",
      "sys.id[in]": ids
   });
   return entries.items;
};

export const getFullRecipeContentByIds = async (ids: string[], isPreviewMode: boolean): Promise<ContentfulRecipe[]> => {
   const entries = await contentfulClient(isPreviewMode).getEntries<TypeRecipeSkeleton>({
      content_type: "recipe",
      "sys.id[in]": ids
   });
   return entries.items;
};

export const getUnprocessedUrlDataFromContent = (
   content: Entry<
      TypeV2_sectionSkeleton | TypeV2_articleSkeleton | TypeRecipeSkeleton | TypeAdventCalendarSlotSkeleton,
      "WITHOUT_UNRESOLVABLE_LINKS",
      "nb_NO"
   >
): UrlDataWithoutFullUrl => {
   const baseData = {
      id: content.sys.id,
      title: content.fields.title ?? "Uten tittel",
      slug: content.fields.slug ?? content.sys.id,
      type: content.sys.contentType.sys.id,
      parentId: content.fields.parentId
   };

   if ("visibleInSections" in content.fields) {
      return {
         ...baseData,
         visibleInSections: content.fields.visibleInSections as string[]
      };
   }

   return baseData;
};

export const buildFullUrlRecursively = (
   builtUrl: string,
   parentId: string | undefined,
   allPages: UrlDataWithoutFullUrl[]
): string => {
   if (isUndefined(parentId)) {
      return builtUrl;
   }
   const parent = allPages.find((possibleParent) => possibleParent.id === parentId);
   if (isUndefined(parent)) {
      return builtUrl;
   }
   return buildFullUrlRecursively(`${parent.slug}/${builtUrl}`, parent.parentId, allPages);
};

export const generateFullUrl = (page: UrlDataWithoutFullUrl, allPages: UrlDataWithoutFullUrl[]): UrlData => {
   const fullUrl = buildFullUrlRecursively(page.slug, page.parentId, allPages);
   return {
      ...page,
      fullUrl
   };
};

export const getWarnings = async (isPreviewMode: boolean): Promise<ContentfulWarning[]> => {
   const entries = await contentfulClient(isPreviewMode).withoutUnresolvableLinks.getEntries<TypeWarningSkeleton>({
      content_type: "warning",
      include: 1
   });
   return entries.items;
};

/**
 * Returns true if the content is tagged with the appriate tag to indicate that the content
 * should be featured in the main menu
 */
export const hasMainMenuTag = (content: SectionRecipeOrArticleOrCalendarContent) => {
   const MAIN_MENU_TAG = "visibleInMainMenu";
   return content.metadata.tags.filter((tag) => tag.sys.id === MAIN_MENU_TAG).length > 0;
};

export const createMenuItem = (content: SectionRecipeOrArticleOrCalendarContent, allUrlData: UrlData[]): MenuItem[] => {
   // Is this content even tagged to be featured in the main menu?
   if (!hasMainMenuTag(content)) {
      return [];
   }

   const urlForMenuItem = allUrlData.find((urlData) => urlData.id === content.sys.id)?.fullUrl;
   // If we are unable to generate a proper URL for the content, we skip it
   if (isUndefined(urlForMenuItem)) {
      return [];
   }

   return [
      {
         title: content.fields.title,
         id: content.sys.id,
         url: urlForMenuItem.startsWith("/") ? urlForMenuItem : `/${urlForMenuItem}`,
         contentType: content.sys.contentType.sys.id
      }
   ];
};

export const getProductCategoryContent = async (isPreviewMode: boolean): Promise<ContentfulProductCategory[]> => {
   const { items } = await contentfulClient(isPreviewMode).withoutUnresolvableLinks.getEntries<TypeProductCategorySkeleton>({
      content_type: "productCategory",
      include: 3
   });
   // Sort items ordered by 1)segmented include, 2)segmented exclude, 3) non-segmented.
   // Product category page displays only the first item.
   return items.sort((item1, item2) => {
      if (item1.fields.includeSegments && !item2.fields.includeSegments) {
         return -1;
      }
      if (!item1.fields.includeSegments && item2.fields.includeSegments) {
         return 1;
      }
      if (item1.fields.excludeSegments && !item2.fields.excludeSegments) {
         return -1;
      }
      if (!item1.fields.excludeSegments && item2.fields.excludeSegments) {
         return 1;
      }

      return 0;
   });
};

type ContentfulSectionArticleEntry = Entry<TypeV2_articleSkeleton, undefined, string>;

const sectionEntryComparator = (
   entry1: ContentfulSectionArticleEntry | ContentfulRecipe,
   entry2: ContentfulSectionArticleEntry | ContentfulRecipe
) => {
   const orderPriority1 = entry1.fields.orderPriority || 5;
   const orderPriority2 = entry2.fields.orderPriority || 5;
   return orderPriority1 - orderPriority2;
};
export const sortSectionContentEntries = (entries: ContentfulSectionArticleEntry[] | ContentfulRecipe[]) => {
   return entries.sort(sectionEntryComparator);
};
