import { view } from "@risingstack/react-easy-state";
import { IconSpinner } from "@tine/designsystem-icons/sharp";
import isNil from "lodash-es/isNil";

import { formatDate } from "../common/utils/dateUtils";

import loginState from "../stores/auth/loginState";
import deliveryDatesStore from "../stores/deliveryDates/deliveryDatesStore";

import Modal from "./Modal";

const ChangeDeliveryDateModal = () => {
   const currentDelivery = deliveryDatesStore.getCurrentDelivery();
   if (isNil(currentDelivery?.date)) {
      return null;
   }

   return (
      <Modal
         TitleIcon={() => <IconSpinner className="tw-h-5 tw-w-5 tw-animate-spin" />}
         name="Oppdaterer produkter og priser"
         title="Oppdaterer produkter og priser"
         isOpen={deliveryDatesStore.changingDeliveryDate && !loginState.is("DELIVERY_DATES_LOADING")}
         showCloseButton={false}
      >
         <p>
            Det hender at nye produkter er tilgjengelig eller andre priser gjelder når man bytter leveringsdag, derfor må vi hente
            et oppdatert produktutvalg og prisliste for <strong>{formatDate(currentDelivery?.date, true, true, false)}</strong>.
         </p>
      </Modal>
   );
};

export default view(ChangeDeliveryDateModal);
