import { store } from "@risingstack/react-easy-state";
import { navigate } from "wouter/use-browser-location";

import { backendIssueMessagesByLocale } from "../../common/errors";
import { sendLoginFailed, sendLoginStart, sendLoginSuccess } from "../../common/tracking";

import segmentStore from "../cms/segmentStore";
import deliveryDatesStore from "../deliveryDates/deliveryDatesStore";
import recommendationStore from "../recommendationStore";
import toastStore from "../toastStore";
import uiStore, { LOGIN_MODE } from "../uiStore";
import authStore from "./authStore";

export type LoginState =
   | "START_UP"
   | "VERIFY_SESSION"
   | "LOGIN_MODAL"
   | "CHECKING_LOGIN"
   | "NOT_LOGGED_IN"
   | "FORCED_LOGOUT"
   | "LOGGING_IN"
   | "COMPANY_SELECTION"
   | "COMPANY_SELECTED"
   | "FEATURES_LOADING"
   | "DELIVERY_DATES_LOADING"
   | "ASSORTMENT_LOADING"
   | "NOTIFICATIONS_LOADING"
   | "LOGGED_IN";

type LoginStateType = {
   currentState: LoginState;
   transitionTo(newState: LoginState): void;
   transitionToIfPossible(newState: LoginState): void;
   is(state: LoginState): boolean;
   isLoggingIn(): boolean;
};

const legalTransitions: Record<LoginState, LoginState[]> = {
   START_UP: ["NOT_LOGGED_IN", "VERIFY_SESSION"],
   VERIFY_SESSION: ["NOT_LOGGED_IN", "LOGGING_IN"],
   LOGGING_IN: ["COMPANY_SELECTION", "COMPANY_SELECTED"],
   COMPANY_SELECTION: ["COMPANY_SELECTED", "LOGGED_IN", "NOT_LOGGED_IN"],
   COMPANY_SELECTED: ["FEATURES_LOADING"],
   FEATURES_LOADING: ["DELIVERY_DATES_LOADING"],
   DELIVERY_DATES_LOADING: ["ASSORTMENT_LOADING", "LOGGED_IN"],
   ASSORTMENT_LOADING: ["NOTIFICATIONS_LOADING", "LOGGED_IN"],
   NOTIFICATIONS_LOADING: ["LOGGED_IN"],
   LOGGED_IN: ["NOT_LOGGED_IN", "COMPANY_SELECTION", "FORCED_LOGOUT"],
   NOT_LOGGED_IN: ["LOGIN_MODAL"],
   FORCED_LOGOUT: ["LOGIN_MODAL", "NOT_LOGGED_IN"],
   LOGIN_MODAL: ["CHECKING_LOGIN", "NOT_LOGGED_IN"],
   CHECKING_LOGIN: ["LOGIN_MODAL", "VERIFY_SESSION"]
};

/**
 * Because some of the components update their needs for login on mount and unmount
 * we want to give the page a few milliseconds to settle before we check if any components
 * require login. Most typical scenario this is needed is when code redirects to frontpage and
 * transitions in the next line of code.
 */
const openLoginModalIfNeeded = () => setTimeout(delayedOpenLoginModalIfNeeded, 250);

const delayedOpenLoginModalIfNeeded = () => {
   console.log(`LOGIN STATE: Checking if we should show login modal... ${authStore.currentlyRequiresLogin}`);

   const currentQueryParams = window.location.search;

   if (currentQueryParams.includes("newaccount=true")) {
      console.log(`LOGIN STATE: Showing modal because of new account param`);
      uiStore.switchLoginMode(LOGIN_MODE.RESET_PASSWORD_EMAIL);
      loginState.transitionTo("LOGIN_MODAL");
   } else if (currentQueryParams.includes("showlogin=true")) {
      console.log(`LOGIN STATE: Showing modal because of show login param`);
      uiStore.switchLoginMode(LOGIN_MODE.USERNAME);
      loginState.transitionTo("LOGIN_MODAL");
   } else if (authStore.currentlyRequiresLogin.length > 0) {
      console.log(`LOGIN STATE: Showing modal because of ${authStore.currentlyRequiresLogin}`);
      loginState.transitionTo("LOGIN_MODAL");
   }
};

/** Removes query parameter if present in current URL */
const dropQueryParameter = (parameterToRemove: string) => {
   if (!window.location.search.includes(parameterToRemove)) {
      return;
   }

   // Remove the parameter specified, rebuilds URL and navigates to it
   const searchParams = new URLSearchParams(window.location.search);
   searchParams.delete(parameterToRemove);
   const newSearch = searchParams.toString();
   navigate(`${window.location.pathname}${newSearch ? `?${newSearch}` : ""}`);
};

const transitionSideEffects: Partial<Record<`${LoginState} -> ${LoginState}`, () => void>> = {
   "VERIFY_SESSION -> NOT_LOGGED_IN": () => {
      console.log("LOGIN STATE: User token was not valid, not logged in");
      sendLoginFailed();
      openLoginModalIfNeeded();
   },

   "START_UP -> NOT_LOGGED_IN": () => {
      console.log("LOGIN STATE: User did not have a token");
      openLoginModalIfNeeded();
   },

   "NOT_LOGGED_IN -> LOGIN_MODAL": () => {
      sendLoginStart();
      console.log("LOGIN STATE: Showing login modal from not logged in");
   },

   "FORCED_LOGOUT -> LOGIN_MODAL": () => {
      sendLoginStart();
      console.log("LOGIN STATE: Showing login modal from forced logout");
   },

   "VERIFY_SESSION -> LOGIN_MODAL": () => {
      sendLoginStart();
      console.log("LOGIN STATE: Showing login modal because session verification failed");
   },

   "DELIVERY_DATES_LOADING -> LOGGED_IN": () => {
      if (authStore.error) {
         toastStore.addError("Feil", backendIssueMessagesByLocale[uiStore.locale][authStore.error.message], {
            context: "login",
            text: "fetch_delivery_dates_error"
         });
      }
      segmentStore.updateCurrentCustomerProperties();
      sendLoginSuccess();
      console.log("LOGIN STATE: User does not have delivery dates available, but we allow them in anyway...", {
         context: "login",
         text: "fetch_assortment_error"
      });
   },

   "ASSORTMENT_LOADING -> LOGGED_IN": () => {
      if (authStore.error) {
         toastStore.addError("Feil", backendIssueMessagesByLocale[uiStore.locale][authStore.error.message]);
      }
      segmentStore.updateCurrentCustomerProperties();
      sendLoginSuccess();
      console.log("LOGIN STATE: User does not have assortment available, but we allow them in anyway...");
   },

   "NOTIFICATIONS_LOADING -> LOGGED_IN": () => {
      segmentStore.updateCurrentCustomerProperties();
      sendLoginSuccess();
      dropQueryParameter("showlogin");
      void recommendationStore.getRecommendations();
      console.log("LOGIN STATE: Login complete");
   },

   "LOGIN_MODAL -> NOT_LOGGED_IN": () => {
      // Removes the query parameters when the login modal is closed
      dropQueryParameter("newaccount");
      dropQueryParameter("showlogin");
      authStore.clearResetPassword();
   },

   "LOGGED_IN -> NOT_LOGGED_IN": () => {
      authStore.logout();
      openLoginModalIfNeeded();
   },

   "LOGGED_IN -> FORCED_LOGOUT": () => {
      authStore.logout();
   },

   "COMPANY_SELECTION -> COMPANY_SELECTED": () => {
      deliveryDatesStore.clearDeliveryDates();
   }
};

const getTransitionName = (from: LoginState, to: LoginState): `${LoginState} -> ${LoginState}` => `${from} -> ${to}`;

const loginState: LoginStateType = store({
   currentState: "START_UP",

   transitionTo(newState) {
      if (!legalTransitions[loginState.currentState].includes(newState)) {
         console.error(`Illegal state transition from ${loginState.currentState} to ${newState}`);
         throw new Error(`Illegal state transition from ${loginState.currentState} to ${newState}`);
      }
      const transitionName = getTransitionName(loginState.currentState, newState);
      console.log(`LOGIN STATE: Transitioning ${transitionName}`);
      loginState.currentState = newState;

      if (transitionSideEffects[transitionName]) {
         console.log(`LOGIN STATE: Executing side effect for ${transitionName}`);
         transitionSideEffects[transitionName]!();
      }
   },

   transitionToIfPossible: (newState) => {
      if (legalTransitions[loginState.currentState].includes(newState)) {
         loginState.transitionTo(newState);
      }
   },

   is(state: LoginState) {
      return loginState.currentState === state;
   },

   isLoggingIn: () => {
      const loggingInStates: LoginState[] = [
         "VERIFY_SESSION",
         "LOGGING_IN",
         "COMPANY_SELECTION",
         "COMPANY_SELECTED",
         "FEATURES_LOADING",
         "DELIVERY_DATES_LOADING",
         "ASSORTMENT_LOADING",
         "NOTIFICATIONS_LOADING"
      ];
      return loggingInStates.some((state) => loginState.is(state));
   }
} satisfies LoginStateType);

export default loginState;
