import { jsx as n, jsxs as c, Fragment as v } from "react/jsx-runtime";
import { cn as i } from "./designsystem-ui-react46.js";
import { Link as u } from "./designsystem-ui-react21.js";
import h from "./designsystem-ui-react56.js";
const l = (e, d, f = !1) => typeof e > "u" ? "" : typeof e == "string" && f ? e : typeof e == "object" ? e[d] ?? "" : "";
function x({
  items: e = [],
  variant: d = "default",
  homeLink: f,
  className: r,
  homeLinkLabel: w
}) {
  const o = {
    default: "tw-fill-link-default",
    inverted: "tw-fill-link-inverted"
  }[d], p = {
    default: "tw-text-link-default",
    inverted: "tw-text-link-inverted"
  }[d];
  return /* @__PURE__ */ n("nav", { "aria-label": "Breadcrumb", className: i("tw-flex", l(r, "container", !0)), children: /* @__PURE__ */ n(
    "ol",
    {
      className: i(
        "tw-flex tw-flex-wrap tw-items-center tw-p-0 tw-m-0 tw-list-none tw-group",
        "[&>*:nth-last-child(2)]:tw-inline-block [&>*]:tw-hidden md:[&>*]:tw-flex [&>li:nth-last-child(2)>div>svg]:tw-rotate-180 md:[&>li:nth-last-child(2)>div>svg]:tw-rotate-0",
        l(r, "list")
      ),
      children: e.map((t) => {
        const s = e.indexOf(t) === 0, a = e.indexOf(t) === e.length - 1;
        return /* @__PURE__ */ n("li", { className: l(r, "item"), children: /* @__PURE__ */ c("div", { className: i("tw-flex tw-items-center"), children: [
          s && f ? /* @__PURE__ */ c(v, { children: [
            /* @__PURE__ */ n(u, { as: t.as, className: l(r, "link"), href: "/", variant: d, children: w }),
            /* @__PURE__ */ n(h, { className: i(o, l(r, "divider")) })
          ] }) : null,
          /* @__PURE__ */ n(
            h,
            {
              className: i(o, s ? "tw-hidden" : "", l(r, "divider"))
            }
          ),
          !a && /* @__PURE__ */ n(
            u,
            {
              "aria-current": t.current ? "page" : void 0,
              as: t.as,
              className: i("tw-underline", l(r, "link")),
              href: t.href,
              variant: d,
              children: t.label
            }
          ),
          a ? /* @__PURE__ */ n("p", { className: i("tw-m-0", p, l(r, "currentPage")), children: t.label }) : null
        ] }) }, t.href);
      })
    }
  ) });
}
x.displayName = "Breadcrumbs";
export {
  x as Breadcrumbs
};
