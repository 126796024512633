import { view } from "@risingstack/react-easy-state";
import { IconUser } from "@tine/designsystem-icons/sharp";
import { get } from "lodash-es";

import useQuery from "../../common/useQuery";

import loginState from "../../stores/auth/loginState";
import uiStore, { LOGIN_MODE } from "../../stores/uiStore";

import Modal from "../Modal";
import LoginSms from "./LoginSms";
import LoginUsername from "./LoginUsername";
import ResetPassword from "./reset-password/ResetPassword";

const LoginModal = () => {
   const queryParams = useQuery();

   const isNewAccount = get(queryParams, "newaccount", "") === "true";
   const showModal = loginState.is("LOGIN_MODAL") || loginState.is("CHECKING_LOGIN");

   const { loginMode, hideLoginModal } = uiStore;

   const hideModal = () => {
      hideLoginModal();
   };

   const renderContent = () => {
      switch (loginMode) {
         case LOGIN_MODE.USERNAME:
            return <LoginUsername />;
         case LOGIN_MODE.SMS:
            return <LoginSms />;
         case LOGIN_MODE.RESET_PASSWORD_EMAIL:
         case LOGIN_MODE.RESET_PASSWORD_OTP:
         case LOGIN_MODE.RESET_PASSWORD_SUCCESS:
            return <ResetPassword title={isNewAccount ? "Sett passord" : "Glemt passord?"} showBackToLogInText={!isNewAccount} />;
         default:
            return null;
      }
   };

   return (
      <Modal title="Logg inn" name="Logg inn" TitleIcon={IconUser} isOpen={showModal} onClose={hideModal} size="md">
         {renderContent()}
      </Modal>
   );
};

export default view(LoginModal);
