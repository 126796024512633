import { getDefaultExportFromCjs as T } from "./designsystem-ui-react152.js";
import { __module as f } from "./designsystem-ui-react157.js";
import { s as Y } from "./designsystem-ui-react158.js";
import { l as h } from "./designsystem-ui-react159.js";
import { Y as A } from "./designsystem-ui-react160.js";
(function(l, t) {
  Object.defineProperty(t, "__esModule", {
    value: !0
  });
  var p = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(e) {
    return typeof e;
  } : function(e) {
    return e && typeof Symbol == "function" && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  }, y = Y, d = o(y), v = h, m = o(v), c = A, i = o(c);
  function o(e) {
    return e && e.__esModule ? e : { default: e };
  }
  var n = void 0;
  t.default = function(e) {
    var u = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, _ = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : !1, r = (0, d.default)();
    if (n || (n = (0, m.default)(r)), u.events)
      throw new Error("Event handlers cannot be overwritten.");
    if (typeof e == "string" && !document.getElementById(e))
      throw new Error('Element "' + e + '" does not exist.');
    u.events = i.default.proxyEvents(r);
    var g = new Promise(function(s) {
      if ((typeof e > "u" ? "undefined" : p(e)) === "object" && e.playVideo instanceof Function) {
        var P = e;
        s(P);
      } else
        n.then(function(x) {
          var w = new x.Player(e, u);
          return r.on("ready", function() {
            s(w);
          }), null;
        });
    }), a = i.default.promisifyPlayer(g, _);
    return a.on = r.on, a.off = r.off, a;
  }, l.exports = t.default;
})(f, f.exports);
var S = f.exports;
const R = /* @__PURE__ */ T(S);
export {
  R as default
};
