import { jsxs as o, jsx as r } from "react/jsx-runtime";
import { cn as t } from "./designsystem-ui-react46.js";
import { useId as m } from "react";
import { FormError as g } from "./designsystem-ui-react15.js";
import x from "./designsystem-ui-react59.js";
function v({
  isChecked: l,
  description: n,
  label: w = "",
  errorMessage: i = "",
  showErrorMessage: c = !1,
  isDisabled: e = !1,
  variant: d = "default",
  onChange: s = () => null,
  centerAlign: u = !1,
  className: f
}) {
  const b = m(), a = typeof w == "string" ? w : b, h = {
    default: "tw-border-base-400 tw-bg-base-0 group-hover:tw-border-surface-action group-hover:tw-bg-surface-default-hover group-hover:checked:tw-bg-surface-action checked:tw-border-surface-action checked:tw-bg-surface-action",
    inverted: "tw-border-surface-default group-hover:tw-border-surface-default checked:tw-bg-base-0 checked:tw-border-surface-default group-hover:tw-bg-surface-default"
  }[d], p = {
    default: "tw-text-ink-brand-default",
    inverted: "tw-text-ink-brand-inverted"
  }[d], k = {
    default: "peer-checked:tw-fill-ink-brand-inverted",
    inverted: "peer-checked:tw-fill-ink-brand-default"
  }[d];
  return /* @__PURE__ */ o("div", { className: "tw-flex tw-flex-col tw-gap-2", children: [
    /* @__PURE__ */ o(
      "div",
      {
        className: t(
          "tw-text-ink-brand-default tw-flex tw-items-start tw-gap-2 tw-text-sm tw-rounded tw-ring-information-600 tw-group [&:has(:focus-visible)]:tw-ring",
          e && "!tw-text-ink-disabled",
          u && "tw-items-center",
          f
        ),
        children: [
          /* @__PURE__ */ o(
            "div",
            {
              className: t(
                "tw-relative tw-w-6 tw-h-6 tw-flex tw-shrink-0 tw-justify-center tw-items-center tw-rounded",
                e && "tw-cursor-not-allowed"
              ),
              children: [
                /* @__PURE__ */ r(
                  "input",
                  {
                    checked: l,
                    className: t(
                      "tw-w-full tw-h-full tw-absolute tw-m-0 tw-transition-colors tw-peer tw-rounded tw-appearance-none tw-cursor-pointer",
                      // border
                      "tw-border-2",
                      // focus
                      "focus:tw-outline-none",
                      // Checked
                      h,
                      // Disabled
                      e && "!tw-cursor-not-allowed tw-bg-base-50 tw-border-ink-disabled group-hover:!tw-border-base-400 group-hover:tw-bg-base-50 checked:tw-bg-base-50 checked:tw-border-base-200",
                      // Invalid state
                      c && "!tw-border-ink-error group-hover:!tw-border-ink-error !tw-bg-surface-error"
                    ),
                    disabled: e,
                    id: a,
                    onChange: s,
                    type: "checkbox"
                  }
                ),
                /* @__PURE__ */ r(
                  x,
                  {
                    className: t(
                      "tw-pointer-events-none tw-absolute peer-checked:tw-block tw-fill-ink-brand-default tw-hidden",
                      k,
                      e && "tw-fill-ink-disabled",
                      c && "!tw-fill-ink-error"
                    ),
                    height: 16,
                    width: 16
                  }
                )
              ]
            }
          ),
          /* @__PURE__ */ o("div", { className: t("tw-flex tw-flex-col tw-gap-1 ", p, e && "tw-text-ink-disabled"), children: [
            /* @__PURE__ */ r(
              "label",
              {
                className: t("tw-w-fit", e ? "tw-cursor-not-allowed" : "tw-cursor-pointer"),
                htmlFor: a,
                children: w
              }
            ),
            n ? /* @__PURE__ */ r("span", { className: "tw-text-sm tw-mb-0", children: n }) : null
          ] })
        ]
      }
    ),
    /* @__PURE__ */ r(g, { errorMessage: i, showErrorMessage: c })
  ] });
}
v.displayName = "Checkbox";
export {
  v as Checkbox
};
