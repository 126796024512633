import E, { createContext as q, useReducer as se, useRef as B, useMemo as F, useEffect as ue, useContext as z } from "react";
import { Description as pe, useDescriptions as Q } from "./designsystem-ui-react107.js";
import { Keys as k } from "./designsystem-ui-react108.js";
import { Label as de, useLabels as X } from "./designsystem-ui-react131.js";
import { useControllable as ce } from "./designsystem-ui-react122.js";
import { useDisposables as me } from "./designsystem-ui-react112.js";
import { useEvent as $ } from "./designsystem-ui-react92.js";
import { useFlags as fe } from "./designsystem-ui-react132.js";
import { useId as Y } from "./designsystem-ui-react94.js";
import { useIsoMorphicEffect as ve } from "./designsystem-ui-react123.js";
import { useLatestValue as be } from "./designsystem-ui-react124.js";
import { useSyncRefs as Z } from "./designsystem-ui-react100.js";
import { useTreeWalker as Re } from "./designsystem-ui-react133.js";
import { Hidden as ge, Features as Ee } from "./designsystem-ui-react117.js";
import { isDisabledReactIssue7711 as j } from "./designsystem-ui-react104.js";
import { focusIn as V, Focus as U, FocusResult as J, sortByDomNode as $e } from "./designsystem-ui-react119.js";
import { objectToFormEntries as he, attemptSubmit as ye } from "./designsystem-ui-react129.js";
import { match as ke } from "./designsystem-ui-react105.js";
import { getOwnerDocument as Fe } from "./designsystem-ui-react110.js";
import { forwardRefWithAs as ee, compact as Te, render as re } from "./designsystem-ui-react106.js";
var we = ((r) => (r[r.RegisterOption = 0] = "RegisterOption", r[r.UnregisterOption = 1] = "UnregisterOption", r))(we || {});
let Oe = { 0(r, t) {
  let o = [...r.options, { id: t.id, element: t.element, propsRef: t.propsRef }];
  return { ...r, options: $e(o, (d) => d.element.current) };
}, 1(r, t) {
  let o = r.options.slice(), d = r.options.findIndex((c) => c.id === t.id);
  return d === -1 ? r : (o.splice(d, 1), { ...r, options: o });
} }, H = q(null);
H.displayName = "RadioGroupDataContext";
function te(r) {
  let t = z(H);
  if (t === null) {
    let o = new Error(`<${r} /> is missing a parent <RadioGroup /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(o, te), o;
  }
  return t;
}
let K = q(null);
K.displayName = "RadioGroupActionsContext";
function oe(r) {
  let t = z(K);
  if (t === null) {
    let o = new Error(`<${r} /> is missing a parent <RadioGroup /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(o, oe), o;
  }
  return t;
}
function Ce(r, t) {
  return ke(t.type, Oe, r, t);
}
let De = "div";
function Ge(r, t) {
  let o = Y(), { id: d = `headlessui-radiogroup-${o}`, value: c, defaultValue: f, form: A, name: I, onChange: h, by: T = (e, n) => e === n, disabled: y = !1, ...W } = r, v = $(typeof T == "string" ? (e, n) => {
    let i = T;
    return (e == null ? void 0 : e[i]) === (n == null ? void 0 : n[i]);
  } : T), [w, O] = se(Ce, { options: [] }), l = w.options, [L, P] = X(), [b, C] = Q(), D = B(null), M = Z(D, t), [s, x] = ce(c, h, f), u = F(() => l.find((e) => !e.propsRef.current.disabled), [l]), R = F(() => l.some((e) => v(e.propsRef.current.value, s)), [l, s]), p = $((e) => {
    var n;
    if (y || v(e, s))
      return !1;
    let i = (n = l.find((g) => v(g.propsRef.current.value, e))) == null ? void 0 : n.propsRef.current;
    return i != null && i.disabled ? !1 : (x == null || x(e), !0);
  });
  Re({ container: D.current, accept(e) {
    return e.getAttribute("role") === "radio" ? NodeFilter.FILTER_REJECT : e.hasAttribute("role") ? NodeFilter.FILTER_SKIP : NodeFilter.FILTER_ACCEPT;
  }, walk(e) {
    e.setAttribute("role", "none");
  } });
  let _ = $((e) => {
    let n = D.current;
    if (!n)
      return;
    let i = Fe(n), g = l.filter((a) => a.propsRef.current.disabled === !1).map((a) => a.element.current);
    switch (e.key) {
      case k.Enter:
        ye(e.currentTarget);
        break;
      case k.ArrowLeft:
      case k.ArrowUp:
        if (e.preventDefault(), e.stopPropagation(), V(g, U.Previous | U.WrapAround) === J.Success) {
          let a = l.find((G) => G.element.current === (i == null ? void 0 : i.activeElement));
          a && p(a.propsRef.current.value);
        }
        break;
      case k.ArrowRight:
      case k.ArrowDown:
        if (e.preventDefault(), e.stopPropagation(), V(g, U.Next | U.WrapAround) === J.Success) {
          let a = l.find((G) => G.element.current === (i == null ? void 0 : i.activeElement));
          a && p(a.propsRef.current.value);
        }
        break;
      case k.Space:
        {
          e.preventDefault(), e.stopPropagation();
          let a = l.find((G) => G.element.current === (i == null ? void 0 : i.activeElement));
          a && p(a.propsRef.current.value);
        }
        break;
    }
  }), m = $((e) => (O({ type: 0, ...e }), () => O({ type: 1, id: e.id }))), S = F(() => ({ value: s, firstOption: u, containsCheckedOption: R, disabled: y, compare: v, ...w }), [s, u, R, y, v, w]), ae = F(() => ({ registerOption: m, change: p }), [m, p]), ie = { ref: M, id: d, role: "radiogroup", "aria-labelledby": L, "aria-describedby": b, onKeyDown: _ }, ne = F(() => ({ value: s }), [s]), N = B(null), le = me();
  return ue(() => {
    N.current && f !== void 0 && le.addEventListener(N.current, "reset", () => {
      p(f);
    });
  }, [N, p]), E.createElement(C, { name: "RadioGroup.Description" }, E.createElement(P, { name: "RadioGroup.Label" }, E.createElement(K.Provider, { value: ae }, E.createElement(H.Provider, { value: S }, I != null && s != null && he({ [I]: s }).map(([e, n], i) => E.createElement(ge, { features: Ee.Hidden, ref: i === 0 ? (g) => {
    var a;
    N.current = (a = g == null ? void 0 : g.closest("form")) != null ? a : null;
  } : void 0, ...Te({ key: e, as: "input", type: "radio", checked: n != null, hidden: !0, readOnly: !0, form: A, disabled: y, name: e, value: n }) })), re({ ourProps: ie, theirProps: W, slot: ne, defaultTag: De, name: "RadioGroup" })))));
}
var Ae = ((r) => (r[r.Empty = 1] = "Empty", r[r.Active = 2] = "Active", r))(Ae || {});
let Ie = "div";
function Le(r, t) {
  var o;
  let d = Y(), { id: c = `headlessui-radiogroup-option-${d}`, value: f, disabled: A = !1, ...I } = r, h = B(null), T = Z(h, t), [y, W] = X(), [v, w] = Q(), { addFlag: O, removeFlag: l, hasFlag: L } = fe(1), P = be({ value: f, disabled: A }), b = te("RadioGroup.Option"), C = oe("RadioGroup.Option");
  ve(() => C.registerOption({ id: c, element: h, propsRef: P }), [c, C, h, P]);
  let D = $((m) => {
    var S;
    if (j(m.currentTarget))
      return m.preventDefault();
    C.change(f) && (O(2), (S = h.current) == null || S.focus());
  }), M = $((m) => {
    if (j(m.currentTarget))
      return m.preventDefault();
    O(2);
  }), s = $(() => l(2)), x = ((o = b.firstOption) == null ? void 0 : o.id) === c, u = b.disabled || A, R = b.compare(b.value, f), p = { ref: T, id: c, role: "radio", "aria-checked": R ? "true" : "false", "aria-labelledby": y, "aria-describedby": v, "aria-disabled": u ? !0 : void 0, tabIndex: u ? -1 : R || !b.containsCheckedOption && x ? 0 : -1, onClick: u ? void 0 : D, onFocus: u ? void 0 : M, onBlur: u ? void 0 : s }, _ = F(() => ({ checked: R, disabled: u, active: L(2) }), [R, u, L]);
  return E.createElement(w, { name: "RadioGroup.Description" }, E.createElement(W, { name: "RadioGroup.Label" }, re({ ourProps: p, theirProps: I, slot: _, defaultTag: Ie, name: "RadioGroup.Option" })));
}
let Pe = ee(Ge), xe = ee(Le), tr = Object.assign(Pe, { Option: xe, Label: de, Description: pe });
export {
  tr as RadioGroup
};
