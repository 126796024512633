import { jsxs as n, jsx as t } from "react/jsx-runtime";
import { cn as u } from "./designsystem-ui-react46.js";
import { useState as x, useRef as m, useEffect as h } from "react";
function p({
  imageUrl: w = "/api/placeholder/1200/800",
  children: o,
  title: c,
  description: i,
  speed: r = 0.5,
  className: d
}) {
  const [a, s] = x(0), e = m(null);
  return h(() => {
    const l = () => {
      if (!e.current)
        return;
      s(window.scrollY);
      const f = -e.current.getBoundingClientRect().top;
      s(f * r);
    };
    return window.addEventListener("scroll", l), l(), () => {
      window.removeEventListener("scroll", l);
    };
  }, [r, a]), /* @__PURE__ */ n("div", { className: u("tw-relative tw-overflow-hidden tw-h-[500px] tw-w-full", d), ref: e, children: [
    /* @__PURE__ */ t(
      "div",
      {
        className: "tw-absolute tw-inset-0 tw-w-full tw-h-full",
        style: {
          transform: `translateY(${a}px)`
        },
        children: /* @__PURE__ */ t("img", { alt: "Parallax background", className: "tw-w-full tw-h-[120%] tw-object-cover", src: w })
      }
    ),
    /* @__PURE__ */ t("div", { className: "tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-transition-opacity tw-duration-300", children: /* @__PURE__ */ n("div", { className: "tw-relative tw-z-10 tw-text-white tw-px-4 tw-text-center", children: [
      /* @__PURE__ */ t("h2", { className: "tw-text-4xl tw-font-bold tw-mb-4 tw-text-base-0 tw-text-shadow-default", children: c }),
      /* @__PURE__ */ t("p", { className: "tw-text-xl tw-text-base-0 tw-text-shadow-default", children: i }),
      o
    ] }) })
  ] });
}
p.displayName = "Parallax";
export {
  p as Parallax
};
