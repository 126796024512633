var c = function(a, n, r) {
  var o = document.head || document.getElementsByTagName("head")[0], e = document.createElement("script");
  typeof n == "function" && (r = n, n = {}), n = n || {}, r = r || function() {
  }, e.type = n.type || "text/javascript", e.charset = n.charset || "utf8", e.async = "async" in n ? !!n.async : !0, e.src = a, n.attrs && l(e, n.attrs), n.text && (e.text = "" + n.text);
  var d = "onload" in e ? i : u;
  d(e, r), e.onload || i(e, r), o.appendChild(e);
};
function l(t, a) {
  for (var n in a)
    t.setAttribute(n, a[n]);
}
function i(t, a) {
  t.onload = function() {
    this.onerror = this.onload = null, a(null, t);
  }, t.onerror = function() {
    this.onerror = this.onload = null, a(new Error("Failed to load " + this.src), t);
  };
}
function u(t, a) {
  t.onreadystatechange = function() {
    this.readyState != "complete" && this.readyState != "loaded" || (this.onreadystatechange = null, a(null, t));
  };
}
export {
  c as l
};
