import { jsx as o } from "react/jsx-runtime";
import { cn as l } from "./designsystem-ui-react46.js";
const c = {
  row: "tw-flex-row",
  column: "tw-flex-col",
  "row-reverse": "tw-flex-row-reverse",
  "column-reverse": "tw-flex-col-reverse"
}, g = {
  start: "tw-items-start",
  center: "tw-items-center",
  end: "tw-items-end",
  between: "tw-items-between",
  around: "tw-items-around",
  evenly: "tw-items-evenly"
}, f = {
  start: "tw-justify-start",
  center: "tw-justify-center",
  end: "tw-justify-end",
  between: "tw-justify-between",
  around: "tw-justify-around",
  evenly: "tw-justify-evenly"
}, i = {
  nowrap: "tw-flex-nowrap",
  wrap: "tw-flex-wrap",
  "wrap-reverse": "tw-flex-wrap-reverse"
}, S = {
  0: "tw-gap-0",
  1: "tw-gap-1",
  2: "tw-gap-2",
  3: "tw-gap-3",
  4: "tw-gap-4",
  5: "tw-gap-5",
  6: "tw-gap-6",
  8: "tw-gap-8",
  10: "tw-gap-10",
  12: "tw-gap-12",
  16: "tw-gap-16",
  20: "tw-gap-20",
  24: "tw-gap-24",
  32: "tw-gap-32",
  40: "tw-gap-40",
  48: "tw-gap-48",
  56: "tw-gap-56",
  64: "tw-gap-64"
};
function m({
  children: t,
  direction: e = "row",
  align: w = "center",
  justify: r = "center",
  wrap: a = "nowrap",
  className: n = "",
  gap: s = 4,
  ...p
}) {
  return /* @__PURE__ */ o(
    "div",
    {
      className: l(
        "tw-flex",
        S[s],
        c[e],
        g[w],
        f[r],
        i[a],
        n
      ),
      ...p,
      children: t
    }
  );
}
m.displayName = "Flex";
export {
  m as Flex
};
