import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import { view } from "@risingstack/react-easy-state";
import { Alert } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { useEffect, useState } from "react";

import { ToastTheme, ToastType } from "../common/types/toastTypes";

import toastStore from "../stores/toastStore";

const toastThemes: Record<string, ToastTheme> = {
   success: {
      icon: CheckIcon,
      color: "white",
      background: "success"
   },
   error: {
      icon: ExclamationCircleIcon,
      color: "white",
      background: "danger"
   }
};

type SingleToastProps = {
   toast: ToastType;
};

const fadeDuration = 500;
const durationStyle = "tw-duration-500";

const SingleToast = view(({ toast }: SingleToastProps) => {
   const { delay: duration, id, message, show: showProp, title, type } = toast;
   const [show, setShow] = useState<boolean>(typeof showProp === "boolean" || true);
   const [finished, setFinished] = useState(false);

   const variant = type === "success" ? "success" : type === "error" ? "error" : "information";

   useEffect(() => {
      const timeoutId = setTimeout(() => {
         setShow(false);
      }, duration);

      return () => clearTimeout(timeoutId);
   }, [duration]);

   useEffect(() => {
      const timeoutId = setTimeout(() => {
         if (!show) {
            setFinished(true);
         }
      }, fadeDuration);
      return () => clearTimeout(timeoutId);
   }, [show]);

   if (finished) {
      return null;
   }

   const borderStyles = cn("tw-border", {
      "tw-border-error-600": variant === "error",
      "tw-border-success-600": variant === "success"
   });
   const alertStyles = cn(borderStyles, "tw-transition-opacity", durationStyle, {
      "tw-opacity-100": show,
      "tw-opacity-0": !show
   });
   return (
      <div className="tw-m-3 tw-w-96">
         <Alert variant={variant} title={title} className={alertStyles} onClose={() => setShow(false)}>
            {toast.message.split("\n").map((line, i) => (
               <p key={i}>{line}</p>
            ))}
         </Alert>
      </div>
   );
});

const ToastDisplay = () => (
   <div className="tw-fixed tw-bottom-0 tw-top-0 tw-z-40" data-testid="toast-display">
      {toastStore.toasts.map((toast) => (
         <SingleToast key={toast.id} toast={toast} />
      ))}
   </div>
);

export default view(ToastDisplay);
