import { jsx as o, jsxs as w } from "react/jsx-runtime";
import { isValidElement as a, cloneElement as l, Children as s } from "react";
import { RadioGroup as n } from "./designsystem-ui-react74.js";
function u(e) {
  return e.type === n.Label || e.type === n.Description;
}
function m({ children: e, value: p, icon: r }) {
  return /* @__PURE__ */ o(
    n.Option,
    {
      className: ({ active: i, checked: t }) => `${i ? "tw-ring-2 tw-ring-offset-2 tw-ring-offset-button-primary-bg" : ""}
        ${t ? "tw-bg-button-primary-bg tw-text-button-primary-text hover:tw-bg-button-primary-bg-hover" : "tw-bg-chip-default hover:tw-bg-chip-default-hover tw-text-ink-brand-default"}
          tw-relative tw-flex tw-cursor-pointer tw-rounded-full tw-p-2 tw-focus:outline-none`,
      value: p,
      children: ({ checked: i }) => /* @__PURE__ */ o("div", { className: "tw-flex tw-w-full tw-items-center tw-justify-between", children: /* @__PURE__ */ w("div", { className: "tw-flex tw-items-center tw-gap-x-2", children: [
        r && a(r) ? l(r, {
          style: {
            width: "var(--spacing-4)",
            height: "var(--spacing-4)"
          }
        }) : null,
        s.map(
          e,
          (t) => a(t) && u(t) ? l(t, { checked: i }) : t
        )
      ] }) })
    }
  );
}
m.displayName = "ToggleGroupItem";
export {
  m as ToggleGroupItem
};
