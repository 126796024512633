import { view } from "@risingstack/react-easy-state";
import { IconClose } from "@tine/designsystem-icons/sharp";
import { Search } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import debounce from "lodash-es/debounce";
import { ChangeEventHandler, useEffect, useRef } from "react";
import { useLocation } from "wouter";

import { SupportedLocaleType } from "../../common/types/languageTypes";
import { SEARCH_TYPE } from "../../common/types/searchTypes";
import useMediaQuery from "../../common/useMediaQuery";
import useOutsideClick from "../../common/useOutsideClick";
import { focusableElementCssSelector, mediaQueries } from "../../common/utils";
import { getMaxHitsForSearchDomain } from "../../common/utils/searchUtils";
import useSupportedLocale from "../../common/utils/useSupportedLocale";
import MainColumn from "../common/MainColumn";
import Popover from "../common/popover/Popover";

import searchStore from "../../stores/searchStore";

import theme from "../../themes/theme";
import Button from "../Button";
import HitsForCmsContent from "./HitsForCmsContent";
import HitsForProduct from "./HitsForProduct";

const hide = () => searchStore.clear(SEARCH_TYPE.QuickSearch, true);

const performQuickSearch = debounce((locale: SupportedLocaleType) => {
   searchStore.performSearches(SEARCH_TYPE.QuickSearch, locale);
}, 85);
const searchDomains = theme.searchConfig.searchDomains;

const SearchField = () => {
   const [location, redirectTo] = useLocation();
   const quickSearchRef = useRef<HTMLDivElement>(null);
   const searchFieldRef = useRef<HTMLInputElement>(null);
   const locale = useSupportedLocale();
   const isXl = useMediaQuery(mediaQueries.xl);
   const isLg = useMediaQuery(mediaQueries.lg);

   const results = searchStore[SEARCH_TYPE.QuickSearch].results;

   const totalHits: number = results.reduce((total, result) => total + result.hits.length, 0);

   const hasHits: boolean = !!totalHits;

   const resultsWithHits = results.filter((result) => result.hits.length);

   const hasHitsForMultipleNonProductDomains =
      resultsWithHits.length > 2 ||
      (resultsWithHits.length === 2 && !resultsWithHits.find((result) => result.searchDomainType === "product"));

   const hiddenStyle = "tw-hidden";
   const resultsCN = cn({ hiddenStyle: !hasHits });

   const nonProdDomainCN = cn("tw-m-4 md:tw-flex-1");

   const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
      const { relatedTarget } = e;
      const quickSearchElement = quickSearchRef.current;
      const searchFieldElement = searchFieldRef.current;
      if (quickSearchElement && searchFieldElement && relatedTarget) {
         if (!quickSearchElement.contains(relatedTarget)) {
            searchFieldRef.current.focus();
         }
      }
   };

   const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code === "Tab" && !e.shiftKey) {
         const quickSearchElement = quickSearchRef.current;
         if (quickSearchElement && !quickSearchElement.classList.contains(hiddenStyle)) {
            {
               const firstLink = quickSearchElement.querySelector(focusableElementCssSelector) as HTMLElement;
               if (firstLink) {
                  e.preventDefault();
                  firstLink.focus();
               }
            }
         }
      }

      if (e.key === "Enter" && searchStore.query != "") {
         redirectTo("/search?q=" + searchStore.query);
         hide();
      }
   };

   const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      searchStore.query = e.target.value;
      performQuickSearch(locale);
   };
   // Clear the search field if you navigate to a new page, except for the search result page
   useEffect(() => {
      if (location !== "/search") {
         hide();
      }
   }, [location]);

   useOutsideClick([searchFieldRef, quickSearchRef], () => {
      hide();
   });

   return (
      <>
         <div ref={searchFieldRef}>
            <Search
               onChange={handleChange}
               placeholder="Søk i hele butikken"
               label=""
               isLabelHidden={true}
               value={searchStore.query}
               autoComplete="off"
               onKeyDown={handleKeyDown}
            />
         </div>
         <Popover
            onHide={() => hide()}
            referenceElement={searchFieldRef.current}
            show={hasHits}
            placement="bottom"
            container={MainColumn}
            className="tw-w-full"
         >
            <>
               <div className="tw-flex tw-flex-row tw-justify-end">
                  <div>
                     <Button variant="tertiary" icon={<IconClose />} onClick={() => hide()} trackingName="Lukk quicksearch" />
                  </div>
               </div>
               <div id="quick-search-results" ref={quickSearchRef} onBlur={handleBlur} className={resultsCN}>
                  <>
                     <div className="tw-flex tw-flex-row tw-flex-wrap">
                        {resultsWithHits.map((results) => {
                           const searchDomain = searchDomains.find(
                              (searchDomain) => searchDomain.type === results.searchDomainType
                           );
                           if (searchDomain) {
                              if (results.searchDomainType === "product") {
                                 return (
                                    <div key={searchDomain.name} className="tw-m-4 tw-basis-full">
                                       <HitsForProduct
                                          searchDomain={searchDomain}
                                          maxHits={getMaxHitsForSearchDomain(
                                             results.searchDomainType,
                                             resultsWithHits,
                                             isLg,
                                             isXl
                                          )}
                                       />
                                    </div>
                                 );
                              }
                              return (
                                 <div className={nonProdDomainCN} key={searchDomain.name}>
                                    <HitsForCmsContent
                                       searchDomain={searchDomain}
                                       maxHits={getMaxHitsForSearchDomain(results.searchDomainType, resultsWithHits, isLg, isXl)}
                                       isNarrow={hasHitsForMultipleNonProductDomains}
                                    />
                                 </div>
                              );
                           }
                        })}
                     </div>
                  </>
               </div>
            </>
         </Popover>
      </>
   );
};

export default view(SearchField);
