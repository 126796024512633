import { jsxs as a, jsx as e } from "react/jsx-runtime";
import { cn as i } from "./designsystem-ui-react46.js";
import { useId as y } from "react";
import { FormError as N } from "./designsystem-ui-react15.js";
import { FormDetails as j } from "./designsystem-ui-react14.js";
function I({
  className: w,
  isDisabled: o,
  placeholder: l,
  isLabelHidden: f,
  value: t,
  label: r,
  description: b,
  maxLength: n,
  showMaxLength: c,
  errorMessage: m = "",
  showErrorMessage: d = !1,
  tooltipPosition: u = "right",
  tooltipText: x = "",
  rows: p = 4,
  onChange: g,
  ...k
}) {
  const v = t == null ? void 0 : t.toString().length, h = y(), s = typeof r == "string" ? r : h;
  return /* @__PURE__ */ a("div", { className: i("tw-flex tw-flex-col tw-gap-2 tw-full", w), children: [
    /* @__PURE__ */ e(
      j,
      {
        description: b,
        id: s,
        isLabelHidden: f,
        label: r,
        tooltipPosition: u,
        tooltipText: x
      }
    ),
    /* @__PURE__ */ e(
      "textarea",
      {
        className: i(
          // basic layout
          "tw-block tw-w-full tw-transition-color tw-rounded tw-text-base tw-resize-y tw-p-2 tw-m-0 tw-text-ink-brand-default tw-font-sans placeholder:tw-text-ink-disabled",
          // border
          "tw-border tw-border-ink-disabled hover:tw-border-ink-brand-default focus-visible:tw-ring-offset-1 focus-visible:tw-ring-offset-ink-brand-default",
          // focus
          "focus-visible:tw-border-ink-brand-default focus-visible:tw-ring-2 focus-visible:tw-ring-information-600 focus-visible:tw-outline-none",
          // Invalid state
          "data-[invalid]:tw-border-error-600 data-[invalid]:data-[hover]:tw-border-error-600",
          // Disabled state
          o && "!tw-bg-base-50 !tw-cursor-not-allowed !tw-text-ink-disabled",
          // Error state
          d && "!tw-border-error-600"
        ),
        disabled: o,
        id: s,
        maxLength: n,
        onChange: g,
        placeholder: l,
        rows: p,
        value: t,
        ...k
      }
    ),
    c ? /* @__PURE__ */ a("div", { className: i("tw-flex tw-justify-end tw-text-xs tw-text-ink-brand-default"), children: [
      v,
      "/",
      n
    ] }) : null,
    /* @__PURE__ */ e(N, { errorMessage: m, showErrorMessage: d })
  ] });
}
I.displayName = "TextArea";
export {
  I as TextArea
};
