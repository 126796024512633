import includes from "lodash-es/includes";
import isArray from "lodash-es/isArray";
// @ts-ignore - needed because TypeScript does not support loading SVGs as images
import desktopLogoUrl from "url:/src/img/themes/isdalen/logo.jpg";
// @ts-ignore - needed because TypeScript does not support loading SVGs as images
import mobileLogoUrl from "url:/src/img/themes/isdalen/logo.jpg";

import { CustomerFeature, FEATURE_NAME } from "../common/types/featureTypes";
import { Invoice } from "../common/types/invoiceTypes";
import { SupportedLocaleType } from "../common/types/languageTypes";
import { Product } from "../common/types/productTypes";
import { SiteConfig } from "../common/types/themeTypes";

const CONTENTFUL_ENV = process.env.CONTENTFUL_ENV ?? "";
const ALGOLIA_APPID = "CV5266SV9V";
const ALGOLIA_SEARCHKEY = "6e0ed776d4e102db935498659894446c";
const algoliaEnvironment = process.env.ENV_NAME === "prod" ? "prod" : "preprod";

type DataMapping = [Partial<Product>, Partial<Product>];

type PartialMatch<T> = Partial<T>;

function doesProductMatch<T>(product: T, matches: PartialMatch<T>): boolean {
   return Object.entries(matches).every(([key, value]) => product[key as keyof T] === value);
}

function convertSizeMappingsToMappings(sizeMappings: Record<string, string>): DataMapping[] {
   return Object.entries(sizeMappings).map(([sourceSize, targetSize]) => [{ size: sourceSize }, { size: targetSize }]);
}

const enhanceIsdalenProduct = (product: Product): Product => {
   // Direct mappings from size to size simplified for readability
   const sizeMappings: Record<string, string> = {
      "8 X 473ml": "Pint",
      "6x2l": "2 liter",
      "5l": "5 liter",
      "Essens 5l": "5 liter",
      "Slush Essens": "5 liter",
      "5L": "5 liter",
      "0,5l": "0,5 liter",
      "1l X 6": "1 liter",
      "500stk": "500 stk",
      "1l": "1 liter",
      "4L": "4 liter",
      "Dpak 6stk": "0,5 liter"
   };

   // Cross field mappings like size -> type
   const mappings: DataMapping[] = [
      ...convertSizeMappingsToMappings(sizeMappings),
      [{ size: "20 X 76g" }, { type: "Pinne" }],
      [{ size: "6 X (4  51g)" }, { type: "Multipack", size: undefined }],
      [{ size: "208g X 9" }, { type: "Multipack", size: undefined }],
      [{ size: "20 X 76g" }, { type: "Pinne", size: undefined }],
      [{ type: "Saft, sirup og leskedrikker (inkl. pulver)" }, { type: "Essens" }],
      [{ type: "Kjøkkenartikler" }, { type: "Tilbehør" }],
      [{ size: "Mango" }, { type: "Sorbet", size: undefined }],
      [{ size: "5L" }, { type: "Vegansk", size: "5 liter" }]
   ];

   mappings.forEach((mapping) => {
      if (doesProductMatch(product, mapping[0])) {
         product = { ...product, ...mapping[1] };
      }
   });

   // Additional mappings based on product name or external category reference
   if (product.category?.externalReference === "I0041" || product.category?.externalReference === "I0061") {
      product.type = "Tilbehør";
   }

   if (product.category?.externalReference === "I0140") {
      product.type = "Frukt";
   }

   if (product.size === null && includes(product.name, "5L")) {
      product.size = "5 liter";
   }

   if ((product.type === null && includes(product.name, "Eskimo")) || includes(product.name, "Shake")) {
      product.type = "Iskrem";
   }

   // Product data is published with a period as decimal, this in not correct in Norwegian.
   if (isArray(product.nutritions)) {
      product.nutritions.forEach((nutrition) => {
         nutrition.value = nutrition.value.replace(/\./g, ",");
      });
   }

   return product;
};

const isdalenSiteConfig: SiteConfig = {
   storeId: "isdalen",
   storeCompanyName: "Isdalen",
   orderSupportEmail: "post@isdalen.no",
   baseUrl: "",
   siteName: "Isdalen Handel",
   m3CompanyNumber: "500",
   m3DivisionNumber: "500",
   desktopLogoUrl: desktopLogoUrl.toString(),
   mobileLogoUrl: mobileLogoUrl.toString(),
   productDropdownEnabled: false,
   showComparisonPrice: false,
   logoRatio: 0.24,
   faqSlug: "/kundeservice/ofte-stilte-sporsmal",
   filterAttributes: () => ({
      type: "Type",
      size: "Størrelse"
   }),
   papirflyApsCustomerId: "12590",
   papirflyApsImagePrefix: "isd-",
   enhanceProduct: enhanceIsdalenProduct,
   filterRefreshOnChange: true,
   tipApiPrefix: "is-",
   fetchCustomerFeatures: async (customerNumber: string) => {
      return Promise.resolve([
         { name: FEATURE_NAME.subscriptionOrderAvailable, description: "Makes subscriptions available" },
         { name: FEATURE_NAME.invoicePaymentAvailable, description: "Makes invoice overview available" },
         { name: FEATURE_NAME.createOrderAvailable, description: "Allows customer to place orders" }
      ] satisfies CustomerFeature[]);
   },
   invoiceTooltipText: (invoice: Invoice) => "",
   deliveryFee: {
      enabled: false
   },
   productAvailability: {
      enabled: true
   },
   newCustomerForm: "https://xml.isdalenhandel.no/bli-kunde",
   deliveryNotes: {
      visible: true
   },
   searchConfig: {
      algoliaConfig: {
         appId: ALGOLIA_APPID,
         searchApiKey: ALGOLIA_SEARCHKEY
      },
      searchDomains: [
         {
            type: "product",
            name: "produkter",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Products" : "Produkter"),
            homePath: (_locale) => "/produkter.html",
            indexName: `products_${algoliaEnvironment}`,
            linkText: (_locale) => "Se alle produkter"
         },
         {
            type: "v2_article",
            name: "inspirasjon",
            displayName: (locale: SupportedLocaleType) => "Nyheter",
            homePath: () => "/nyheter",
            indexName: `contentful_${CONTENTFUL_ENV}`,
            linkText: () => "Se alle nyheter"
         }
      ]
   },
   contentImages: {
      cloudinaryMapping: "isdalen_images"
   },
   contactForm: {
      apiPath: "/api/contactform",
      reasons: [
         { value: "delivery", label: "Spørsmål angående levering", label_en: "Questions about ordering" },
         { value: "invoice", label: "Endring av fakturaadresse", label_en: "Invoice address changes" },
         { value: "invoice_credit", label: "Spørsmål angående faktura", label_en: "Invoice inquiries" },
         { value: "other", label: "Annet", label_en: "Other" },
         { value: "product_order", label: "Spørsmål angående bestillling", label_en: "Questions about ordering" },
         { value: "product_question", label: "Spørsmål angående produkter", label_en: "Question about products" },
         {
            value: "refrigeration_equipment",
            label: "Spørsmål angående kjølemøbler",
            label_en: "Questions about refrigeration equipment"
         }
      ]
   },
   whileYouWaitMessage: "Lag deg en cola-slush og nyt et par sekunders velfortjent pause!",
   notifications: {
      categories: [
         { id: "ORDER_CONFIRMATION", name: "Ordrebekreftelse" },
         { id: "DELIVERY_CONFIRMATION", name: "Leveringsbekreftelse" },
         { id: "IMPORTANT_INFO_ABOUT_DELIVERY", name: "Viktig melding om levering" },
         { id: "ORDER_DEADLINE", name: "Påminnelse om ordrefrist" }
      ],
      enableDeliveryMessages: false
   },
   cart: {
      customerReferenceLabel: "Din betalingsreferanse"
   }
};

export default isdalenSiteConfig;
