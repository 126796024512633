import { jsx as o } from "react/jsx-runtime";
import { cn as g } from "./designsystem-ui-react46.js";
const i = {
  start: "tw-items-start",
  center: "tw-items-center",
  end: "tw-items-end",
  between: "tw-items-between",
  around: "tw-items-around",
  evenly: "tw-items-evenly"
}, f = {
  start: "tw-justify-start",
  center: "tw-justify-center",
  end: "tw-justify-end",
  between: "tw-justify-between",
  around: "tw-justify-around",
  evenly: "tw-justify-evenly"
}, u = {
  nowrap: "tw-flex-nowrap",
  wrap: "tw-flex-wrap",
  "wrap-reverse": "tw-flex-wrap-reverse"
}, c = {
  0: "tw-gap-0",
  1: "tw-gap-1",
  2: "tw-gap-2",
  3: "tw-gap-3",
  4: "tw-gap-4",
  5: "tw-gap-5",
  6: "tw-gap-6",
  8: "tw-gap-8",
  10: "tw-gap-10",
  12: "tw-gap-12",
  16: "tw-gap-16",
  20: "tw-gap-20",
  24: "tw-gap-24",
  32: "tw-gap-32",
  40: "tw-gap-40",
  48: "tw-gap-48",
  56: "tw-gap-56",
  64: "tw-gap-64"
};
function S({
  children: t,
  align: e = "center",
  gap: w = 4,
  grow: a = !1,
  justify: r = "center",
  wrap: n = "nowrap",
  className: p = "",
  ...s
}) {
  return /* @__PURE__ */ o(
    "div",
    {
      className: g(
        "tw-flex tw-flex-row",
        a ? "[&>*]:tw-grow tw-grow" : "",
        i[e],
        f[r],
        u[n],
        c[w],
        p
      ),
      ...s,
      children: t
    }
  );
}
S.displayName = "Group";
export {
  S as Group
};
