import { forwardRef as O, Fragment as y, isValidElement as w, cloneElement as R, createElement as E, useRef as F, useCallback as k } from "react";
import { classNames as b } from "./designsystem-ui-react135.js";
import { match as S } from "./designsystem-ui-react105.js";
var A = ((e) => (e[e.None = 0] = "None", e[e.RenderStrategy = 1] = "RenderStrategy", e[e.Static = 2] = "Static", e))(A || {}), P = ((e) => (e[e.Unmount = 0] = "Unmount", e[e.Hidden = 1] = "Hidden", e))(P || {});
function C({ ourProps: e, theirProps: n, slot: t, defaultTag: r, features: a, visible: f = !0, name: i, mergeRefs: l }) {
  l = l ?? T;
  let s = j(n, e);
  if (f)
    return g(s, t, r, i, l);
  let m = a ?? 0;
  if (m & 2) {
    let { static: o = !1, ...d } = s;
    if (o)
      return g(d, t, r, i, l);
  }
  if (m & 1) {
    let { unmount: o = !0, ...d } = s;
    return S(o ? 0 : 1, { 0() {
      return null;
    }, 1() {
      return g({ ...d, hidden: !0, style: { display: "none" } }, t, r, i, l);
    } });
  }
  return g(s, t, r, i, l);
}
function g(e, n = {}, t, r, a) {
  let { as: f = t, children: i, refName: l = "ref", ...s } = v(e, ["unmount", "static"]), m = e.ref !== void 0 ? { [l]: e.ref } : {}, o = typeof i == "function" ? i(n) : i;
  "className" in s && s.className && typeof s.className == "function" && (s.className = s.className(n));
  let d = {};
  if (n) {
    let u = !1, p = [];
    for (let [h, c] of Object.entries(n))
      typeof c == "boolean" && (u = !0), c === !0 && p.push(h);
    u && (d["data-headlessui-state"] = p.join(" "));
  }
  if (f === y && Object.keys(N(s)).length > 0) {
    if (!w(o) || Array.isArray(o) && o.length > 1)
      throw new Error(['Passing props on "Fragment"!', "", `The current component <${r} /> is rendering a "Fragment".`, "However we need to passthrough the following props:", Object.keys(s).map((c) => `  - ${c}`).join(`
`), "", "You can apply a few solutions:", ['Add an `as="..."` prop, to ensure that we render an actual element instead of a "Fragment".', "Render a single element as the child so that we can forward the props onto that element."].map((c) => `  - ${c}`).join(`
`)].join(`
`));
    let u = o.props, p = typeof (u == null ? void 0 : u.className) == "function" ? (...c) => b(u == null ? void 0 : u.className(...c), s.className) : b(u == null ? void 0 : u.className, s.className), h = p ? { className: p } : {};
    return R(o, Object.assign({}, j(o.props, N(v(s, ["ref"]))), d, m, { ref: a(o.ref, m.ref) }, h));
  }
  return E(f, Object.assign({}, v(s, ["ref"]), f !== y && m, f !== y && d), o);
}
function W() {
  let e = F([]), n = k((t) => {
    for (let r of e.current)
      r != null && (typeof r == "function" ? r(t) : r.current = t);
  }, []);
  return (...t) => {
    if (!t.every((r) => r == null))
      return e.current = t, n;
  };
}
function T(...e) {
  return e.every((n) => n == null) ? void 0 : (n) => {
    for (let t of e)
      t != null && (typeof t == "function" ? t(n) : t.current = n);
  };
}
function j(...e) {
  if (e.length === 0)
    return {};
  if (e.length === 1)
    return e[0];
  let n = {}, t = {};
  for (let r of e)
    for (let a in r)
      a.startsWith("on") && typeof r[a] == "function" ? (t[a] != null || (t[a] = []), t[a].push(r[a])) : n[a] = r[a];
  if (n.disabled || n["aria-disabled"])
    return Object.assign(n, Object.fromEntries(Object.keys(t).map((r) => [r, void 0])));
  for (let r in t)
    Object.assign(n, { [r](a, ...f) {
      let i = t[r];
      for (let l of i) {
        if ((a instanceof Event || (a == null ? void 0 : a.nativeEvent) instanceof Event) && a.defaultPrevented)
          return;
        l(a, ...f);
      }
    } });
  return n;
}
function I(e) {
  var n;
  return Object.assign(O(e), { displayName: (n = e.displayName) != null ? n : e.name });
}
function N(e) {
  let n = Object.assign({}, e);
  for (let t in n)
    n[t] === void 0 && delete n[t];
  return n;
}
function v(e, n = []) {
  let t = Object.assign({}, e);
  for (let r of n)
    r in t && delete t[r];
  return t;
}
export {
  A as Features,
  P as RenderStrategy,
  N as compact,
  I as forwardRefWithAs,
  C as render,
  W as useMergeRefsFn
};
