import { IconSpinner } from "@tine/designsystem-icons/sharp";
import { Button, ButtonProps } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { formatISO } from "date-fns";
import { KeyboardEvent as ReactKeyboardEvent, useMemo, useRef } from "react";

import { DayFlags } from "../../../common/types/calendarTypes";
import { getKeyboardFocusableElements } from "../../../common/utils";

type DayProps = ButtonProps &
   DayFlags & {
      date: Date;
      isArrowFocus?: boolean;
      onDayClicked: (day: Date, element: HTMLElement) => void;
   };

type OptionalButtonProps = {
   tabIndex?: number;
   onKeyDown?: (e: ReactKeyboardEvent<HTMLElement>) => void;
   "data-focused"?: boolean;
};

export const buttonClassName = ({ notInMonth, disabled, holiday, cancelled, paused, loading, inRange, focused }: DayFlags) =>
   cn("tw-m-px", {
      "hover:tw-text-ink-brand-default tw-text-ink-brand-subtle": notInMonth,
      "!tw-border-transparent !tw-bg-transparent": disabled,
      "!tw-bg-calendar-holiday-bg !tw-text-calendar-holiday-text": holiday,
      "!tw-border-calendar-cancelled-bg !tw-bg-calendar-cancelled-bg !tw-text-calendar-cancelled-text": cancelled,
      "!tw-bg-calendar-paused-bg !tw-text-calendar-paused-text hover:!tw-border-calendar-paused-bg-hover hover:!tw-bg-calendar-paused-bg-hover":
         paused,
      "!tw-border-button-primary-bg !tw-bg-button-primary-bg !tw-text-button-primary-text": !paused && loading,
      "[&>svg]:!tw-fill-base-0": loading,
      "!tw-bg-button-tertiary-bg-hover": inRange,
      "tw-outline tw-outline-2 tw-outline-ink-focus": focused && !notInMonth
   });

const Day = ({
   date,
   className = "",
   size,
   isArrowFocus = false,
   onDayClicked,
   selected = false,
   selectable = true,
   secondary = false,
   holiday = false,
   cancelled = false,
   disabled = false,
   notInMonth = false,
   loading = false,
   paused = false,
   inRange = false,
   focused = false,
   ...props
}: DayProps) => {
   const ref = useRef<HTMLButtonElement>(null);
   const dayFlags: Required<DayFlags> = {
      selectable,
      secondary,
      holiday,
      cancelled,
      disabled,
      notInMonth,
      loading,
      inRange,
      selected,
      paused,
      focused
   };

   const dayOfMonth = useMemo(() => date.getDate(), [date]);

   const buttonCN = cn(className, buttonClassName(dayFlags));

   const variant = !notInMonth && selected ? "primary" : !notInMonth && secondary ? "secondary" : "tertiary";

   const handleClick = () => {
      if (selectable && ref.current && ref.current instanceof HTMLElement) {
         onDayClicked(date, ref.current);
      }
   };

   const handleKeyDown = (e: ReactKeyboardEvent<HTMLElement>) => {
      const { key, shiftKey } = e;
      if (key === "Tab" && shiftKey) {
         e.preventDefault();
         const monthsElement = e.currentTarget.closest('div[tabIndex="0"]');
         if (monthsElement) {
            const focusableElements = getKeyboardFocusableElements();
            const monthsIndex = focusableElements.findIndex((focusable) => focusable === monthsElement);
            if (monthsIndex) {
               (focusableElements[monthsIndex - 1] as HTMLElement).focus();
            }
         }
      }
   };

   const optionalProps: OptionalButtonProps = isArrowFocus ? { tabIndex: -1, onKeyDown: handleKeyDown } : {};

   if (focused && !notInMonth) {
      optionalProps["data-focused"] = true;
   }

   return (
      <Button
         ref={ref}
         data-testid={disabled ? undefined : "day-button"}
         variant={variant}
         size={size}
         disabled={disabled || cancelled}
         data-date={formatISO(date, { representation: "date" })}
         {...props}
         {...optionalProps}
         className={buttonCN}
         onClick={handleClick}
      >
         {!loading && dayOfMonth}
         {!!loading && <IconSpinner className="tw-animate-spin" />}
      </Button>
   );
};

export default Day;
