import { jsxs as o, jsx as i, Fragment as g } from "react/jsx-runtime";
import { useMemo as u } from "react";
import { generateUniqueClassName as C, generateGridColstyles as $, generateGridGapStyles as f, cn as l } from "./designsystem-ui-react46.js";
const c = {
  media: "",
  container: "tw-@container"
};
function p({
  cols: m = 3,
  gap: d = 4,
  type: e = "media",
  className: n,
  children: s,
  ...S
}) {
  const r = u(C, []), a = $(m, e), t = f(d, e);
  return e === "container" ? /* @__PURE__ */ o("div", { className: "tw-@container", children: [
    /* @__PURE__ */ i("div", { className: l("tw-grid", r, c[e], n), children: s }),
    /* @__PURE__ */ i("style", { children: `.${r} { ${a} ${t} }` })
  ] }) : /* @__PURE__ */ o(g, { children: [
    /* @__PURE__ */ i("div", { className: l("tw-grid", r, c[e], n), ...S, children: s }),
    /* @__PURE__ */ i("style", { children: `.${r} { ${a} ${t} }` })
  ] });
}
p.displayName = "GridSimple";
export {
  p as GridSimple
};
