import { jsx as e, jsxs as u } from "react/jsx-runtime";
import { cn as r } from "./designsystem-ui-react46.js";
import { Disclosure as l } from "./designsystem-ui-react47.js";
import { Transition as m } from "./designsystem-ui-react48.js";
import h from "./designsystem-ui-react49.js";
const o = (t, w, a = !1) => typeof t > "u" ? "" : typeof t == "string" && a ? t : typeof t == "object" ? t[w] ?? "" : "";
function x({
  label: t,
  children: w,
  variant: a = "regular",
  borderVariant: f = "separator",
  borderColorClass: s = "tw-border-ink-brand-subtle",
  defaultOpen: c,
  iconPosition: i = "left",
  className: n
}) {
  const p = {
    regular: "tw-px-8 tw-py-5",
    compact: "tw-p-3"
  }[a], y = {
    regular: "tw-px-8 tw-py-4",
    compact: "tw-p-3"
  }[a], g = {
    none: "",
    separator: r(
      "tw-border-x-0",
      "odd:tw-border-b-0 even:tw-border-b-0",
      "last-of-type:tw-border-b last-of-type:tw-border-b-ink-brand-subtle",
      "tw-border-t tw-border-t-ink-brand-subtle",
      s
    ),
    bordered: r("tw-border-2 tw-rounded-lg tw-overflow-hidden", s)
  }[f];
  return /* @__PURE__ */ e(l, { defaultOpen: c, children: ({ open: d }) => /* @__PURE__ */ u(
    "div",
    {
      className: r(
        "tw-max-w-[800px]",
        // Border
        g,
        // Focus
        "[&:has(:focus-visible)]:tw-ring-2 tw-ring-information-600",
        o(n, "container", !0)
      ),
      children: [
        /* @__PURE__ */ e(
          l.Button,
          {
            className: r(
              // Base
              "tw-group tw-flex tw-w-full tw-transition-all tw-justify-between tw-text-left tw-cursor-pointer",
              p,
              // Border
              "tw-border-none",
              // Colors
              "tw-text-base-900 hover:tw-bg-surface-default-hover",
              d ? "tw-bg-surface-default-hover" : "tw-bg-base-0",
              // Focus
              "focus:tw-outline-none",
              o(n, "button")
            ),
            children: /* @__PURE__ */ u(
              "div",
              {
                className: r(
                  "tw-flex tw-items-center tw-gap-x-4 tw-w-full",
                  i === "right" && "tw-justify-between"
                ),
                children: [
                  i === "left" && /* @__PURE__ */ e(b, { className: o(n, "icon"), open: d }),
                  /* @__PURE__ */ e(
                    "span",
                    {
                      className: r("tw-text-ink-brand-default tw-text-sm tw-font-semibold", o(n, "label")),
                      children: t
                    }
                  ),
                  i === "right" && /* @__PURE__ */ e(b, { className: o(n, "icon"), open: d })
                ]
              }
            )
          }
        ),
        /* @__PURE__ */ e("div", { className: "tw-overflow-hidden", children: /* @__PURE__ */ e(
          m,
          {
            enter: "tw-duration-200 tw-ease-out",
            enterFrom: "tw-opacity-0 -tw-translate-y-4",
            enterTo: "tw-opacity-100 tw-translate-y-0",
            leave: "tw-duration-200 tw-ease-out",
            leaveFrom: "tw-opacity-200 tw-translate-y-0",
            leaveTo: "tw-opacity-0 -tw-translate-y-4",
            children: /* @__PURE__ */ e(
              l.Panel,
              {
                className: r(
                  "tw-text-sm tw-text-ink-brand-default tw-bg-base-0 tw-origin-top tw-transition tw-z-0",
                  y,
                  o(n, "panel")
                ),
                children: w
              }
            )
          }
        ) })
      ]
    }
  ) });
}
x.displayName = "Accordion";
function b({ open: t, className: w }) {
  return /* @__PURE__ */ e(
    h,
    {
      className: r(
        "tw-fill-ink-brand-default tw-w-6 tw-h-6 tw-transition-transform tw-rounded-button group-hover:tw-bg-button-tertiary-bg-hover tw-flex-shrink-0",
        t && "tw-rotate-180",
        w
      )
    }
  );
}
export {
  x as Accordion
};
