import { jsx as t, jsxs as w } from "react/jsx-runtime";
import { cn as e } from "./designsystem-ui-react46.js";
import { Tag as h } from "./designsystem-ui-react39.js";
import { Link as y } from "./designsystem-ui-react21.js";
function N({
  title: l,
  description: n,
  image: r,
  imageAlt: d,
  backgroundColor: a = "transparent",
  isImageFlipped: c = !1,
  href: b,
  tags: o,
  ctaLabel: i,
  onCtaClick: u,
  className: p,
  linkComponent: g = "a",
  buttonVariant: m = "secondary"
}) {
  const f = a !== "transparent", x = {
    white: "tw-bg-surface-default",
    transparent: "tw-bg-base-transparent"
  }[a], v = {
    primary: e(
      // Colors
      "tw-text-button-primary-text tw-bg-button-primary-bg tw-fill-button-primary-text",
      // Border
      "tw-border-button-primary-bg",
      // Hover
      "hover:tw-bg-button-primary-bg-hover hover:tw-border-button-primary-bg-hover"
    ),
    secondary: e(
      // Colors
      "tw-text-button-secondary-text tw-bg-button-secondary-bg tw-fill-button-secondary-text",
      // Border
      "tw-border-button-secondary-border",
      // Hover
      "hover:tw-bg-button-secondary-bg-hover hover:tw-border-button-secondary-border"
    )
  }[m];
  return /* @__PURE__ */ t("article", { className: e("tw-rounded-lg tw-overflow-clip tw-@container tw-text-link-default", x, p), children: /* @__PURE__ */ w("div", { className: e("tw-grid tw-grid-cols-12 tw-gap-5 tw-size-full tw-ring "), children: [
    /* @__PURE__ */ t(
      "div",
      {
        className: e(
          "tw-col-span-full tw-aspect-video @xl:tw-col-span-6 tw-size-full tw-relative tw-bg-base-50",
          c ? "@xl:tw-order-1" : "tw-order-0",
          !f && "tw-rounded-lg tw-overflow-clip"
        ),
        children: typeof r == "string" ? /* @__PURE__ */ t("img", { alt: d, className: "tw-size-full tw-inset-0 tw-absolute tw-object-cover", src: r }) : /* @__PURE__ */ t("div", { className: "tw-w-full tw-h-full", children: r })
      }
    ),
    /* @__PURE__ */ t("div", { className: "tw-flex tw-col-span-full @xl:tw-col-span-6 tw-flex-col tw-gap-4 tw-items-start tw-justify-center", children: /* @__PURE__ */ w("div", { className: "tw-p-4 @2xl:tw-p-10 tw-space-y-8", children: [
      l ? /* @__PURE__ */ t("h2", { className: "tw-text-2xl tw-m-0 tw-line-clamp-2 @xl:tw-line-clamp-3", children: /* @__PURE__ */ t("strong", { children: l }) }) : null,
      o ? /* @__PURE__ */ t("div", { className: "tw-flex tw-gap-x-2", children: o.map((s) => /* @__PURE__ */ t(h, { variant: "base", children: s }, s)) }) : null,
      n ? /* @__PURE__ */ t("div", { className: "tw-line-clamp-2 @xl:tw-line-clamp-4", children: n }) : null,
      i ? /* @__PURE__ */ t(
        y,
        {
          as: g,
          className: e(
            v,
            "tw-text-base tw-px-button-regular-padding-x tw-py-button-regular-padding-y [&>svg]:tw-w-[20px] [&>svg]:tw-h-[20px] [&>svg]:-tw-m-1",
            // focus
            "focus-visible:tw-ring-2 focus-visible:tw-ring-information-600 focus-visible:tw-outline-none",
            // Disabled
            "disabled:tw-text-ink-disabled disabled:tw-border-button-disabled-bg disabled:tw-bg-button-disabled-bg disabled:tw-cursor-not-allowed disabled:tw-no-underline [&>svg]:disabled:tw-fill-ink-disabled",
            // Base
            "tw-cursor-pointer tw-leading-none tw-border-2 tw-no-underline tw-rounded-button tw-inline-flex tw-justify-center tw-items-center tw-gap-x-2"
          ),
          href: b,
          onClick: u,
          children: i
        }
      ) : null
    ] }) })
  ] }) });
}
N.displayName = "SectionCard";
export {
  N as SectionCard
};
