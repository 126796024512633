import { view } from "@risingstack/react-easy-state";
import { IconBin, IconHeart, IconHeartFilled } from "@tine/designsystem-icons/sharp";
import { Button, InputStepper } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import clamp from "lodash-es/clamp";
import defaultTo from "lodash-es/defaultTo";
import isUndefined from "lodash-es/isUndefined";
import { useState } from "react";
import { Link } from "wouter";

import { sendAddCartToFavoriteList, sendAddToCart, sendRemoveFromCart } from "../../common/tracking";
import { CartItem, Product } from "../../common/types/productTypes";
import { GAListInformation, LIST_TYPE } from "../../common/types/trackingTypes";
import { formatPrice, formatUnit, inRangeInclusive } from "../../common/utils";
import ProductImage from "../../components/common/product/ProductImage";

import cartStore from "../../stores/cart/cartStore";
import productStore from "../../stores/product/productStore";
import { isDistributionPack } from "../../stores/product/productUtils";
import promotionStore from "../../stores/promotionStore";
import toastStore from "../../stores/toastStore";

import AddToFavoriteModal from "../../account/favorites/AddToFavoriteModal";
import DiscountLabel from "../../components/DiscountLabel";
import DisplayPrice from "../../components/DisplayPrice";
import LeadTimeWarning from "../../components/LeadTimeWarning";

type RecyclingChangeProps = {
   product: Product;
};

const bgGlowClassName = cn(["bg-glow"]);

const className = cn("tw-border-b-px tw-border-0 tw-border-solid tw-border-b-ink-border-subtle tw-pb-4 last:tw-border-b-0");

const oneBorderBaseCN = cn("tw-border-0 tw-border-solid");
const borderBottomCN = cn(oneBorderBaseCN, "tw-border-b-[1px] tw-border-b-ink-border-subtle");
const borderTopCN = cn(oneBorderBaseCN, "tw-border-t-[1px] tw-border-t-ink-border-subtle");
const desktopClassName = cn(["tw-hidden @[575px]:tw-block"]);
const mobileClassName = cn(["tw-block @[575px]:tw-hidden"]);

const outerRowCN = cn(["tw-flex tw-flex-row tw-justify-stretch"]);

const RecyclingChange = view(({ product }: RecyclingChangeProps) => {
   if (product.recyclingChargeBaseUnit === 0) {
      return null;
   }

   const conversion = isDistributionPack(product.unit) ? product.conversionFactor : 1;
   const recyclingCharge = product.recyclingChargeBaseUnit * conversion;

   return <small>+ {formatPrice(recyclingCharge)} pant</small>;
});

type MiniCartItemProps = {
   item: CartItem;
   onCartChanged: () => void;
   listInformation: GAListInformation;
};

const MiniCartItem = view(({ item, onCartChanged, listInformation }: MiniCartItemProps) => {
   const itemPosition = cartStore.items.findIndex((i) => i.sku === item.sku);

   const handleInputStepperChange = (newQty: number) => {
      if (cartStore.isEditing()) {
         const validRange = cartStore.getValidQtyRange(item.sku);
         item.qty = clamp(newQty, validRange.min, validRange.max);

         if (!inRangeInclusive(newQty, validRange)) {
            toastStore.addError(
               "Begrensninger på ordren",
               "Ordren som du redigerer har begrensninger som gjør at du ikke kan endre antallet på denne varen til " +
                  newQty +
                  ".",
               { context: "orders", text: "change_order_error" }
            );
         }
      } else {
         const oldQty = item.qty;
         item.qty = newQty;
         if (oldQty < newQty) {
            sendAddToCart([{ sku: item.sku, qty: newQty - item.qty }], listInformation);
         } else if (oldQty > newQty) {
            sendRemoveFromCart([{ sku: item.sku, qty: oldQty - newQty, position: itemPosition + 1 }], listInformation);
         }
      }
      cartStore.saveCartToServer();
      cartStore.updateCartDependencies();
      onCartChanged();
      return newQty;
   };

   const product = productStore.resolveSku(item.sku);
   if (isUndefined(product)) {
      console.warn("Customer has unavailable product in cart with sku " + item.sku);
      cartStore.removeFromCart(item.sku);
      return null;
   }

   const removeFromCart = () => {
      sendRemoveFromCart([{ sku: item.sku, qty: item.qty, position: itemPosition + 1 }], listInformation);
      cartStore.removeFromCart(item.sku);
      onCartChanged();
   };

   const extraClass =
      cartStore.isEditing() && (cartStore.hasQtyChangedDuringEdit(item.sku) || cartStore.isAddedDuringEdit(item.sku))
         ? bgGlowClassName
         : "";
   return (
      <div className={cn(className, extraClass)}>
         <div className={cn(desktopClassName)}>
            <div className={outerRowCN}>
               <div className="tw-mt-3 tw-grow-0 tw-px-2">
                  <ProductImage product={product} size={48} className="" />
               </div>
               <div className="tw-flex tw-shrink tw-grow tw-flex-col">
                  <div className="tw-flex tw-justify-between">
                     <div className="tw-my-3 tw-py-2 tw-text-base">
                        <Link to={productStore.getUrl(product)}>{product.name}</Link>
                     </div>
                     <Button
                        icon={<IconBin width={24} height={24} />}
                        variant="tertiary"
                        className="tw-self-start"
                        onClick={removeFromCart}
                     />
                  </div>
                  <div className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-2 tw-py-1">
                     <DiscountLabel sku={item.sku} />
                     <div className="tw-text-sm">Art. nr: {item.sku}</div>
                  </div>
                  <div className="tw-text-sm tw-font-bold">
                     {formatPrice(product.price)} pr {formatUnit(product.unit)}
                  </div>
                  <RecyclingChange product={product} />
                  <LeadTimeWarning product={product} />
                  <div className="tw-mr-4 tw-mt-4 tw-flex tw-justify-between">
                     <div>
                        <InputStepper min={1} value={item.qty} onChange={handleInputStepperChange} />
                     </div>
                     <DisplayPrice
                        className="tw-min-w-[140px] tw-text-right"
                        price={item.qty * defaultTo(product.priceBeforeDiscount, 0)}
                        newPrice={item.qty * defaultTo(product.price, 0) - promotionStore.getPromotionDiscount(item.sku)}
                     />
                  </div>
               </div>
            </div>
         </div>

         {/* Mobile */}
         <div className={cn(mobileClassName, extraClass)}>
            <div className="tw-mb-4 tw-flex tw-items-center">
               <div className="tw-mr-2 tw-mt-3">
                  <ProductImage product={product} size={48} />
               </div>
               <div className="tw-mt-3">{product.name}</div>
            </div>
            <div className="tw-mb-4 tw-flex tw-flex-wrap tw-items-end tw-justify-between">
               <div className="tw-flex tw-flex-col tw-flex-wrap tw-gap-1">
                  <div className="tw-flex tw-gap-4">
                     <DiscountLabel sku={item.sku} />
                  </div>
                  <div className="tw-text-sm">
                     <span className="tw-font-bold">{formatPrice(product.price)}</span>{" "}
                     <span className="tw-text-xs">per {formatUnit(product.unit)}</span>
                  </div>
               </div>
               <DisplayPrice
                  price={item.qty * defaultTo(product.priceBeforeDiscount, 0)}
                  newPrice={item.qty * defaultTo(product.price, 0) - promotionStore.getPromotionDiscount(item.sku)}
                  className="tw-mt-2"
               />
            </div>
            <div className="tw-flex tw-items-center tw-justify-between">
               <div>
                  <InputStepper value={item.qty} min={1} onChange={handleInputStepperChange} />
               </div>
               <Button variant="tertiary" onClick={removeFromCart}>
                  <IconBin width={24} height={24} />
               </Button>
            </div>
         </div>
      </div>
   );
});

type MiniCartOrderLines = {
   items: CartItem[];
};

const MiniCartOrderLines = ({ items }: MiniCartOrderLines) => {
   const qtySum = items.reduce((p, i) => p + i.qty, 0);
   const [favoriteModalOpen, setFavoriteModalOpen] = useState(false);
   const addToFavoriteText = "Lagre handlekurven som favorittliste";
   const favoriteAddedText = "Lagret som favorittliste";
   const [favoriteListAdded, setFavoriteListAdded] = useState(false);
   const listInformation: GAListInformation = { type: LIST_TYPE.CART, name: "Minicart", length: items.length };

   const heartIcon = favoriteListAdded ? <IconHeartFilled className="tw-text-[--dsp-color-ink-favorites]" /> : <IconHeart />;
   return (
      <>
         <div className={cn("tw-mt-6 tw-px-1 tw-pb-4 tw-text-xxs @[575px]:tw-px-2", borderBottomCN)}>
            {qtySum} produkter | {items.length} varelinjer
         </div>
         <div className="tw-w-full">
            {items.map((ol, i) => (
               <MiniCartItem
                  key={ol.sku}
                  item={ol}
                  onCartChanged={() => setFavoriteListAdded(false)}
                  listInformation={listInformation}
               />
            ))}
         </div>

         <div className={`tw-flex tw-items-end tw-py-6 ${borderTopCN} [&>button>svg]:hover:tw-text-[--dsp-color-ink-favorites]`}>
            <Button variant="secondary" icon={heartIcon} iconPosition="left" onClick={() => setFavoriteModalOpen(true)}>
               {favoriteListAdded ? <>{favoriteAddedText}</> : <>{addToFavoriteText}</>}
            </Button>
         </div>
         <AddToFavoriteModal
            isOpen={favoriteModalOpen}
            onCloseModal={() => setFavoriteModalOpen(false)}
            favoriteListAdded={() => {
               setFavoriteListAdded(true);
               sendAddCartToFavoriteList("minicart");
            }}
         />
      </>
   );
};

export default view(MiniCartOrderLines);
