import { view } from "@risingstack/react-easy-state";
import { IconHeart } from "@tine/designsystem-icons/sharp";

import useMediaQuery from "../common/useMediaQuery";
import { mediaQueries } from "../common/utils";

import Button from "./Button";

const FavoriteListButton = () => {
   return (
      <Button
         to="/customer/favorites"
         iconPosition="left"
         size="full"
         variant="tertiary"
         icon={<IconHeart />}
         aria-label="favorittlister"
         trackingName="Favorittlister"
         data-testid="go-to-favorite-list-menu-button"
      >
         {useMediaQuery(mediaQueries.xl) ? "Favorittlister" : ""}
      </Button>
   );
};

export default view(FavoriteListButton);
