import { jsxs as m, Fragment as c, jsx as r } from "react/jsx-runtime";
import { useMemo as d } from "react";
import { generateUniqueClassName as u, generateGridColSpanStyles as p, generateGridColOrderStyles as y, cn as C } from "./designsystem-ui-react46.js";
function x({ className: s, children: t, order: n, span: o, ...a }) {
  const e = d(u, []), l = p(o), i = y(n);
  return /* @__PURE__ */ m(c, { children: [
    /* @__PURE__ */ r("div", { className: C(e, s), ...a, children: t }),
    /* @__PURE__ */ r("style", { children: `
        .${e} {
          ${l}
          ${i}
        }
      ` })
  ] });
}
export {
  x as GridCol
};
