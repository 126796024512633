import { jsxs as m, Fragment as f, jsx as e } from "react/jsx-runtime";
import { cn as r } from "./designsystem-ui-react46.js";
import { Tooltip as c } from "./designsystem-ui-react43.js";
function x({
  className: a,
  description: l = "",
  id: i,
  isLabelHidden: o = !1,
  isDisabled: n = !1,
  label: t,
  tooltipPosition: d = "right",
  tooltipText: s = ""
}) {
  const w = i ?? (typeof t == "string" ? t : void 0);
  return /* @__PURE__ */ m(f, { children: [
    /* @__PURE__ */ m("div", { className: r("tw-flex tw-items-center tw-gap-x-2", a), children: [
      /* @__PURE__ */ e(
        "label",
        {
          className: r(
            "tw-text-ink-brand-default tw-font-semibold tw-text-sm",
            o && "tw-sr-only",
            n && "tw-text-ink-disabled"
          ),
          htmlFor: w,
          children: t
        }
      ),
      s ? /* @__PURE__ */ e(c, { tooltipPosition: d, tooltipText: s }) : null
    ] }),
    l ? /* @__PURE__ */ e("p", { className: r("tw-text-sm tw-text-ink-brand-default tw-mb-0", n && "tw-text-ink-disabled"), children: l }) : null
  ] });
}
x.displayName = "FormDetails";
export {
  x as FormDetails
};
