import { jsxs as t, jsx as a } from "react/jsx-runtime";
const i = (l) => t("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "1.5em", height: "1.5em", style: {
  fillRule: "evenodd",
  clipRule: "evenodd"
}, focusable: "false", "aria-hidden": "true", fill: "currentColor", viewBox: "0 0 24 24" }, l, { children: [a("path", { fill: "#005731", d: "M395.5-.5h451v452q-18.147 198.68-177 317.5-94.24 66.166-209 77.5H-.5v-460Q24.66 168.268 211.5 55q85.96-48.24 184-55.5", style: {
  opacity: 0.999
} }), a("path", { fill: "#d3dd81", d: "M465.5 55.5q-.25 16.007.5 32 1.075-.816 1.5-2a1605 1605 0 0 1 22-29.5 90.5 90.5 0 0 1 19 0 1211 1211 0 0 0-26 32.5 1122 1122 0 0 0 27 37q-8.715 1.485-18 1a1005 1005 0 0 1-25.5-36 648 648 0 0 0-.5 36h-15v-71z", style: {
  opacity: 1
} }), a("path", { fill: "#6b9b5a", d: "m465.5 55.5 2 30q-.425 1.185-1.5 2a512 512 0 0 1-.5-32", style: {
  opacity: 1
} }), a("path", { fill: "#d0dc80", d: "M390.5 55.5q13.414-1.102 26 3.5 1.392.582 2 2-2.064 5.89-6 10.5-11.147-4.195-23-2.5-8.167 3.085-5.5 11.5a6.98 6.98 0 0 0 3.5 2.5q10.978 1.998 22 4 20.77 5.785 14.5 26.5-5.416 12.114-18.5 15.5-18.077 4.959-36-.5l3-12a88.6 88.6 0 0 0 27 1.5q13.956-3.406 6-15l-4-2a134 134 0 0 1-24-4q-21.328-17.32-1-36 6.855-3.59 14-5.5", style: {
  opacity: 1
} }), a("path", { fill: "#d1dc80", d: "M328.5 70.5q3.616-.544 4.5 3a7192 7192 0 0 1 19.5 65l-15 4a7531 7531 0 0 0-20-66.5 3.94 3.94 0 0 1 1-2q5.22-1.655 10-3.5", style: {
  opacity: 1
} }), a("path", { fill: "#fbfcfb", d: "M744.5 78.5q7.516-.25 15 .5 3.75 1.75 5.5 5.5 3.59 43.053-29.5 70.5-3.593 3.217-6.5-.5a35 35 0 0 1-1.5-9 438 438 0 0 0 3-36 1101 1101 0 0 1-41 6 8.4 8.4 0 0 1 .5-4q22.157-24.905 54.5-33", style: {
  opacity: 1
} }), a("path", { fill: "#d1dc80", d: "M272.5 92.5h13a39 39 0 0 1-2 12q-16.378-1.677-26.5 11-1.875 3.865-1 8a79.5 79.5 0 0 0 9 22q10.893 14.977 27.5 5.5a39 39 0 0 0 5-5.5q-2.893-7.308-8-13l-10 5a86 86 0 0 1-7-8.5 230 230 0 0 1 25-14.5 134 134 0 0 1 11.5 19q3.426 5.823 5 12-16.995 23.125-45.5 20.5-9.825-2.835-16.5-10.5-11.844-15.03-11-34 7.933-23.19 31.5-29", style: {
  opacity: 1
} }), a("path", { fill: "#6a9b59", d: "M509.5 125.5q.814.09 1 1-9.732 1.975-19 0 9.285.485 18-1", style: {
  opacity: 1
} }), a("path", { fill: "#d1dc80", d: "M194.5 144.5q25.519-1.365 39.5 20 18.447 37.128-20.5 52.5-32.9 1.64-44.5-29.5-4.026-13.197 3-25 8.464-12.738 22.5-18", style: {
  opacity: 1
} }), a("path", { fill: "#035831", d: "M193.5 157.5q15.119 1.692 24.5 14l7 10q5.367 15.517-9.5 22.5-10.452 2.562-18-5-10.12-8.502-15.5-20.5a25.2 25.2 0 0 1 0-10q4.606-6.859 11.5-11", style: {
  opacity: 1
} }), a("path", { fill: "#8baf3c", d: "M409.5 183.5q36.755-1.994 74 0-.186.91-1 1a1333 1333 0 0 0-73-1", style: {
  opacity: 1
} }), a("path", { fill: "#bdcf40", d: "M409.5 183.5q36.759-.497 73 1-149 150-299 299a1157 1157 0 0 1-1-68q6.184-121.652 105-191.5 55.3-37.183 122-40.5", style: {
  opacity: 1
} }), a("path", { fill: "#d1dc80", d: "M122.5 219.5a2349 2349 0 0 1 48.5 34l16.5-22.5q1-1 2 0a63 63 0 0 0 7 8 1251 1251 0 0 1-24 33.5l-58-41a71 71 0 0 0 8-12", style: {
  opacity: 1
} }), a("path", { fill: "#fcfdfc", d: "M580.5 235.5q15.236-1.006 28 7.5 15.53 11.523 24.5 28.5 6.31 20.274-7 37l-9 9q-1.868-13.236-11-23L586.5 275a22.7 22.7 0 0 0-10-4.5q-3.14-.022-5.5 2-13.233-9.913-6-25 6.276-8.04 15.5-12", style: {
  opacity: 1
} }), a("path", { fill: "#fcfdfd", d: "M553.5 254.5q1.197 10.31 7.5 19a406 406 0 0 0 23.5 24.5 25 25 0 0 0 9.5 4.5q6.431-4.55 11 2 9.738 23.286-14.5 31.5-9.128 1.291-18-1-18.394-8.886-30.5-25.5-12.256-19.782-1-40a90 90 0 0 1 12.5-15", style: {
  opacity: 1
} }), a("path", { fill: "#d1dd80", d: "M102.5 271.5q26.561-2.564 45.5 16 12.009 21.872-3 42-15.642 16.099-37.5 9.5-44.336-18.081-18.5-58.5 5.823-6.107 13.5-9", style: {
  opacity: 1
} }), a("path", { fill: "#fbfcfc", d: "M471.5 272.5q25.695-.552 26.5 25-8.44 22.1-30.5 14.5-22.564-16.91-2-36 3.34-1.412 6-3.5", style: {
  opacity: 1
} }), a("path", { fill: "#045832", d: "M100.5 286.5q24-.093 40.5 17 2.874 25.626-22.5 21.5-14.923-3.582-25.5-14.5-4.816-14.794 7.5-24", style: {
  opacity: 1
} }), a("path", { fill: "#fcfdfd", d: "M510.5 300.5a837 837 0 0 1 43 41.5q5.337 3.231 9.5-1.5a267 267 0 0 1 13.5 14 1077 1077 0 0 0-39 39 230 230 0 0 0-12.5-13q.423-3.174 1.5-6.5a12 12 0 0 0-2.5-5.5L501.5 346a9.6 9.6 0 0 0-4.5-1.5q-3.326 1.077-6.5 1.5a212 212 0 0 1-12-12.5z", style: {
  opacity: 1
} }), a("path", { fill: "#d3dd81", d: "M768.5 378.5q-.814.09-1 1a325 325 0 0 0 36 1v14h-71v-14a257 257 0 0 0 32-1q-.186-.91-1-1a949 949 0 0 1-30.5-22 90.5 90.5 0 0 1 0-19 1210 1210 0 0 0 32.5 26 1530 1530 0 0 1 37.5-28 67.2 67.2 0 0 1-.5 20 25448 25448 0 0 1-34 23", style: {
  opacity: 1
} }), a("path", { fill: "#fcfdfd", d: "M461.5 349.5q12.271-1.427 23 4.5 25.258 16.25 37.5 43.5 1.486 10.554-5 19-6.728 9.25-17.5 12.5-14.492 3.243-22-9.5 7.98-5.9 2.5-14a96.2 96.2 0 0 0-17.5-15.5q-3-1-6 0l-4.5 4.5q-17.918-14.033-5-33 6.224-7.495 14.5-12", style: {
  opacity: 1
} }), a("path", { fill: "#d1dc80", d: "M63.5 351.5a1209 1209 0 0 1 29.5 29 2380 2380 0 0 0 40.5-22l1 1.5a113 113 0 0 0-3 18.5 519 519 0 0 0-39 18.5 381 381 0 0 0 34 5.5q.524 7.38 0 15a17141 17141 0 0 1-69-9.5q-1.617-1.011-2-3a571 571 0 0 0 2-12.5 181 181 0 0 0 31 3.5A514 514 0 0 1 61 369.5a166 166 0 0 1 2.5-18", style: {
  opacity: 1
} }), a("path", { fill: "#fdfdfd", d: "M402.5 356.5a2.43 2.43 0 0 1 2 .5 3767 3767 0 0 0 72.5 73.5l1 14a23.7 23.7 0 0 0 2.5 6q-10.5 9.5-20 20a4892 4892 0 0 1-58-59.5 94 94 0 0 0-13-10 29 29 0 0 0-7 1L371 390.5q-1-1 0-2a1423 1423 0 0 0 31.5-32", style: {
  opacity: 1
} }), a("path", { fill: "#bdcf40", d: "M433.5 663.5q-35.759.497-71-1a127597 127597 0 0 0 300.5-300q1 38 0 76Q648.096 585.169 514.5 646q-38.968 15.615-81 17.5", style: {
  opacity: 1
} }), a("path", { fill: "#a0bd6e", d: "M763.5 378.5q.814.09 1 1a257 257 0 0 1-32 1z", style: {
  opacity: 1
} }), a("path", { fill: "#9ebc6d", d: "m768.5 378.5 35 2a325 325 0 0 1-36-1q.186-.91 1-1", style: {
  opacity: 1
} }), a("path", { fill: "#5f9439", d: "M182.5 415.5q-.496 34.259 1 68-.09.814-1 1-1.994-34.745 0-69", style: {
  opacity: 1
} }), a("path", { fill: "#d2dd80", d: "M737.5 421.5a44 44 0 0 1 7 4.5q2.099 1.593 3 4-6.04 8.577-7 19-1.577 11.912 10 11l2.5-2.5a496 496 0 0 1 10-23q12.237-14.884 29.5-5.5 13.201 13.024 9.5 31.5-1.878 12.634-8.5 23.5-6.421-.966-11-6 9.988-14.442 6.5-31.5-6.858-7.968-14 0a496 496 0 0 0-10 23q-9.315 9.602-22.5 6.5-11.17-4.572-13.5-16.5a90.5 90.5 0 0 1 0-19q2.673-10.334 8.5-19", style: {
  opacity: 1
} }), a("path", { fill: "#fbfcfc", d: "M387.5 426.5q24.27-1.491 29 22.5-5.206 19.215-23.5 27.5.656-10.352-9.5-15.5-3.404-1.137-6.5.5-11.879-15.705 2.5-29.5a59.7 59.7 0 0 1 8-5.5", style: {
  opacity: 1
} }), a("path", { fill: "#d1dc80", d: "M50.5 440.5a134 134 0 0 1 13 8q6.68-5.34 15-7.5a156.4 156.4 0 0 1 25 0q27.208 9.646 23.5 38.5a35.9 35.9 0 0 1-3.5 10.5 75.4 75.4 0 0 1 11.5 8.5q-.49 4.563-4 8a144 144 0 0 0-12.5-8q-25.27 16.368-52 2.5Q50 485.794 55 463.5a54 54 0 0 1 2.5-6.5 1284 1284 0 0 0-11-9.5 37 37 0 0 0 4-7", style: {
  opacity: 1
} }), a("path", { fill: "#fcfdfd", d: "M362.5 445.5q1.79 12.1 9.5 22 12.865 15.868 29.5 27.5 12.995 1.5 10.5-11.5a69 69 0 0 0-4.5-6.5l22.5-22.5q16.287 14.43 9 35-12.959 25.96-39.5 37.5a52.8 52.8 0 0 1-19-1q-19.476-8.972-30.5-27.5-11.447-20.36 1-40a215 215 0 0 1 11.5-13", style: {
  opacity: 1
} }), a("path", { fill: "#0b5b33", d: "M85.5 456.5q12.964-.184 25 4.5 9.149 8.615 4.5 20.5-1.574 1.526-3.5.5a1210 1210 0 0 1-33-23.5 247 247 0 0 0 7-2", style: {
  opacity: 1
} }), a("path", { fill: "#075a32", d: "M67.5 463.5a1218 1218 0 0 1 38 25.5 96.6 96.6 0 0 1-26 1q-20.546-6.322-12-26.5", style: {
  opacity: 1
} }), a("path", { fill: "#fcfdfd", d: "M289.5 483.5q23.44-.938 41 14.5 18.786 14.777 28.5 36.5 7.286 26.86-11 48a212 212 0 0 1-12.5 12q-8.5-7.5-16-16 8.399-10.123 2.5-22-13.19-18.69-31.5-32.5a30.7 30.7 0 0 0-12-4.5q-6.805 1.402-12 6a117.3 117.3 0 0 0-16-15.5q15.666-19.3 39-26.5", style: {
  opacity: 1
} }), a("path", { fill: "#d1dc80", d: "M720.5 497.5a3959 3959 0 0 0 68 20l-4 15a1926 1926 0 0 1-67-20q-.75-7.806 3-15", style: {
  opacity: 1
} }), a("path", { fill: "#fdfefe", d: "M242.5 517.5a5107 5107 0 0 0 85 85 2287 2287 0 0 0-39.5 41 396 396 0 0 1-16.5-17q8.09-9.158-.5-18a1019 1019 0 0 0-38.5-37.5q-7.896-3.694-14 2.5-8.5-7.5-16-16a1131 1131 0 0 0 40-40", style: {
  opacity: 1
} }), a("path", { fill: "#d1dc80", d: "M712.5 539.5q1.494-.129 2.5 1l3.5 9a180 180 0 0 0-11.5 11q-8.841 14.85 4.5 25.5 10.58 7.916 23.5 10.5 17.753-2.836 17.5-20.5a104 104 0 0 1-12-6.5 62.7 62.7 0 0 0-6 9q-6.052 1.416-11.5-2 5.35-12.7 12.5-25 1.298-1.624 3-.5l27 14.5q1.382 23.495-16 39.5-24.258 12.546-46-4-18.507-16.795-9.5-40.5 6.389-13.131 18.5-21", style: {
  opacity: 1
} }), a("path", { fill: "#d2dd80", d: "M664.5 614.5q31.133-.616 45.5 27 6.578 28.097-18.5 42.5-15.192 5.288-30-1-12.82-8.32-20.5-21.5-8.007-22.482 9.5-38.5 6.498-5.284 14-8.5", style: {
  opacity: 1
} }), a("path", { fill: "#045832", d: "M664.5 627.5q7.366.924 14 4.5 11.881 8.879 19.5 21.5.985 21.515-20.5 19.5-17.14-7.692-25.5-24.5-2.593-10.058 5.5-16.5a786 786 0 0 0 7-4.5", style: {
  opacity: 1
} }), a("path", { fill: "#5e9338", d: "M362.5 662.5q35.241 1.497 71 1-35.755 1.993-72 0 .186-.91 1-1", style: {
  opacity: 1
} }), a("path", { fill: "#d1dc80", d: "M592.5 675.5a1198 1198 0 0 1 34 47.5q1 1 2 0a506 506 0 0 1 22-15.5q4.188 5.562 4 12a1750 1750 0 0 1-32.5 23 3492 3492 0 0 0-40.5-58 117 117 0 0 1 11-9", style: {
  opacity: 1
} }), a("path", { fill: "#fbfcfc", d: "M111.5 690.5q4.108-.803 5.5 3a230 230 0 0 1-2.5 38.5 14.4 14.4 0 0 0 1 5 842 842 0 0 1 40-5.5q-1.853 7.418-8 12.5-23.432 21.213-55 24.5-9.525 1.113-12.5-8-2.504-43.474 31.5-70", style: {
  opacity: 1
} }), a("path", { fill: "#d1dd80", d: "M540.5 704.5q21.207-.774 32.5 17 14.655 23.277 0 46-23.19 19.568-48.5 3.5-20.91-22.362-9.5-50.5 9.88-12.642 25.5-16", style: {
  opacity: 1
} }), a("path", { fill: "#045832", d: "M539.5 716.5q4.724-.434 9 1.5 19.681 18.027 13.5 43.5-14.781 12.942-27-2-9.192-14.78-9-32 4.389-8.549 13.5-11", style: {
  opacity: 1
} }), a("path", { fill: "#d1dc80", d: "M397.5 724.5q4.42 1.716 8 5l-8 12q15.263 23.143 4.5 49-10.734 15.867-30 15-7.896.4-15-3-4.75 4.692-8.5 10.5-3.775.385-7-2-1.526-1.574-.5-3.5l7.5-11q-12.798-15.206-9.5-35 7.174-33.636 41.5-28.5 4.98 1.674 10 2a168 168 0 0 1 7-10.5m83 1q3.535-.245 7 .5a1193 1193 0 0 0-21 35.5 1562 1562 0 0 0 33 33.5 59.8 59.8 0 0 1-20 2L452 767.5a620 620 0 0 0 3.5 35q-7.54.004-15 1a8815 8815 0 0 0-10-70 573 573 0 0 0 12.5-2 6.3 6.3 0 0 1 3 1 810 810 0 0 1 4 30 840 840 0 0 0 18.5-34 156 156 0 0 1 12-3", style: {
  opacity: 1
} }), a("path", { fill: "#065932", d: "M366.5 743.5q8.717-1.089 16 3.5a677 677 0 0 1-25 35.5q-2.907-11.835-1.5-24 1.612-10.281 10.5-15", style: {
  opacity: 1
} }), a("path", { fill: "#0c5b33", d: "M386.5 756.5q2.146.572 2.5 3 2.203 15.629-4 30-2.597 3.175-6.5 4.5-7.18 1.318-14-1-1.552-2.26.5-4.5a864 864 0 0 0 21.5-32", style: {
  opacity: 1
} })] }));
export {
  i as default
};
