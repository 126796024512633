import { useRef as o, useEffect as n } from "react";
import { microTask as u } from "./designsystem-ui-react120.js";
import { useEvent as m } from "./designsystem-ui-react92.js";
function i(t) {
  let e = m(t), r = o(!1);
  n(() => (r.current = !1, () => {
    r.current = !0, u(() => {
      r.current && e();
    });
  }), [e]);
}
export {
  i as useOnUnmount
};
