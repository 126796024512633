import { jsxs as i, jsx as n } from "react/jsx-runtime";
import { cn as C } from "./designsystem-ui-react46.js";
import { useState as v, useRef as x, useCallback as N, useEffect as X } from "react";
import et from "./designsystem-ui-react139.js";
import nt from "./designsystem-ui-react88.js";
import ot from "./designsystem-ui-react140.js";
import st from "./designsystem-ui-react141.js";
import lt from "./designsystem-ui-react142.js";
import rt from "./designsystem-ui-react143.js";
function ht({
  player: o,
  isPlaying: D,
  duration: l,
  currentTime: h,
  loadedFraction: $,
  onPlayPause: B,
  onSeek: O,
  onDragStart: W,
  onDragging: M,
  onDragEnd: k,
  isFullscreen: g
}) {
  var T;
  const [c, j] = v(100), [u, b] = v(!1), [A, P] = v(!1), [d, V] = v(!1), [w, S] = v(null), E = x(null), R = x(null), F = x(null), f = x(), Y = (t) => {
    const e = Math.floor(t / 60), a = Math.floor(t % 60);
    return `${e}:${a.toString().padStart(2, "0")}`;
  }, p = N(
    (t) => {
      if (!E.current || !l)
        return 0;
      const e = E.current.getBoundingClientRect(), r = e.width - 16, s = (t - e.left) / r;
      return Math.min(Math.max(s, 0), 1);
    },
    [l]
  ), y = (t) => {
    const e = p(t);
    S(e), V(!0), W(), M(e * l);
  }, I = (t) => {
    if (t.buttons === 1)
      y(t.clientX);
    else {
      const e = p(t.clientX);
      O(e * l);
    }
  }, H = (t) => {
    t.preventDefault(), y(t.touches[0].clientX);
  }, K = N(
    (t) => {
      if (!o)
        return;
      const e = Math.max(0, Math.min(100, t));
      o.setVolume(e), j(e), e === 0 ? (b(!0), o.mute()) : u && (b(!1), o.unMute());
    },
    [o, u]
  ), U = N(() => {
    o && (u ? (o.unMute(), o.setVolume(c), b(!1)) : (o.mute(), b(!0)));
  }, [o, u, c]), G = N(() => {
    var e;
    const t = (e = o == null ? void 0 : o.getIframe()) == null ? void 0 : e.closest(".video-player-container");
    t && (document.fullscreenElement ? document.exitFullscreen ? document.exitFullscreen() : document.webkitExitFullscreen ? document.webkitExitFullscreen() : document.msExitFullscreen ? document.msExitFullscreen() : document.mozCancelFullScreen && document.mozCancelFullScreen() : t.requestFullscreen ? t.requestFullscreen() : t.webkitRequestFullscreen ? t.webkitRequestFullscreen() : t.msRequestFullscreen ? t.msRequestFullscreen() : t.mozRequestFullScreen ? t.mozRequestFullScreen() : o && o.playVideo());
  }, [o]);
  X(() => {
    g && F.current && F.current.focus();
  }, [g]), X(() => {
    const t = (s) => {
      if (d) {
        const m = p(s);
        S(m), M(m * l);
      }
    }, e = () => {
      d && w !== null && (k(w * l), V(!1), S(null));
    }, a = (s) => {
      t(s.clientX);
    }, r = (s) => {
      s.preventDefault(), t(s.touches[0].clientX);
    };
    return d && (document.addEventListener("mousemove", a), document.addEventListener("mouseup", e), document.addEventListener("touchmove", r, { passive: !1 }), document.addEventListener("touchend", e), document.addEventListener("touchcancel", e)), () => {
      document.removeEventListener("mousemove", a), document.removeEventListener("mouseup", e), document.removeEventListener("touchmove", r), document.removeEventListener("touchend", e), document.removeEventListener("touchcancel", e);
    };
  }, [d, w, l, M, k, p]);
  const J = () => d && w !== null ? `${w * 100}%` : l - h <= 1 ? "100%" : `${h / l * 100}%`, Q = () => {
    f.current && clearTimeout(f.current), f.current = setTimeout(() => {
      P(!1);
    }, 300);
  }, Z = () => {
    f.current && clearTimeout(f.current), P(!0);
  }, _ = (t) => {
    var s, m;
    if (!((s = R.current) == null ? void 0 : s.getBoundingClientRect()))
      return;
    const a = (m = R.current) == null ? void 0 : m.querySelector('input[type="range"]'), r = a == null ? void 0 : a.getBoundingClientRect();
    r && t.clientX >= r.left && t.clientX <= r.right && t.clientY >= r.top && t.clientY <= r.bottom || Q();
  }, L = J(), q = ((T = navigator == null ? void 0 : navigator.userAgentData) == null ? void 0 : T.platform) || (navigator == null ? void 0 : navigator.platform) || "unknown", z = q === "MacIntel" && navigator.maxTouchPoints > 0 || q === "iPad", tt = /iPhone|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent || "");
  return /* @__PURE__ */ i(
    "div",
    {
      className: C(
        "tw-bg-surface-default-inverted tw-px-1 tw-rounded-b-lg tw-flex tw-items-center tw-gap-2",
        "tw-relative",
        g && "tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-rounded-none tw-z-50 tw-p-2"
      ),
      children: [
        /* @__PURE__ */ i("div", { className: "tw-flex tw-items-center tw-gap-1", children: [
          /* @__PURE__ */ n(
            "button",
            {
              className: "tw-bg-transparent tw-p-2 focus:tw-outline-none",
              onClick: B,
              ref: F,
              type: "button",
              children: D ? /* @__PURE__ */ n(et, { className: "tw-fill-surface-default" }) : /* @__PURE__ */ n(nt, { className: "tw-fill-surface-default" })
            }
          ),
          !tt && !z ? /* @__PURE__ */ i(
            "div",
            {
              className: "tw-relative",
              onMouseEnter: Z,
              onMouseLeave: _,
              ref: R,
              children: [
                /* @__PURE__ */ n("button", { className: "tw-bg-transparent tw-p-2", onClick: U, type: "button", children: u ? /* @__PURE__ */ n(ot, { className: "tw-fill-surface-default" }) : /* @__PURE__ */ n(st, { className: "tw-fill-surface-default" }) }),
                /* @__PURE__ */ n(
                  "div",
                  {
                    className: C(
                      "tw-absolute tw-left-2 tw-bottom-full tw-rounded-t-sm",
                      "tw-bg-surface-default-inverted tw-p-2",
                      "tw-transition-all tw-duration-200 tw-z-50",
                      A ? "tw-opacity-100 tw-translate-y-0 tw-pointer-events-auto" : "tw-opacity-0 tw-translate-y-2 tw-pointer-events-none"
                    ),
                    children: /* @__PURE__ */ i("div", { className: "tw-flex tw-items-center tw-justify-center tw-h-24 tw-relative", children: [
                      /* @__PURE__ */ i("div", { className: "tw-relative tw-w-1 tw-h-full tw-bg-ink-brand-subtle/30 tw-rounded-full tw-ring-1 tw-ring-surface-default", children: [
                        /* @__PURE__ */ n(
                          "div",
                          {
                            className: "tw-absolute tw-bottom-0 tw-w-full tw-bg-surface-default tw-rounded-full",
                            style: { height: `${u ? 0 : c}%` }
                          }
                        ),
                        /* @__PURE__ */ n(
                          "div",
                          {
                            className: "tw-absolute tw-w-3 tw-h-3 tw-bg-button-primary-bg tw-border-2 tw-border-surface-default tw-rounded-full tw-left-1/2 tw-transform -tw-translate-x-1/2 tw-shadow-md hover:tw-scale-110 tw-transition-transform",
                            style: {
                              bottom: `${u ? 0 : c}%`,
                              transform: "translateX(-50%) translateY(50%)"
                            }
                          }
                        )
                      ] }),
                      /* @__PURE__ */ n(
                        "input",
                        {
                          className: "tw-absolute tw-w-24 tw-opacity-0 tw-cursor-pointer",
                          max: "100",
                          min: "0",
                          onChange: (t) => {
                            K(parseInt(t.target.value));
                          },
                          style: {
                            transform: "rotate(-90deg)",
                            transformOrigin: "center"
                          },
                          type: "range",
                          value: u ? 0 : c
                        }
                      )
                    ] })
                  }
                )
              ]
            }
          ) : null
        ] }),
        /* @__PURE__ */ i("div", { className: "tw-flex tw-flex-grow tw-items-center tw-gap-2", children: [
          /* @__PURE__ */ n(
            "div",
            {
              "aria-valuemax": l,
              "aria-valuemin": 0,
              "aria-valuenow": h,
              className: "tw-flex-1 tw-cursor-pointer tw-px-2",
              onKeyDown: (t) => {
                (t.key === "Enter" || t.key === " ") && (t.preventDefault(), I(t));
              },
              onMouseDown: I,
              onTouchStart: H,
              ref: E,
              role: "slider",
              tabIndex: 0,
              children: /* @__PURE__ */ i("div", { className: "tw-h-1 tw-relative tw-rounded-full", children: [
                /* @__PURE__ */ n("div", { className: "tw-absolute tw-inset-0 tw-bg-ink-brand-subtle tw-opacity-30 tw-rounded-full" }),
                /* @__PURE__ */ n(
                  "div",
                  {
                    className: "tw-absolute tw-left-0 tw-top-0 tw-h-full tw-rounded-full tw-bg-surface-default tw-opacity-50 tw-z-0",
                    style: { width: `${Math.min($ * 100, 100)}%` }
                  }
                ),
                /* @__PURE__ */ n(
                  "div",
                  {
                    className: "tw-relative tw-h-full tw-rounded-full tw-bg-surface-default tw-z-10",
                    style: { width: L },
                    children: /* @__PURE__ */ n(
                      "div",
                      {
                        className: C(
                          "tw-absolute tw-top-1/2 tw-w-4 tw-h-4 tw--translate-y-1/2 tw-bg-button-primary-bg tw-rounded-full tw-border-2 tw-border-surface-default",
                          L === "100%" ? "tw-right-0" : "tw--right-2"
                        )
                      }
                    )
                  }
                )
              ] })
            }
          ),
          /* @__PURE__ */ n("div", { className: "tw-text-surface-default tw-w-12 tw-text-sm tw-whitespace-nowrap", children: Y(h) })
        ] }),
        /* @__PURE__ */ n("button", { className: "tw-bg-transparent tw-p-2", onClick: G, type: "button", children: g ? !z && /* @__PURE__ */ n(lt, { className: "tw-fill-surface-default" }) : /* @__PURE__ */ n(rt, { className: "tw-fill-surface-default" }) })
      ]
    }
  );
}
export {
  ht as VideoControls
};
