import { IconChevronLeft, IconChevronRight } from "@tine/designsystem-icons/sharp";
import { Button, Grid } from "@tine/designsystem-ui-react";
import { cn, Cols } from "@tine/designsystem-utils";
import { isAfter, isBefore, isSameDay, lastDayOfMonth } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";

import { ArrowDirection, MonthAndYear, MonthNumber } from "../../../common/types/calendarTypes";
import { monthNames } from "../../../common/utils";
import {
   createCalendarMatrix,
   getHighestMonthAndYear,
   getLowestMonthAndYear,
   getMonthAndYears,
   getNextMonth,
   getPreviousMonth,
   getRelativeDate,
   getSelectedOrFirstSelectableDate,
   isArrowDirection,
   isDateBetween,
   isInMonthYears,
   monthAndYearCompare
} from "../../../common/utils/dateUtils";

import Month, { MonthProps } from "./Month";

export type MonthsProps = MonthProps & {
   min?: Date;
   max?: Date;
   monthCount: 1 | 2 | 3 | 4;
   selecting?: Date;
   onRangeSelected?: (date1: Date, date2: Date) => void;
   onMonthChange?: (monthAndYear: MonthAndYear) => void;
   onBlur?: (e: FocusEvent) => void;
   isArrowFocus?: boolean;
};

const Months = ({
   min,
   max,
   monthCount,
   month,
   year,
   selecting,
   getDayFlags,
   onDayClicked,
   onBlur,
   isArrowFocus,
   ...props
}: MonthsProps) => {
   const [monthAndYears, setMonthAndYears] = useState<MonthAndYear[]>(getMonthAndYears(month, year, monthCount, min, max));
   const [calendarMatrix, setCalendarMatrix] = useState(
      createCalendarMatrix(
         [getPreviousMonth(monthAndYears[0]), ...monthAndYears, getNextMonth(monthAndYears[monthAndYears.length - 1])],
         getDayFlags
      )
   );
   const [hovering, setHovering] = useState<Date | undefined>();
   const [focusedDate, setFocusedDate] = useState<Date | undefined>();
   const ref = useRef<HTMLDivElement>(null);
   const lowestMonthAndYear: MonthAndYear = useMemo(() => getLowestMonthAndYear(min), [min]);
   const highestMonthAndYear: MonthAndYear = useMemo(() => getHighestMonthAndYear(max), [max]);

   const previousMonthAndYear: MonthAndYear | undefined = useMemo(() => {
      const previous = getPreviousMonth(monthAndYears[0]);
      if (monthAndYearCompare(previous, lowestMonthAndYear) >= 0) {
         return previous;
      }
   }, [monthAndYears, lowestMonthAndYear]);

   const nextMonthAndYear: MonthAndYear | undefined = useMemo(() => {
      const next = getNextMonth(monthAndYears[monthAndYears.length - 1]);
      if (monthAndYearCompare(next, highestMonthAndYear) <= 0) {
         return next;
      }
   }, [monthAndYears, highestMonthAndYear]);

   useEffect(() => {
      if (document.activeElement && ref.current) {
         if (ref.current === document.activeElement || ref.current.contains(document.activeElement)) {
            const focusedDay = ref.current?.querySelector('[data-focused="true"]');
            if (focusedDay && focusedDay instanceof HTMLElement) {
               focusedDay.focus();
            }
         }
      }
   }, [monthAndYears, focusedDate]);

   useEffect(() => {
      setCalendarMatrix(
         createCalendarMatrix(
            [getPreviousMonth(monthAndYears[0]), ...monthAndYears, getNextMonth(monthAndYears[monthAndYears.length - 1])],
            getDayFlags
         )
      );

      const handleFocus = (e: FocusEvent) => {
         if (e.target === ref.current) {
            setFocusedDate(getSelectedOrFirstSelectableDate(monthAndYears, getDayFlags));
         }
      };

      const handleFocusOut = (e: FocusEvent) => {
         if (ref.current) {
            if (e.relatedTarget instanceof HTMLElement) {
               if (!ref.current?.contains(e.relatedTarget)) {
                  setFocusedDate(undefined);
               }
            }
         }
      };
      if (ref.current) {
         ref.current.addEventListener("focus", handleFocus);
         ref.current.addEventListener("focusout", handleFocusOut);
      }

      if (focusedDate && !isInMonthYears(focusedDate, monthAndYears)) {
         setFocusedDate(getSelectedOrFirstSelectableDate(monthAndYears, getDayFlags));
      }
      return () => {
         ref.current?.removeEventListener("focus", handleFocus);
         ref.current?.removeEventListener("focusout", handleFocusOut);
      };
   }, [monthAndYears]);

   useEffect(() => {
      const handleFocus = (e: FocusEvent) => {
         if (e.target instanceof HTMLElement) {
            const focusedDay = e.target.querySelector('[data-focused="true"]');
            if (focusedDay && focusedDay instanceof HTMLElement) {
               focusedDay.focus();
            }
         }
      };

      if (ref.current) {
         ref.current.addEventListener("focus", handleFocus);
      }

      return () => {
         if (ref.current) {
            ref.current.removeEventListener("focus", handleFocus);
         }
      };
   }, [ref]);

   useEffect(() => {
      setMonthAndYears(getMonthAndYears(month, year, monthCount, min, max));
   }, [monthCount, month, year, min, max]);

   useEffect(() => {
      const handleMouseOver = (e: MouseEvent) => {
         if (e.target instanceof HTMLElement) {
            const { date } = e.target.dataset;
            if (date) {
               setHovering(new Date(date));
            }
         }
      };

      const handleFocusIn = (e: FocusEvent) => {
         if (e.target instanceof HTMLElement) {
            const { date } = e.target.dataset;
            if (date) {
               setHovering(new Date(date));
            }
         }
      };

      if (ref.current) {
         if (selecting) {
            ref.current.addEventListener("mouseover", handleMouseOver);
            ref.current.addEventListener("focusin", handleFocusIn);
         }
      }

      return () => {
         if (ref.current) {
            ref.current.removeEventListener("mouseover", handleMouseOver);
            ref.current.removeEventListener("focusin", handleFocusIn);
         }
      };
   }, [selecting]);

   const augmentedGetDayFlags = (date: Date) => {
      const flags = getDayFlags(date);
      if (selecting && hovering) {
         if (isDateBetween(date, selecting, hovering)) {
            flags.inRange = true;
         } else {
            flags.inRange = false;
         }
      }
      if (focusedDate && isSameDay(focusedDate, date)) {
         flags.focused = true;
      }
      return flags;
   };

   const { size } = props;
   const handleDayClicked = (date: Date, element: HTMLElement) => {
      const flags = augmentedGetDayFlags(date);
      setHovering(undefined);

      if (flags.selectable) {
         setFocusedDate(date);
      }
      if (onDayClicked) {
         onDayClicked(date, element);
      }
   };
   const handleNavigation = (to: MonthAndYear) => {
      let newMonthsAndYears = monthAndYears;
      if (to.month === nextMonthAndYear?.month && to.year === nextMonthAndYear.year) {
         newMonthsAndYears = [...monthAndYears, nextMonthAndYear];
         newMonthsAndYears.shift();

         setMonthAndYears(newMonthsAndYears);
      }
      if (to.month === previousMonthAndYear?.month && to.year === previousMonthAndYear.year) {
         newMonthsAndYears = [previousMonthAndYear, ...monthAndYears];
         newMonthsAndYears.pop();
         setMonthAndYears(newMonthsAndYears);
      }
   };

   useEffect(() => {
      const keydownHandler = (e: KeyboardEvent) => {
         const { key } = e;
         if (isArrowDirection(key) && focusedDate) {
            e.preventDefault();
            e.stopPropagation();
            onArrowKeyDown(key);
         }
      };
      ref.current?.addEventListener("keydown", keydownHandler);

      return () => {
         ref.current?.removeEventListener("keydown", keydownHandler);
      };
   }, [ref, focusedDate]);

   const className = cn({
      "tw-divide-ink-brand-subtle tw-grid tw-divide-x tw-divide-y-0 tw-divide-solid": monthCount > 1,
      "tw-grid-cols-2": monthCount === 2,
      "tw-grid-cols-3": monthCount === 3,
      "tw-grid-cols-4": monthCount === 4,
      "!tw-outline-0": isArrowFocus
   });

   const onArrowKeyDown = (direction: ArrowDirection) => {
      if (focusedDate) {
         const targetDate = getRelativeDate(focusedDate, direction, calendarMatrix);
         if (targetDate) {
            const earliestDate = new Date(monthAndYears[0].year, monthAndYears[0].month, 1);
            const latestDate = lastDayOfMonth(
               new Date(monthAndYears[monthAndYears.length - 1].year, monthAndYears[monthAndYears.length - 1].month, 1)
            );
            if (isBefore(targetDate, earliestDate) || isAfter(targetDate, latestDate)) {
               handleNavigation({ year: targetDate.getFullYear(), month: targetDate.getMonth() as MonthNumber });
            }
            setFocusedDate(targetDate);
         }
      }
   };

   const optionalProps = isArrowFocus
      ? {
           tabIndex: 0
        }
      : {};

   const monthHeaderStyle = cn("tw-h-8 tw-text-center tw-leading-8", { "tw-text-[14px]": size === "compact" });

   return (
      <>
         <div className="tw-px-3">
            <Grid gap={0} cols={monthAndYears.length as Cols} className="tw-relative tw-w-full">
               {monthAndYears.map((monthAndYear) => {
                  const { month, year } = monthAndYear;
                  return (
                     <div key={`monthAndYearHeader-${month}-${year}`} className={monthHeaderStyle}>
                        {monthNames[month]}, {year}
                     </div>
                  );
               })}
               {!!previousMonthAndYear && (
                  <div className="tw-absolute tw-left-0">
                     <Button
                        variant="tertiary"
                        size={size}
                        onClick={() => handleNavigation(previousMonthAndYear)}
                        icon={<IconChevronLeft />}
                     />
                  </div>
               )}
               {!!nextMonthAndYear && (
                  <div className="tw-absolute tw-right-0">
                     <Button
                        variant="tertiary"
                        size={size}
                        onClick={() => handleNavigation(nextMonthAndYear)}
                        icon={<IconChevronRight />}
                     />
                  </div>
               )}
            </Grid>
         </div>
         <div ref={ref} className={className} {...optionalProps}>
            {monthAndYears.map((monthAndYear) => {
               return (
                  <div key={`month-grid-${monthAndYear.month}-${monthAndYear.year}`} className="tw-px-3">
                     <Month
                        getDayFlags={augmentedGetDayFlags}
                        {...monthAndYear}
                        {...props}
                        focusedDate={focusedDate}
                        onArrowKeyDown={onArrowKeyDown}
                        onDayClicked={handleDayClicked}
                     />
                  </div>
               );
            })}
         </div>
      </>
   );
};

export default Months;
