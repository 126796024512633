import { jsx as c } from "react/jsx-runtime";
import { cn as g } from "./designsystem-ui-react46.js";
const i = {
  start: "tw-items-start",
  center: "tw-items-center",
  end: "tw-items-end",
  between: "tw-items-between",
  around: "tw-items-around",
  evenly: "tw-items-evenly",
  stretch: "tw-items-stretch"
}, o = {
  start: "tw-justify-start",
  center: "tw-justify-center",
  end: "tw-justify-end",
  between: "tw-justify-between",
  around: "tw-justify-around",
  evenly: "tw-justify-evenly",
  stretch: "tw-justify-stretch"
}, f = {
  nowrap: "tw-flex-nowrap",
  wrap: "tw-flex-wrap",
  "wrap-reverse": "tw-flex-wrap-reverse"
}, S = {
  0: "tw-gap-0",
  1: "tw-gap-1",
  2: "tw-gap-2",
  3: "tw-gap-3",
  4: "tw-gap-4",
  5: "tw-gap-5",
  6: "tw-gap-6",
  8: "tw-gap-8",
  10: "tw-gap-10",
  12: "tw-gap-12",
  16: "tw-gap-16",
  20: "tw-gap-20",
  24: "tw-gap-24",
  32: "tw-gap-32",
  40: "tw-gap-40",
  48: "tw-gap-48",
  56: "tw-gap-56",
  64: "tw-gap-64"
};
function l({
  children: t,
  align: e = "center",
  gap: w = 4,
  grow: a = !1,
  justify: r = "center",
  wrap: n = "nowrap",
  className: s = "",
  ...p
}) {
  return /* @__PURE__ */ c(
    "div",
    {
      className: g(
        "tw-flex tw-flex-col",
        a && "tw-grow",
        i[e],
        o[r],
        f[n],
        S[w],
        s
      ),
      ...p,
      children: t
    }
  );
}
l.displayName = "Stack";
export {
  l as Stack
};
