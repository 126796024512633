import { view } from "@risingstack/react-easy-state";
import { useEffect, useState } from "react";

import { DeliveryDate } from "../common/types/deliveryTypes";
import { ORDERTYPE } from "../common/types/productOrderTypes";
import { translateOrderType } from "../common/utils";
import { formatDate, formatDateTime } from "../common/utils/dateUtils";

import cartStore from "../stores/cart/cartStore";
import deliveryDatesStore from "../stores/deliveryDates/deliveryDatesStore";

import theme from "../themes/theme";
import Button from "./Button";
import Modal from "./Modal";

const ExpiredDeadlineModal = () => {
   const [showModal, setShowModal] = useState<boolean>(false);
   const [nextDelivery, setNextDelivery] = useState<DeliveryDate | null>(
      deliveryDatesStore.getNextDeliveryDateForOrderType(cartStore.orderType)
   );
   const expiration = deliveryDatesStore.getCurrentDeliveryExpiration();
   const orderType: ORDERTYPE = cartStore.orderType;
   const deliveryDescription =
      orderType === "WEB"
         ? "med leveranse"
         : orderType === "HPN"
           ? "klar for henting etter"
           : orderType === "WAS"
             ? "med første levering"
             : "";

   useEffect(() => {
      if (expiration === 0) {
         deliveryDatesStore.removeExpiredDeliveryDates();
         const next = deliveryDatesStore.getNextDeliveryDateForOrderType(cartStore.orderType);
         setNextDelivery(next);
         setShowModal(true);
      }
   }, [expiration]);

   const handleClose = () => {
      deliveryDatesStore.selectNextDeliveryDateForOrderType(cartStore.orderType);
      setShowModal(false);
   };

   return (
      <Modal
         isOpen={showModal}
         title="Bestillingsfristen har utløpt"
         onClose={handleClose}
         name="Advarsel: Bestillingsfristen har utløpt"
      >
         <>
            {!!nextDelivery && (
               <p>
                  Den nye fristen er {formatDateTime(nextDelivery.deadline)} {deliveryDescription}{" "}
                  {formatDate(nextDelivery.date, true)}
               </p>
            )}
            {!nextDelivery && (
               <p>
                  Vi finner dessverre ikke flere alternativer for ordretype{" "}
                  {translateOrderType(orderType, theme.storeId).toLowerCase()}. Ta kontakt med kundeservice.
               </p>
            )}
            <div className="tw-flex tw-justify-center">
               <Button variant="primary" onClick={handleClose}>
                  OK
               </Button>
            </div>
         </>
      </Modal>
   );
};

export default view(ExpiredDeadlineModal);
